import { action, makeObservable, observable } from 'mobx';
import { ISPResponse } from '../../../global/interfaces/responses/sp/sp.response.interface';
import { TPuTabScreen, TSavingsPotentialStatus } from '../../../global/types/types';

type ISPHeaderDTO = {
  id: string;
  identifier: string;
  submitter_name: string;
  submitter_ico: string;
  auditor_name: string | undefined;
  preparation_date: Date;
};

interface IEditSummaryProvisionArgs {
  objectId: string;
  provisionId: number;
  data: ISPResponse | null;
}

interface IEditSummaryCarrierArgs {
  objectId: string;
  provisionId: number;
  carrierId: string;
  data: ISPResponse | null;
}

export interface ISubjectType {
  _id: string;
  name: string;
}

interface IEditSummaryObjectArgs {
  objectId: string;
}

class SavingsPotentialStore {
  puTabScreen: TPuTabScreen = 'SPList';
  spStatus?: TSavingsPotentialStatus;
  editSummaryProvision?: IEditSummaryProvisionArgs;
  editSummaryCarrier?: IEditSummaryCarrierArgs;
  spBaseInfo: ISPHeaderDTO | undefined;
  editSummaryConsumptions?: {};
  editSummaryObject?: IEditSummaryObjectArgs;

  clearSummaryData() {
    this.editSummaryProvision = undefined;
    this.editSummaryConsumptions = undefined;
    this.editSummaryObject = undefined;
    this.editSummaryCarrier = undefined;
  }

  subjectTypes: ISubjectType[] = [
    { _id: 'cry', name: 'Slovenská republika' },
    { _id: 'reg', name: 'Kraj' },
    { _id: 'cou', name: 'Okres' },
    { _id: 'cit', name: 'Obec' },
    { _id: 'cul', name: 'Región' }
  ];

  setSPTabScreen(screen: TPuTabScreen) {
    this.puTabScreen = screen;
  }

  setSPStatus(eaStatus: TSavingsPotentialStatus) {
    this.spStatus = eaStatus;
  }

  constructor() {
    makeObservable(this, {
      puTabScreen: observable,
      setSPTabScreen: action,
      spStatus: observable
    });
  }
}

const singleton = new SavingsPotentialStore();
export default singleton;
