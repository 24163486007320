import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XsButton, XsLoading } from '.';
import { IFormDialogChildrenProps } from '../interfaces/common';
import UIStore from '../stores/UIStore';
import { warn } from '../helpers/actions';

export interface IFormDialogProps extends Omit<DialogProps, 'open'> {
  name: string;
  title: string;
  children?: React.PropsWithChildren<IFormDialogChildrenProps> | React.ReactElement;
  onSave?: () => void;
  onClose?: () => void;
  titleIcon?: JSX.Element;
  subTitle?: string;
}

function XsFormDialog(props: IFormDialogProps) {
  const { t } = useTranslation();
  const { name, title, titleIcon, subTitle, children, onClose, onSave, ...rest } = props;
  const [saving, setSaving] = useState<boolean>(false);

  const handleClose = (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      if (onClose && typeof onClose === 'function') {
        onClose();
      }
      UIStore.closeFormDialog();
    }
  };

  let formSave: () => Promise<void>;

  const setFormSave = (formSaveHandler: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>) => {
    formSave = formSaveHandler;
  };

  const setFormSaving = (isFormSavingChild: boolean) => {
    setSaving(isFormSavingChild);
  };

  const handleSave = () => {
    if (onSave && typeof onSave === 'function') {
      onSave();
    }
    if (formSave) {
      formSave();
    } else {
      warn(`XsFormDialog ${name} has no formSave callback!`);
    }
  };

  const dialog =
    name === UIStore.formDialogOpened ? (
      <Dialog maxWidth='md' className={`xs-confirmation-dialog ${name}`} {...rest} open={true} onClose={handleClose}>
        <DialogTitle className='xs-title'>
          <Grid container direction='column'>
            {titleIcon && <Grid item>{titleIcon}</Grid>}
            <Grid item>
              <Typography variant='h6'>{t(title)}</Typography>
            </Grid>
            {subTitle && <Typography variant='body2'>{t(subTitle)}</Typography>}
          </Grid>
        </DialogTitle>
        <DialogContent className='dialog-content'>
          {React.cloneElement(children as React.ReactElement, { setFormSave, setFormSaving })}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent='flex-end'>
            <Grid item>
              <XsButton
                variant='outlined'
                startIcon={<i className='fal fa-times' />}
                label={'global.cancel'}
                onClick={() => handleClose()}
                disabled={saving}
              />
            </Grid>

            <Grid item>
              {saving ? (
                <XsLoading size={30} />
              ) : (
                <XsButton
                  variant='outlined'
                  startIcon={<i className='fal fa-save' />}
                  label={'global.save'}
                  onClick={() => handleSave()}
                  disabled={saving}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    ) : null;

  return dialog;
}

export default observer(XsFormDialog);
