import { Grid, Link, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { version } from '../../../package.json';
import settings from '../config/settings';
import { useServerCall } from '../helpers/hooks';
import { XsAppStatus } from './XsAppStatus';
import classnames from 'classnames';
import { IS_BO, IS_FO } from '../config/constants';
import { windowOpen } from '../helpers/actions';

interface IAppInfo {
  app_version: {
    at: string;
    number: string;
    source_identifier: string;
  };
}

export const XsFooter = () => {
  const { t } = useTranslation();
  const [buildInfo, setBuildInfo] = useState<string | undefined>('');

  const getAppInfo = useServerCall<IAppInfo, IAppInfo>({
    key: 'getAppInfo',
    mode: 'control',
    request: { method: 'GET', url: `${settings.API_PUBLIC_URL}/discovery/app-info` },
    queryOptions: { refetchOnWindowFocus: false },
    onSuccess: async (response: any) => {
      const { data: appInfo } = response;
      let bi: any = (
        <div>
          {settings.CI_COMMIT_HASH && (
            <pre>
              FE: {settings.MODULE} {version} {settings.CI_COMMIT_HASH} {settings.BUILD_TIME}
            </pre>
          )}
          {appInfo && (
            <pre>
              BE: {appInfo.app_version.number} {appInfo.app_version.source_identifier?.substring(0, 8)}{' '}
              {appInfo.app_version.at}
            </pre>
          )}
        </div>
      );

      setBuildInfo(bi);
    },
    onError: (error) => {}
  });

  useEffect(() => {
    getAppInfo.run();
  }, []);

  const currYear: number = new Date().getFullYear();

  return (
    // <div className='main_footer'>
    //   <footer className='first_part'>
    //     <Container maxWidth='lg' className='footer_container'>
    //       <Grid container justifyContent='space-between' alignItems='center'>
    //         <Grid item container xs alignItems='center'>
    //           <Grid item>
    //             <img
    //               className='pointer'
    //               alt='logo'
    //               src='./images/logo_siea_blue.png'
    //               onClick={() => windowOpen('https://www.siea.sk/', '_blank')}
    //             />
    //           </Grid>
    //           <Grid item style={{ color: 'rgba(0, 0, 0, 0.48)' }}>
    //             <Box ml={4}>
    //               <Grid container direction='column' spacing={1}>
    //                 <Grid item>Bajkalská 27</Grid>
    //                 <Grid item>827 99 Bratislava</Grid>
    //               </Grid>
    //             </Box>
    //           </Grid>
    //         </Grid>
    //         <Grid item>
    //           <img
    //             className='pointer'
    //             src='./images/mhsr_logo_blue.png'
    //             alt='logo'
    //             onClick={() => windowOpen('https://www.mhsr.sk/', '_blank')}
    //           />
    //         </Grid>
    //       </Grid>
    //     </Container>
    //   </footer>
    //   <footer className={classnames('white', { bgBlueDark: IS_FO, bgGreenDark: IS_BO })}>
    //     <Container maxWidth='lg' className='footer_container'>
    //       <Typography variant='caption'>
    //         <Grid container>
    //           <Grid item>
    //             © {currYear} SIEA<span> | </span>
    //             <Tooltip title={t('footer.contact_tooltip') as string}>
    //               <Link className='white' href='https://www.siea.sk/monitorovaci-system' target='_blank'>
    //                 www.siea.sk/monitorovaci-system
    //               </Link>
    //             </Tooltip>
    //             <span> | </span>
    //             <Link
    //               className='white pointer'
    //               onClick={() => windowOpen('https://www.siea.sk/o-siea/ochrana-osobnych-udajov/', '_blank')}
    //             >
    //               {t('footer.GDPR')} {t('footer.created')}: 2022, {t('footer.changed')}: {currYear}
    //             </Link>
    //             {version && buildInfo && (
    //               <>
    //                 <span> | </span>
    //                 <Tooltip title={buildInfo}>
    //                   <span>
    //                     {t('footer.version')}: {version}
    //                   </span>
    //                 </Tooltip>
    //               </>
    //             )}
    //             {settings.DEBUG_APP_STATUS && <>{<XsAppStatus />}</>}
    //           </Grid>
    //         </Grid>
    //       </Typography>
    //     </Container>
    //   </footer>
    // </div>
    <div className={classnames('white main_footer', { bgBlueDark: IS_FO, bgGreenDark: IS_BO })}>
      <Grid container alignItems='center' justifyContent='space-between' height={44}>
        <Grid item>
          <Grid container>
            <img
              className='pointer'
              style={{ height: 30, paddingTop: 4, marginLeft: '30px' }}
              alt='logo'
              src='./images/siea_logo_white.png'
              onClick={() => windowOpen('https://www.siea.sk/', '_blank')}
            />
            <Stack style={{ fontSize: 7, paddingLeft: 10, paddingTop: 3 }}>
              SLOVENSKÁ
              <br />
              INOVAČNÁ
              <br />A ENERGETICKÁ
              <br />
              AGENTÚRA
            </Stack>
          </Grid>
        </Grid>
        <Grid item>
          © {currYear} SIEA<span> | </span>
          <Tooltip title={t('footer.contact_tooltip') as string}>
            <Link className='white' href='https://www.siea.sk/monitorovaci-system' target='_blank'>
              Viac o monitorovaní
            </Link>
          </Tooltip>
          <span> | </span>
          <Link
            className='white pointer'
            onClick={() => windowOpen('https://www.siea.sk/o-siea/ochrana-osobnych-udajov/', '_blank')}
          >
            {t('footer.GDPR')} {t('footer.created')}: 2022, {t('footer.changed')}: {currYear}
          </Link>
          {version && buildInfo && (
            <>
              <span> | </span>
              <Tooltip title={buildInfo}>
                <span>
                  {t('footer.version')}: {version}
                </span>
              </Tooltip>
            </>
          )}
          {settings.DEBUG_APP_STATUS && <>{<XsAppStatus />}</>}
        </Grid>
        <Grid item>
          <img
            className='pointer'
            style={{ height: 30, paddingTop: 6, marginRight: '30px' }}
            alt='logo'
            src='./images/mhsr_logo_white.png'
            onClick={() => windowOpen('https://www.siea.sk/', '_blank')}
          />
        </Grid>
      </Grid>
    </div>
  );
};
