import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConfirmationDialogBase } from '../../types/confirmationDialog';
import XsConfirmationDialog from './XsConfirmationDialog';

export default observer(({ onConfirmation, onCancel, name = 'leaveConfirmation' }: IConfirmationDialogBase) => {
  const { t } = useTranslation();

  return (
    <XsConfirmationDialog
      type='warning'
      name={name}
      title={'leave_confirmation.title'}
      titleIcon={<i className='fas fa-exclamation-triangle fa-2x'></i>}
      justifyActions={['space-between']}
      directionActions={'row-reverse'}
      content={
        <Grid container className='greyDefault' direction='column' spacing={2}>
          <Grid item fontWeight='500'>
            {t('leave_confirmation.text')}
          </Grid>
          <Grid item>{t('leave_confirmation.sub_text')}</Grid>
          <Grid item>{t('leave_confirmation.sub_text_2')}</Grid>
        </Grid>
      }
      confirmBtn={{
        icon: <></>,
        label: 'leave_confirmation.action_leave',
        className: 'xs-outlined-warning',
        variant: 'outlined'
      }}
      cancelBtn={{
        icon: <></>,
        label: 'leave_confirmation.action_stay',
        className: 'primary',
        variant: 'contained'
      }}
      onConfirmation={onConfirmation}
      onCancel={onCancel}
    />
  );
});
