import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import LoginStore from '../../modules/common/login/LoginStore';
import API from '../config/api';
import { DATETIMESEC_FORMAT } from '../config/constants';
import { useServerCall } from '../helpers/hooks';
import { IUserInfo } from '../interfaces/userInfo';
import AutoLogoutStore from '../stores/AutoLogoutStore';
import { XsButton } from './XsButton';
import { XsIconButton } from './XsIconButton';
import settings from '../config/settings';

interface Props {
  secondsToLogout: number;
  onContinue: Function;
  open?: boolean;
}

const LogoutWarningDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { secondsToLogout, onContinue, open = false } = props;

  return (
    <Dialog open={open} maxWidth='sm'>
      <DialogTitle className='bgBlueDark'>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <i className='fas fa-lock fa-2x snowWhite'></i>
          </Grid>
          <Grid item>
            <Typography variant='h6' className='snowWhite'>
              {t('auto_logout.title')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container pb={2} pt={2}>
          <Grid item>
            <Typography variant='body2'>{t('auto_logout.text')}</Typography>
          </Grid>
          <Grid item container spacing={1} pt={2} alignItems='flex-end' className='greyDefault'>
            <Grid item>
              <Typography variant='h6'>{t('auto_logout.remaining_time')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4'>{secondsToLogout}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>{t('auto_logout.second')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='bgWhite border_top_1'>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <XsButton variant='contained' label={t('global.continue')} onClick={() => onContinue()} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});

interface IAutoLogoutProps {
  debug?: boolean;
}

export const XsAutoLogout = observer((props: IAutoLogoutProps) => {
  const [debug, setDebug] = useState(props.debug);
  const [timeToAutologout, setTimeToAutologout] = useState<number | undefined>(undefined);
  const [timeBeforePrompt, setTimeBeforePrompt] = useState<number | undefined>(undefined);

  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [elapsed, setElapsed] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called `promptBeforeIdle` milliseconds before `timeout`.
    // In this case 29 minutes and 30 seconds or 30 seconds before idle.
    // Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `activate()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setRemaining(Math.ceil(getRemainingTime() / 1000));
    setOpen(true);
  };

  const onIdle = () => {
    // onIdle will be called after the timeout is reached.
    // In this case 30 minutes. Here you can close your prompt and
    // perform whatever idle action you want such as logging out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);

    //logout
    LoginStore.LogOut();
    LoginStore.ShowReloginDialog();
  };

  const { getRemainingTime, isPrompted, start, reset, getElapsedTime, getActiveTime } = useIdleTimer({
    timers: workerTimers,
    timeout: timeToAutologout,
    promptBeforeIdle: timeBeforePrompt,
    onPrompt,
    onIdle,
    startManually: true,
    events: []
  });

  const startTimer = () => {
    start();
  };

  const stopTimer = () => {
    reset();
    setRemaining(0);
  };

  const pingToken = useServerCall<any, IUserInfo>({
    key: ['userInfo-autologout', Date.now()],
    mode: 'control',
    request: {
      method: 'GET',
      url: API.Self()
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 0, cacheTime: 0 },
    onSuccess: () => {
      setOpen(false);
    },
    onError: () => {
      setOpen(false);
      stopTimer();
      //logout
      LoginStore.LogOut();
      LoginStore.ShowReloginDialog();
    }
  });

  const handleStillHere = () => {
    pingToken.run();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
      setElapsed(Math.ceil(getElapsedTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted, getElapsedTime, getActiveTime]);

  useEffect(() => {
    // pri zmene datumu expiracie nastav spravny odpocet a vynuluj pocitadlo
    if (AutoLogoutStore.tokenExpDateUTC) {
      const tokenExp = AutoLogoutStore.tokenExpDateUTC.getTime() - Date.now() - settings.API_CLIENT_TIMEOUT_GAP;
      setTimeToAutologout(tokenExp);
      setTimeBeforePrompt(settings.AUTOLOGOUT_PROMPT_VISIBILITY); // prompt dialog is displayed fo 60s
      stopTimer();
    } else {
      stopTimer();
      setTimeToAutologout(undefined);
      setTimeBeforePrompt(undefined);
    }
  }, [AutoLogoutStore.tokenExpDateUTC?.getTime()]);

  useEffect(() => {
    // pri zmene odpoctu spust nove pocitadlo
    if (timeToAutologout) {
      startTimer();
    }
  }, [timeToAutologout]);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
      {/* Debug window */}
      {debug && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            position: 'absolute',
            padding: 16,
            right: 10,
            bottom: 10,
            borderRadius: 4,
            backgroundColor: '#292929',
            color: 'lightgray',
            zIndex: 99999
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h6'>{'Debug autologout'}</Typography>
            <XsIconButton
              size='medium'
              icon={<i className='fas fa-times' />}
              onClick={() => setDebug(false)}
              color='inherit'
            />
          </div>
          <div>
            token expiration:{' '}
            {AutoLogoutStore.tokenExpDateUTC ? format(AutoLogoutStore.tokenExpDateUTC, DATETIMESEC_FORMAT) : ' - '}
          </div>
          <div>timeout in: {timeToAutologout} ms</div>
          <div>prompt in: {timeBeforePrompt} ms</div>
          <div>remaining: {remaining}s</div>
          <div>elapsed: {elapsed}s</div>
          <XsButton style={{ color: 'orange' }} label='Start timer manually' onClick={() => startTimer()} />
        </div>
      )}
      <LogoutWarningDialog open={open} secondsToLogout={remaining} onContinue={handleStillHere} />
    </div>
  );
});
