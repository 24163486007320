import loadable from '@loadable/component';

export const llLoginFO = () => {
  return loadable(() => import(/* webpackChunkName: "loginFO" */ './../../modules/frontOffice/login'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/login'),
      props: { component: keyof typeof import('./../../modules/frontOffice/login') }
    ) => components[props.component]
  });
};

export const llLoginBO = () => {
  return loadable(() => import(/* webpackChunkName: "loginBO" */ './../../modules/backOffice/login'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/login'),
      props: { component: keyof typeof import('./../../modules/backOffice/login') }
    ) => components[props.component]
  });
};

export const llWelcomeFO = () => {
  return loadable(() => import(/* webpackChunkName: "welcomeFO" */ './../../modules/frontOffice/welcome'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/welcome'),
      props: { component: keyof typeof import('./../../modules/frontOffice/welcome') }
    ) => components[props.component]
  });
};

export const llWelcomeBO = () => {
  return loadable(() => import(/* webpackChunkName: "welcomeBO" */ './../../modules/backOffice/welcome'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/welcome'),
      props: { component: keyof typeof import('./../../modules/backOffice/welcome') }
    ) => components[props.component]
  });
};

export const llAdminBO = () => {
  return loadable(() => import(/* webpackChunkName: "adminBO" */ './../../modules/backOffice/admin'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/admin'),
      props: { component: keyof typeof import('./../../modules/backOffice/admin') }
    ) => components[props.component]
  });
};

export const llAdminFO = () => {
  return loadable(() => import(/* webpackChunkName: "adminFO" */ './../../modules/frontOffice/admin'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/admin'),
      props: { component: keyof typeof import('./../../modules/frontOffice/admin') }
    ) => components[props.component]
  });
};

export const llBuildingBO = () => {
  return loadable(() => import(/* webpackChunkName: "buildingBO" */ './../../modules/backOffice/building'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/building'),
      props: { component: keyof typeof import('./../../modules/backOffice/building') }
    ) => components[props.component]
  });
};

export const llBuildingFO = () => {
  return loadable(() => import(/* webpackChunkName: "buildingFO" */ './../../modules/frontOffice/building'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/building'),
      props: { component: keyof typeof import('./../../modules/frontOffice/building') }
    ) => components[props.component]
  });
};

export const llRoutes = () => {
  return loadable(() => import(/* webpackChunkName: "routes" */ './../../modules/common/routes'), {
    resolveComponent: (
      components: typeof import('./../../modules/common/routes'),
      props: { component: keyof typeof import('./../../modules/common/routes') }
    ) => components[props.component]
  });
};

export const llEAFO = () => {
  return loadable(() => import(/* webpackChunkName: "ea_fo" */ './../../modules/frontOffice/energeticAudit'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/energeticAudit'),
      props: { component: keyof typeof import('./../../modules/frontOffice/energeticAudit') }
    ) => components[props.component]
  });
};

export const llEABO = () => {
  return loadable(() => import(/* webpackChunkName: "ea_bo" */ './../../modules/backOffice/energeticAudit'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/energeticAudit'),
      props: { component: keyof typeof import('./../../modules/backOffice/energeticAudit') }
    ) => components[props.component]
  });
};

export const llCvsFO = () => {
  return loadable(() => import(/* webpackChunkName: "cvs_fo" */ './../../modules/common/cvs'), {
    resolveComponent: (
      components: typeof import('./../../modules/common/cvs'),
      props: { component: keyof typeof import('./../../modules/common/cvs') }
    ) => components[props.component]
  });
};

export const llCvsBO = () => {
  return loadable(() => import(/* webpackChunkName: "cvs_bo" */ './../../modules/backOffice/cvs/CvsContainer'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/cvs'),
      props: { component: keyof typeof import('./../../modules/backOffice/cvs') }
    ) => components[props.component]
  });
};

export const llOesFO = () => {
  return loadable(() => import(/* webpackChunkName: "oes_fo" */ './../../modules/frontOffice/oes'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/oes'),
      props: { component: keyof typeof import('./../../modules/frontOffice/oes') }
    ) => components[props.component]
  });
};

export const llOesBO = () => {
  return loadable(() => import(/* webpackChunkName: "oes_bo" */ './../../modules/backOffice/oes'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/oes'),
      props: { component: keyof typeof import('./../../modules/backOffice/oes') }
    ) => components[props.component]
  });
};

export const llDistFO = () => {
  return loadable(() => import(/* webpackChunkName: "dist_fo" */ './../../modules/common/distributions'), {
    resolveComponent: (
      components: typeof import('./../../modules/common/distributions'),
      props: { component: keyof typeof import('./../../modules/common/distributions') }
    ) => components[props.component]
  });
};

export const llDistBO = () => {
  return loadable(() => import(/* webpackChunkName: "dist_bo" */ './../../modules/backOffice/distributions'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/distributions'),
      props: { component: keyof typeof import('./../../modules/backOffice/distributions') }
    ) => components[props.component]
  });
};

export const llSpFO = () => {
  return loadable(() => import(/* webpackChunkName: "sp_fo" */ './../../modules/frontOffice/pu'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/pu'),
      props: { component: keyof typeof import('./../../modules/frontOffice/pu') }
    ) => components[props.component]
  });
};

export const llSpBO = () => {
  return loadable(() => import(/* webpackChunkName: "sp_bo" */ './../../modules/backOffice/sp'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/sp'),
      props: { component: keyof typeof import('./../../modules/backOffice/sp') }
    ) => components[props.component]
  });
};

export const llEtlBO = () => {
  return loadable(() => import(/* webpackChunkName: "etl_bo" */ './../../modules/backOffice/etl'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/etl'),
      props: { component: keyof typeof import('./../../modules/backOffice/etl') }
    ) => components[props.component]
  });
};

export const llOzeBO = () => {
  return loadable(() => import(/* webpackChunkName: "ozeBO" */ './../../modules/backOffice/oze'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/oze'),
      props: { component: keyof typeof import('./../../modules/backOffice/oze') }
    ) => components[props.component]
  });
};

export const llOzeFO = () => {
  return loadable(() => import(/* webpackChunkName: "ozeFO" */ './../../modules/frontOffice/oze'), {
    resolveComponent: (
      components: typeof import('./../../modules/frontOffice/oze'),
      props: { component: keyof typeof import('./../../modules/frontOffice/oze') }
    ) => components[props.component]
  });
};

export const llOomBO = () => {
  return loadable(() => import(/* webpackChunkName: "OomBO" */ './../../modules/backOffice/oom'), {
    resolveComponent: (
      components: typeof import('./../../modules/backOffice/oom'),
      props: { component: keyof typeof import('./../../modules/backOffice/oom') }
    ) => components[props.component]
  });
};
