import { CircularProgress, Modal, Theme, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export interface ILoadingProps {
  message?: string;
  size?: number;
  overlay?: boolean;
}

type ILoadingStyle = ReturnType<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw'
    },
    message: {
      fontWeight: 'bold',
      color: theme.palette?.primary.main,
      paddingTop: '8px'
    }
  })
);

const renderLoading = (classes: ILoadingStyle, message?: string, size?: number) => {
  return (
    <Grid container direction='column' alignItems='center' style={{ outline: 'none' }}>
      <CircularProgress size={size || 50} />
      {message && (
        <Grid item className={classes.message}>
          {message}
        </Grid>
      )}
    </Grid>
  );
};

export const XsLoading = (props: ILoadingProps) => {
  const classes = useStyles();
  const { overlay = false, ...rest } = props;
  if (overlay) {
    return (
      <Modal open={true} className={classes.modalContainer}>
        {renderLoading(classes, rest.message, rest.size)}
      </Modal>
    );
  } else {
    return renderLoading(classes, rest.message, rest.size);
  }
};
