import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from 'react-query';
import queryClient from './global/helpers/query';
import skLocale from 'date-fns/locale/sk';
import { SnackbarProvider } from 'notistack';
import './i18n';
import { ThemeProvider } from '@mui/material';
import { theme } from './global/styles/muiTheme';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { XsFooter } from './global/ui';

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={skLocale}>
          <SnackbarProvider
            iconVariant={{ error: <i className='far fa-exclamation-triangle fa-lg pr-3'></i> }}
            maxSnack={3}
          >
            <Suspense fallback=''>
              <App />
              <XsFooter />
            </Suspense>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
