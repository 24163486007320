import { Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import API from '../config/api';
import { useServerCall } from '../helpers/hooks';
import { IAppStatusResponse } from '../interfaces/userInfo';
import UIStore from '../stores/UIStore';

export const XsAppStatus = () => {
  const [isAlive, setIsAlive] = useState<IAppStatusResponse | undefined>(undefined);
  const classes: any = useStyles();
  const printerCheckInterval = 2 * 60 * 1000; //2 min refresh

  const stillAlive = useServerCall<unknown, IAppStatusResponse>({
    key: 'stillAlive',
    request: { method: 'GET', url: API.AppStatus() },
    queryOptions: { refetchOnWindowFocus: false, refetchInterval: printerCheckInterval },
    onSuccess: async (response: any) => {
      const { data: pingData } = response;
      setIsAlive(pingData);
      UIStore.setMaintenance(false);
    },

    onError: (error) => {
      setIsAlive(undefined);
    }
  });

  useEffect(() => {
    stillAlive.run();
  }, []);

  const renderDot = (title: string, online: boolean | undefined) => {
    return (
      <Tooltip title={title}>
        <i className={`${classes.appdot} fas fa-circle ${online ? classes.online : classes.offline}`}></i>
      </Tooltip>
    );
  };

  return (
    <>
      {` | `}
      {renderDot(
        `Cadastre ${
          isAlive?.cadastre_last_seen
            ? `last seen online ${new Date(isAlive?.cadastre_last_seen)?.toLocaleString()}`
            : 'is offline'
        }
      `,
        Boolean(isAlive?.cadastre_last_seen)
      )}
      {renderDot(
        `Printer ${
          isAlive?.printer_last_seen
            ? `last seen online ${new Date(isAlive?.printer_last_seen)?.toLocaleString()}`
            : 'is offline'
        }
      `,
        Boolean(isAlive?.printer_last_seen)
      )}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    appdot: { paddingRight: '2px' },
    offline: {
      color: 'red'
    },
    online: {
      color: '#00a787'
    }
  })
);
