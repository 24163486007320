import { FormHelperText, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { createStyles, makeStyles } from '@mui/styles';
import FileSaver from 'file-saver';
import React, { ChangeEvent, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import settings from '../config/settings';
import { useError, useServerCall } from '../helpers/hooks';
import { IAttachment } from '../interfaces/common';
import UIStore from '../stores/UIStore';
import XsConfirmationDialog from './confirmationDialog/XsConfirmationDialog';
import { ReactComponent as ExcelIcon } from './dropzoneIcons/excel-svgrepo-com.svg';
import { ReactComponent as WordIcon } from './dropzoneIcons/word-svgrepo-com.svg';
import { ReactComponent as PDFIcon } from './dropzoneIcons/pdf-document-svgrepo-com.svg';
import { ReactComponent as JPGIcon } from './dropzoneIcons/jpg-svgrepo-com.svg';
import { ReactComponent as PNGIcon } from './dropzoneIcons/png-svgrepo-com.svg';

interface IDropzoneProps {
  name: string;
  multiple?: boolean;
  disabled?: boolean;
  returnAttachments?: boolean;
  maxFiles?: number;
  accept?: string[];
  value?: Array<IAttachment>;
  onChange: (files: FileList | File[] | IAttachment[]) => void;
}

export const XsDropzone = (props: IDropzoneProps) => {
  const {
    multiple = true,
    value,
    name,
    disabled = false,
    onChange,
    accept = [
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.pdf',
      '.jpeg',
      '.jpg',
      '.png',
      '.bmp',
      '.csv',
      '.eml',
      '.htm',
      '.html',
      '.mht',
      '.msg',
      '.odg',
      '.ods',
      '.odt',
      '.rtf',
      '.tif',
      '.txt',
      '.url'
    ],
    maxFiles = 50,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { error, helperText } = useError(name);
  const fileSizeLimit: number = settings.UPLOAD_FILE_SIZE_LIMIT;

  const bytesToMB = (value: number) => {
    return value / 1024 / 1024;
  };

  const onDrop = useCallback(
    (droppedFiles: File[], fileRejection: FileRejection[], event) => {
      if (event.type === 'drop') {
        onDropzoneChange(undefined, droppedFiles);
      }
    },
    [value]
  );

  //IAttachment[]
  const onDropzoneChange = (
    e: ChangeEvent<HTMLInputElement> | undefined,
    droppedFiles: File[] | undefined = undefined
  ) => {
    const files: FileList | File[] | null = droppedFiles ? droppedFiles : (e!.target as HTMLInputElement)?.files;
    const validFiles: FileList | File[] = [];
    const rejectedFiles: FileList | File[] = [];
    if (files) {
      // 1. File count limit
      // 2. File size limit
      // 3. File type check
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const currentFilesCount: number = value?.length ?? 0;
        const fileType = file.name.substring(file.name.lastIndexOf('.'));

        if (currentFilesCount + validFiles.length + 1 > maxFiles) {
          UIStore.setNotificationMessage(t('xs_dropzone.file_count_limit_exceeded', { filecount: maxFiles }), 'error');
        } else if (file.size > settings.UPLOAD_FILE_SIZE_LIMIT) {
          UIStore.setNotificationMessage(t('xs_dropzone.file_size_limit_exceeded', { filename: file.name }), 'error');
        } else if (accept.every((a) => a.toLowerCase() !== fileType.toLowerCase())) {
          rejectedFiles.push(file);
        } else {
          validFiles.push(file);
        }
      }

      if (rejectedFiles.length > 0) {
        UIStore.setNotificationMessage(
          t('xs_dropzone.file_type_invalid', { filename: rejectedFiles.map((rf) => rf.name).join('; ') }),
          'error'
        );
      }

      transformFilesToAttachments(validFiles);
    } else {
      onChange([]);
    }
  };

  //Download content
  const downloadContent = useServerCall<any, any>({
    key: ['attachmentContent'],
    mode: 'control',
    request: {
      method: 'GET',
      responseType: 'blob'
    },
    onSuccess: (result: any) => {
      if (result.data) {
        const contentDisposition: string = result.headers['content-disposition'];
        const filename = contentDisposition.split('filename=')[1];
        const saveBlob = new Blob([result.data], { type: result.headers['content_type'] });

        FileSaver.saveAs(saveBlob, filename);
        UIStore.setNotificationMessage(t('xs_dropzone.file_download_success'), 'success');
      }
    },
    onError: (error) => {
      UIStore.setNotificationMessage(t('xs_dropzone.file_download_fail', { error }), 'error');
    }
  });

  const transformFilesToAttachments = (files: FileList | File[]) => {
    let promises: Promise<IAttachment>[] = [];

    for (let i = 0; i < files.length; i++) {
      let filePromise: Promise<IAttachment> = new Promise((resolve) => {
        const file = files[i];
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () =>
          resolve({
            file_name: file.name,
            content_type: file.type,
            content: btoa(reader.result as string)
          });
      });
      promises.push(filePromise);
    }

    Promise.all(promises).then((attachments: IAttachment[]) => {
      let newAttach: IAttachment[] = [];

      if (value?.length) {
        newAttach = [...value];
      }

      newAttach = [...newAttach, ...attachments];
      onChange(newAttach);
    });
  };

  const deleteFile = (fileIdx: number) => {
    let newAttach: IAttachment[] = [];

    if (value?.length) {
      newAttach = value.filter((item: IAttachment, idx: number) => idx !== fileIdx);
    }
    onChange(newAttach);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept,
    onDrop,
    maxSize: fileSizeLimit,
    disabled,
    ...rest
  });
  const classes = useStyles();

  const handleDropzonePreview = (fileIdx: number) => {
    if (value?.length) {
      const attachmentToDownload: IAttachment = value[fileIdx];
      //Direct download
      //Currently uploaded files, without attachment content and id
      if (attachmentToDownload.content) {
        const byteCharacters = atob(attachmentToDownload.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const saveBlob = new Blob([byteArray], { type: attachmentToDownload.content_type });
        FileSaver.saveAs(saveBlob, attachmentToDownload.file_name);
        UIStore.setNotificationMessage(t('xs_dropzone.file_download_success'), 'success');
      } else if (attachmentToDownload._id) {
        //Perform attachment content download
        //Stored files with attachment content and id
        downloadContent.run({ url: `/object/attachment/${attachmentToDownload._id}` });
      }
    }
  };

  const generateIconForFile = (attach: IAttachment, index: number) => {
    const nameSplit = attach.file_name.split('.');
    const fileType = nameSplit[nameSplit.length - 1];

    const onClickHandle = (
      e: React.MouseEvent<SVGSVGElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      e.stopPropagation();
      handleDropzonePreview(index);
    };

    if (fileType === 'xls' || fileType === 'xlsx') {
      return (
        <ExcelIcon
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    } else if (fileType === 'doc' || fileType === 'docx') {
      return (
        <WordIcon
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    } else if (fileType === 'pdf') {
      return (
        <PDFIcon
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    } else if (fileType === 'jpeg' || fileType === 'jpg') {
      return (
        <JPGIcon
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    } else if (fileType === 'png') {
      return (
        <PNGIcon
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    } else {
      return (
        <i
          className={`fal fa-file ${classes.droppedFileIcon}`}
          onClick={(e) => {
            onClickHandle(e);
          }}
        />
      );
    }
  };

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        {value && (
          <Grid item xs={12} container spacing={1}>
            {value?.map((attach: IAttachment, index) => (
              <Grid item key={index}>
                <Tooltip title={attach.file_name} placement='bottom'>
                  <span>
                    <div className={classes.droppedFile}>
                      {generateIconForFile(attach, index)}
                      {!disabled && (
                        <Tooltip title={'Delete'} placement='top'>
                          <span
                            className={`far fa-trash-alt ${classes.deleteIcon}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              UIStore.openConfirmationDialog('removeFile', { fileIndex: index });
                            }}
                          ></span>
                        </Tooltip>
                      )}
                      <div className={classes.fileName}>{attach.file_name}</div>
                    </div>
                  </span>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        )}
        {!disabled && (
          <Grid item xs={12}>
            <div className={classes.dropzonePlaceholder}>
              <div
                style={{
                  opacity: 0.27,
                  fontSize: value?.length ? '1em' : '1.1em'
                }}
              >
                {t('xs_dropzone.hint')}
              </div>

              <Tooltip
                title={
                  <>
                    <Typography variant='body2'>
                      <span>{t('xs_dropzone.limitSize')}</span>
                      <span>
                        <b>{`${bytesToMB(settings.UPLOAD_FILE_SIZE_LIMIT)} MB `}</b>
                      </span>
                    </Typography>
                    <Typography variant='body2'>
                      <span>{t('xs_dropzone.limitFileTypes')}</span>
                      <span>
                        <b>{accept.join(', ')}</b>
                      </span>
                    </Typography>
                  </>
                }
                placement='left-end'
              >
                <span className={classes.hintContainer}>?</span>
              </Tooltip>
            </div>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <div
        {...getRootProps()}
        className={classes.dropzoneContainer}
        style={
          {
            // borderColor: disabled ? '#999999' : '#444444',
          }
        }
      >
        <div className={classes.labelContainer} onClick={(e) => e.stopPropagation()}>
          {`${t('xs_dropzone.defaultLabel')} (${value ? value.length : 0}/${maxFiles})`}
        </div>
        <input {...getInputProps({ onChange: onDropzoneChange })} />

        {renderContent()}
      </div>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>}
      <XsConfirmationDialog
        type='danger'
        name='removeFile'
        titleIcon={<i className='fas fa-trash-alt fa-2x'></i>}
        content={t('xs_dropzone.delete_file')}
        onConfirmation={() => deleteFile(UIStore.confParams.fileIndex)}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzoneContainer: {
      width: 'auto !important',
      minHeight: '112px !important',
      backgroundColor: '#fafafa',
      borderRadius: 4,
      border: '2px dashed #c4c4c4',
      padding: 8,
      position: 'relative',
      display: 'flex',
      zIndex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    dropzonePlaceholder: {
      textAlign: 'center',
      marginTop: '5px',
      fontSize: '1em',
      zIndex: -10
    },
    droppedFile: {
      position: 'relative',
      textAlign: 'center',
      borderRadius: 4,
      padding: 4,
      width: 75,

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f0f0f0'
      } as CSSProperties
    },
    labelContainer: {
      position: 'absolute',
      top: -10,
      left: 10,
      fontSize: 12,
      padding: '1px 8px 0px 4px',
      backgroundColor: 'white',
      color: '#22232c'
    },
    fileName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: '12px'
    },
    deleteIcon: {
      position: 'absolute',
      color: 'red',
      right: 5,
      padding: 4,
      borderRadius: '50%',
      fontSize: 14,

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        color: '#ff2d2d'
      } as CSSProperties
    },
    droppedFileIcon: {
      fontSize: '32px',
      color: 'dimgray'
    },
    hintContainer: {
      position: 'absolute',
      width: 16,
      height: 16,
      backgroundColor: '#c7c7c7',
      color: 'white',
      bottom: 8,
      right: 8,
      borderRadius: '50%',
      fontSize: 14,
      fontWeight: 'bold',
      padding: 2
    }
  })
);
