import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FieldPath, FieldValues, Path, UnpackNestedValue, UseFormReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  QueryKey,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryOptions,
  hydrate,
  useQuery
} from 'react-query';
import { useHistory, useLocation } from 'react-router';
import queryClient from '../../global/helpers/query';
import EnergeticAuditStore from '../../modules/common/energeticAudit/EnergeticAuditStore';
import { EABreadcrumb } from '../../modules/common/energeticAudit/audits/header/EnergeticAuditHeaderContainer';
import LoginStore from '../../modules/common/login/LoginStore';
import SavingsPotentialStore from '../../modules/common/pu/SavingsPotentialStore';
import { SPBreadcrumb } from '../../modules/common/pu/main/header/SPHeaderContainer';
import { ERR_CODE_MAINTENANCE, IS_FO } from '../config/constants';
import settings from '../config/settings';
import { ICodeListResponse } from '../interfaces/responses/codeList.response.interface';
import { getByKey } from '../storage/StorageEx';
import AutoLogoutStore from '../stores/AutoLogoutStore';
import ServerTableStore from '../stores/ServerTableStore';
import UIStore from '../stores/UIStore';
import { equalsDate, err, getCompanies, setCompany, showServerError } from './actions';

export const useDebounce = <T extends unknown>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

interface IError {
  code: number;
  description: string;
  domain: string;
  error: string;
  id: string;
  name: string;
  params?: any[];
  stack: any[];
}

interface IErrorResponse {
  errors: IError[];
  success: boolean;
  summary: string;
  time: string;
  _type: string;
}

export interface IServerCallErrorResponse {
  code: number;
  params: any;
  desc: string;
  description?: string | null;
}

interface IUseServerCallProps<T, K> {
  key: QueryKey;
  /** default: 'auto' */
  mode?: 'auto' | 'control';
  request: AxiosRequestConfig<T>;
  queryOptions?: Omit<UseQueryOptions<K, AxiosError<K, T>, K, QueryKey>, 'queryKey' | 'queryFn'>;
  disableSnackError?: boolean;
  APIBaseURL?: string;
  onSuccess?: (response: AxiosResponse<K, T>, request?: AxiosRequestConfig<T>) => void;
  onError?: (error: string, errors?: IServerCallErrorResponse[]) => void;
  disabled?: boolean;
  disableServerError?: boolean;
}

export interface IUseServerCall<TReq, TRes> {
  data: TRes | undefined;
  isPending: boolean;
  isError: boolean;
  run: (request?: Partial<AxiosRequestConfig<TReq>>) => void;
  runAsync: (request?: Partial<AxiosRequestConfig<TReq>>) => Promise<TRes | undefined>;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<TRes, AxiosError<TRes, TReq>>>;
}

export const useServerCall = <TReq extends unknown, TRes extends unknown>(
  props: IUseServerCallProps<TReq, TRes>
): IUseServerCall<TReq, TRes> => {
  const {
    key,
    mode = 'auto',
    request,
    queryOptions,
    disabled = false,
    onError,
    onSuccess,
    /* disableSnackError */
    disableServerError = false
  } = props;

  const [control, setControl] = useState(false);
  const [dynamicRequest, setDynamicRequest] = useState<AxiosRequestConfig<TReq>>(request);
  const history = useHistory();

  const axiosRequest = useCallback(async () => {
    if (mode === 'control') setControl(false);
    let axiosInstance = axios.create();
    let response: AxiosResponse<TRes, TReq> | null = null;
    try {
      //Public URL requests are without auth header
      if (settings.API_PUBLIC_URL && dynamicRequest?.url?.includes(settings.API_PUBLIC_URL)) {
        dynamicRequest.headers = {
          ...dynamicRequest.headers,
          Authorization: ''
        };
      }

      response = await axiosInstance.request<TRes, AxiosResponse<TRes, TReq>, TReq>(dynamicRequest);
      if (onSuccess) {
        onSuccess(response, dynamicRequest);

        // AUTO LOGOUT
        const tokenExpiration = response?.headers?.authorization
          ?.split(';')
          ?.find((x) => x.includes('expires'))
          ?.split('=')[1];

        const tokenExpDate = tokenExpiration ? new Date(tokenExpiration) : null;
        const storedTokenExp = AutoLogoutStore.tokenExpDateUTC;
        if (!storedTokenExp || (tokenExpDate && storedTokenExp && tokenExpDate > storedTokenExp)) {
          AutoLogoutStore.setTokenExpDateUTC(tokenExpDate);
        }
      }
      return response.data;
    } catch (catchedErr: any) {
      if (axios.isAxiosError(catchedErr)) {
        const errResponse = (catchedErr as AxiosError<TRes, TReq>).response;
        if (errResponse?.status === 401) {
          //Unauthorized
          if (LoginStore.isAuthorized) {
            LoginStore.LogOut();
            LoginStore.ShowReloginDialog(refetch);
          } else if (LoginStore.Refetch.length > 0) {
            LoginStore.ShowReloginDialog(refetch);
          }
        }

        const errData = errResponse?.data as IErrorResponse | undefined;
        const errorDescription = errData?.errors?.[0]?.description ?? '';

        const errors = errData?.errors?.map((x) => {
          return {
            code: x.code,
            desc: x.description ?? 'Unknown error',
            params: x.params?.reduce((acc, val, index) => {
              acc[`param${index}`] = val;
              return acc;
            }, {})
          };
        });

        // Maintenance error type
        if (errors && errors[0].code === ERR_CODE_MAINTENANCE) {
          try {
            axios.post(settings.API_PUBLIC_URL + '/sec/logout');
            UIStore.setMaintenance(true);
            LoginStore.LogOut();
            history.push('/login');
          } catch (error) {
            err(error);
          }
        } else {
          if (!disableServerError) showServerError(errors);
        }

        if (errorDescription) {
          if (onError) {
            onError(errorDescription, errors);
          }
          throw new Error(errorDescription);
        } else {
          if (onError) {
            onError('Unknown error', errors);
          }
        }
        throw new Error('Unknown error: ' + JSON.stringify(catchedErr.toJSON()));
      } else {
        throw new Error(catchedErr ?? 'Unknown error');
      }
    } finally {
      // if (mode === 'control') setControl(false);
    }
  }, [dynamicRequest, onSuccess, onError, mode]);

  const { data, isFetching, isLoading, isError, refetch } = useQuery<TRes, AxiosError<TRes, TReq>>(
    key,
    axiosRequest,
    Object.assign({}, queryOptions, mode === 'control' ? { enabled: !disabled && control } : { enabled: !disabled }, {
      retry: false
    })
  );

  const run = useCallback(
    (req?: Partial<AxiosRequestConfig<TReq>>) => {
      if (mode === 'control') {
        // const nextReq = Object.assign({}, dynamicRequest, request);

        // if (JSON.stringify(nextReq) === JSON.stringify(dynamicRequest)) {
        //   return;
        // }

        if (req) {
          setDynamicRequest((previousRequest) => Object.assign({}, previousRequest, req));
        }
        setControl(true);
      }
    },
    [mode, request, dynamicRequest]
  );

  const runAsync = useCallback(
    async (request?: Partial<AxiosRequestConfig<TReq>>) => {
      const nextReq = Object.assign({}, dynamicRequest, request);
      // if (JSON.stringify(nextReq) === JSON.stringify(dynamicRequest)) return;

      if (mode === 'control') {
        if (request) {
          let axiosInstance = axios.create();
          const response = await axiosInstance.request<TRes, AxiosResponse<TRes, TReq>, TReq>(nextReq);
          return response.data;
        }
      }
    },
    [mode]
  );

  return { data, isPending: isFetching || isLoading, isError, run, runAsync, refetch };
};

export const useCodeList = <T extends unknown>(codeListName: string) => {
  let data: AxiosResponse<T> | undefined = queryClient.getQueryData<AxiosResponse<T>>(codeListName);

  if (!data) {
    const state = getByKey('codeLists');
    if (state) {
      hydrate(queryClient, state);
      data = queryClient.getQueryData<AxiosResponse<T>>(codeListName);
    }
  }

  let rowsData = data?.data as ICodeListResponse;
  if (rowsData && Array.isArray(rowsData.rows)) {
    rowsData.rows = rowsData.rows.sort((a, b) => (b?.item_order ?? 0) - (a?.item_order ?? 0));
    return { data: rowsData as T };
  } else {
    return { data: data?.data };
  }
};

export const useServerPutCall = <TRequest extends unknown, T>(
  key?: QueryKey,
  successMsgKey?: string,
  onSuccess?: (response?: AxiosResponse<T, TRequest>) => void,
  closeFormDialogOnSuccess: boolean = true,
  disableServerError: boolean = false,
  onError?: (error: string, errors?: IServerCallErrorResponse[]) => void
) => {
  const { t } = useTranslation();
  const callKey: QueryKey = key ?? (new Date().getTime() + Math.random()).toString();
  return useServerCall<TRequest, T>({
    key: callKey,
    mode: 'control',
    request: { method: 'PUT' },
    queryOptions: { retry: false },
    disableServerError,
    onSuccess: (response) => {
      if (successMsgKey) {
        UIStore.setNotificationMessage(t(successMsgKey), 'success');
      }
      if (onSuccess) {
        onSuccess(response);
      }
      if (closeFormDialogOnSuccess) {
        UIStore.closeFormDialog();
      }
    },
    onError: (error, errors) => {
      onError?.(error, errors);
    }
  });
};

export const useServerDeleteCall = <TRequest extends unknown, T>(
  key?: QueryKey,
  successMsgKey?: string,
  onSuccess?: (response?: AxiosResponse<T, TRequest>) => void,
  closeFormDialogOnSuccess: boolean = true
) => {
  const { t } = useTranslation();
  const callKey: QueryKey = key ?? (new Date().getTime() + Math.random()).toString();
  return useServerCall<TRequest, T>({
    key: callKey,
    mode: 'control',
    request: { method: 'DELETE' },
    queryOptions: { retry: false },
    onSuccess: (response) => {
      if (successMsgKey) {
        UIStore.setNotificationMessage(t(successMsgKey), 'success');
      }
      if (onSuccess) {
        onSuccess(response);
      }
      if (closeFormDialogOnSuccess) {
        UIStore.closeFormDialog();
      }
    },
    onError: (error) => {
      // UIStore.setNotificationMessage(error, 'error'); // pouzite v useServerCall
    }
  });
};

export const useServerFilter = ({
  Renderer,
  initParams,
  propsForRenderer
}: {
  Renderer: Function;
  initParams: any;
  propsForRenderer?: any;
}) => {
  const [data, setData] = useState<any>(initParams);
  const [shouldRefresh, setShouldRefresh] = useState<number | undefined>(undefined);

  const handleChange = (changed: any) => {
    setData(changed);
    setShouldRefresh(Date.now());
  };

  return {
    ServerFilterComponent: (
      <form>
        <Renderer {...data} onChange={handleChange} {...propsForRenderer} initParams={initParams} />
      </form>
    ),
    serverFilterData: data,
    shouldRefresh
  };
};

export const useError = (name?: string) => {
  const { t } = useTranslation();
  const form = useFormContext();

  const getValue = (obj: any, name?: string) => {
    if (obj === undefined || obj === null || name === undefined) return null;
    let value = obj;
    if (typeof value === 'object') {
      for (const prop of name.split('.')) {
        if (value) {
          value = value[prop];
        }
      }
    }
    return value;
  };

  let formStateErrors = getValue(form?.formState?.errors, name); //form?.formState?.errors[name ?? ''];
  let error: boolean = false;
  let helperText: string[] = [];

  if (formStateErrors) {
    formStateErrors = Array.isArray(formStateErrors) ? formStateErrors : [formStateErrors];

    error = formStateErrors.some((formStateError: any) => formStateError !== undefined);

    formStateErrors.forEach((formStateError: any) => {
      if (formStateError.type) {
        const translationArgs = {
          name: t(`validation.${name}`),
          index: formStateError.message.substring(
            formStateError.message.indexOf('[') + 1,
            formStateError.message.lastIndexOf(']')
          )
        };
        helperText.push(
          t(
            [`validation.${formStateError.message}`, `validation.${formStateError.type}`, `validation.${name}`],
            translationArgs
          )
        );
      } else if (formStateError.message) {
        helperText.push(formStateError.message);
      }
    });
  }
  return { error, helperText };
};

interface IUseLoadData<TReq, TRes, TForm extends FieldValues>
  extends Omit<IUseServerCallProps<TReq, TRes>, 'mode' | 'onSuccess'> {
  form: UseFormReturn<TForm>;
  transform?: (data: TRes) => Promise<Partial<TForm>> | Partial<TForm>;
  onlyFormFields?: boolean;
}

export const useLoadData = <TReq extends unknown, TRes extends unknown, TForm extends FieldValues>(
  props: IUseLoadData<TReq, TRes, TForm>
) => {
  const { transform, form, onlyFormFields = false, ...serverCallProps } = props;
  const { data, isPending, isError } = useServerCall(serverCallProps);

  const handleData = useCallback(async () => {
    if (data && !isPending) {
      let formData: Partial<TForm> = data as TForm;
      if (transform) formData = await transform(data);

      const fieldNames = Object.keys(form.getValues() as any);

      for (const fieldName in formData) {
        if (!onlyFormFields || fieldNames.some((x) => x === fieldName)) {
          form.setValue(fieldName as unknown as Path<TForm>, (formData as any)[fieldName] ?? null);
        }
      }
    }
  }, [data, isPending]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  return { data, isPending, isError };
};

export interface IFormFieldChange {
  field: string;
  originalValue: any;
  formValue: any;
}

interface IUseFormChangesProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  formId?: string;
  formInDialog?: boolean;
  initDataRef: MutableRefObject<T | null>;
  exceptions?: Extract<keyof UnpackNestedValue<T> | FieldPath<T>, string>[];
}

export const useFormChanges = <T extends FieldValues>(props: IUseFormChangesProps<T>) => {
  const { form, exceptions = [], initDataRef, formId = Date.now().toString(), formInDialog = false } = props;

  const getChanges = useCallback((origData: any) => {
    let changedFields: IFormFieldChange[] = [];

    if (form) {
      const changes = form.getValues();
      const originalData = typeof origData === 'object' ? origData ?? {} : origData;

      for (const field in changes) {
        if (exceptions.includes(field)) continue;

        let formValue: any = changes[field] ?? '';
        let originalValue = originalData[field] ?? '';

        if (originalValue instanceof Date && formValue instanceof Date && !equalsDate(originalValue, formValue)) {
          changedFields.push({ field, originalValue, formValue });
        } else if (Array.isArray(formValue) && Array.isArray(originalValue)) {
          //Replacer all numbers as strings and from object only _id
          let formValueObjs: any[] = [];
          for (const item of formValue) {
            if (item === Object(item)) {
              let formValueObj: any = {};
              for (const key of Object.keys(item).sort()) {
                formValueObj[key] = item[key];
              }
              formValueObjs.push(formValueObj);
            } else {
              formValueObjs.push(item);
            }
          }
          let originalValueObjs: any[] = [];
          for (const item of originalValue) {
            if (item === Object(item)) {
              let originalValueObj: any = {};
              for (const key of Object.keys(item).sort()) {
                originalValueObj[key] = item[key];
              }
              originalValueObjs.push(originalValueObj);
            } else {
              originalValueObjs.push(item);
            }
          }
          const replacer = (_: string, value: any) => {
            if (typeof value === 'number') {
              return value;
            } else if (typeof value === 'string' && !isNaN(+value)) {
              return +value;
            } else if (typeof value === 'object' && value?._id) {
              return value._id;
            } else return value;
          };
          if (JSON.stringify(formValueObjs, replacer) !== JSON.stringify(originalValueObjs, replacer)) {
            changedFields.push({ field, originalValue, formValue });
          }
          continue;
        } else {
          if (formValue === Object(formValue) && originalValue === Object(originalValue)) {
            if (originalValue._id && (formValue as any)._id) {
              formValue = (formValue as any)._id;
              originalValue = originalValue._id;
            } else {
              for (const prop in originalValue) {
                if ((exceptions as string[]).includes(`${field}.${prop}`)) continue;
                let formValueAny: any = (formValue as any)[prop];
                if (typeof originalValue[prop] === 'number' || typeof formValueAny === 'number') {
                  formValueAny = +formValueAny;
                  originalValue[prop] = +originalValue[prop];
                }
                if (formValueAny !== originalValue[prop]) {
                  changedFields.push({
                    field: `${field}.${prop}`,
                    originalValue: originalValue[prop],
                    formValue: formValueAny
                  });
                }
              }
              continue;
            }
          }
        }
        if (typeof originalValue === 'number' || typeof formValue === 'number') {
          formValue = +formValue;
          originalValue = +originalValue;
        }
        if (formValue !== originalValue) {
          changedFields.push({ field, originalValue, formValue });
        }
      }
    }

    return changedFields;
  }, []);

  // save reference to check form changes globally
  useEffect(() => {
    UIStore.addCheckingForm(formId, {
      getChanges: () => getChanges(initDataRef?.current),
      inDialog: formInDialog
    });

    return () => {
      UIStore.removeCheckingForm(formId);
    };
  }, []);

  return getChanges;
};

export const useClearForm = (form: UseFormReturn<any, any>) => {
  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    const formVals = form.getValues() ?? null;
    if (formVals) {
      const fields = Object.keys(formVals);
      for (const fieldIndex in fields) {
        const field = fields[fieldIndex];
        form.setValue(field, '', { shouldValidate: false, shouldDirty: false, shouldTouch: false });
      }
    }
  }, [resetForm]);

  const run = () => {
    setResetForm(!resetForm);
  };

  return run;
};

export const useSummaryBreadcrumbs = () => {
  const { t } = useTranslation();

  return [
    {
      ID: EABreadcrumb.HEADER_LIST,
      label: t('energeticAudit.audit_list.list'),
      action: () => {
        EnergeticAuditStore.clearSummaryData();
        EnergeticAuditStore.setEaTabScreen('EAList');
      }
    },
    {
      ID: EABreadcrumb.HEADER_DETAIL,
      label: (
        <span>
          {t('energeticAudit.audit_header.breadcrumb')}:{' '}
          <span className='bold'>{EnergeticAuditStore.energeticAuditBaseInfo?.identifier ?? ''}</span>
        </span>
      ),
      action: () => {
        EnergeticAuditStore.clearSummaryData();
        EnergeticAuditStore.setEaTabScreen('header');
      }
    },
    {
      ID: EABreadcrumb.SUMMARY,
      label: t('energeticAudit.audit_summary.breadcrumb'),
      action: () => null
    }
  ];
};

export const useSummaryBreadcrumbsEx = () => {
  const { t } = useTranslation();

  return (identifier: string) => [
    {
      ID: EABreadcrumb.HEADER_LIST,
      label: t('energeticAudit.audit_list.list'),
      action: () => {
        EnergeticAuditStore.clearSummaryData();
        EnergeticAuditStore.setEaTabScreen('EAList');
      }
    },
    {
      ID: EABreadcrumb.HEADER_DETAIL,
      label: (
        <span>
          {t('energeticAudit.audit_header.breadcrumb')}: <span className='bold'>{identifier}</span>
        </span>
      ),
      action: () => {
        EnergeticAuditStore.clearSummaryData();
        EnergeticAuditStore.setEaTabScreen('header');
      }
    },
    {
      ID: EABreadcrumb.SUMMARY,
      label: t('energeticAudit.audit_summary.breadcrumb'),
      action: () => null
    }
  ];
};

export const useSPSummaryBreadcrumbs = () => {
  const { t } = useTranslation();

  return [
    {
      ID: SPBreadcrumb.HEADER_LIST,
      label: t('sp.breadcrumbs.list'),
      action: () => {
        SavingsPotentialStore.clearSummaryData();
        SavingsPotentialStore.setSPTabScreen('SPList');
      }
    },
    {
      ID: SPBreadcrumb.HEADER_DETAIL,
      label: (
        <span>
          {t('sp.breadcrumbs.report')}:{' '}
          <span className='bold'>{SavingsPotentialStore?.spBaseInfo?.identifier ?? ''}</span>
        </span>
      ),
      action: () => {
        SavingsPotentialStore.clearSummaryData();
        SavingsPotentialStore.setSPTabScreen('header');
      }
    },
    {
      ID: SPBreadcrumb.SUMMARY,
      label: t('sp.breadcrumbs.summary'),
      action: () => null
    }
  ];
};

export const useSPSummaryBreadcrumbsEx = () => {
  const { t } = useTranslation();

  return (identifier: string) => [
    {
      ID: SPBreadcrumb.HEADER_LIST,
      label: t('sp.breadcrumbs.list'),
      action: () => {
        SavingsPotentialStore.clearSummaryData();
        SavingsPotentialStore.setSPTabScreen('SPList');
      }
    },
    {
      ID: SPBreadcrumb.HEADER_DETAIL,
      label: (
        <span>
          {t('sp.breadcrumbs.report')}: <span className='bold'>{identifier ?? ''}</span>
        </span>
      ),
      action: () => {
        SavingsPotentialStore.clearSummaryData();
        SavingsPotentialStore.setSPTabScreen('header');
      }
    },
    {
      ID: SPBreadcrumb.SUMMARY,
      label: t('sp.breadcrumbs.summary'),
      action: () => null
    }
  ];
};

interface IUseNavigationProps {
  navigateToListFn: () => void;
  navigateToDetailFn: (id: string) => void;
  navigateToSummaryFn?: (id: string) => void;
  navigateToDetailInNewWindowFn?: (params: URLSearchParams) => void;
}

export const useNavigation = (props: IUseNavigationProps) => {
  const { navigateToListFn, navigateToDetailFn, navigateToSummaryFn, navigateToDetailInNewWindowFn } = props;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search !== '') {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('id');
      const summary = searchParams.get('summary');

      if (id && summary === '' && navigateToSummaryFn) {
        navigateToSummaryFn(id);
        return;
      }

      if (id) {
        if (
          navigateToDetailInNewWindowFn &&
          Array.from(searchParams.keys()).some((x) => !['id', 'summary'].includes(x))
        ) {
          navigateToDetailInNewWindowFn(searchParams);
          return;
        }
        navigateToDetailFn(id);
        return;
      }

      history.replace(location.pathname);
    }
    navigateToListFn();
  }, [location]);

  const onRowClick = (id: string) => {
    history.push(`?id=${id}`);
  };

  const onSummaryClick = (id: string) => {
    history.push(`?id=${id}&summary`);
  };

  const onBack = () => {
    history.push(location.pathname);
  };

  return { onRowClick, onSummaryClick, onBack };
};

interface IUseCompanyProps {
  data: any;
  isPending: boolean;
  isError: boolean;
  companyIdSelector?: (data: any) => string;
}

export const useCompany = (props: IUseCompanyProps) => {
  const { data, isPending, isError, companyIdSelector = (data) => data.company_id } = props;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError && !isPending) {
      UIStore.setNotificationMessage(t('global.data_not_found_error'), 'error');
      history.push('/welcome');
    }

    if (IS_FO && data && !isPending && !isError) {
      const companies = getCompanies()?.filter((c) => c.company_id === companyIdSelector(data));
      if (companies && companies.length > 0) {
        if (!companies?.some((c) => c.company_id === LoginStore.currentCompany?.company_id)) {
          setCompany(companies[0]);
        }
      } else {
        if (data.submitting_company?.identifier && LoginStore.currentCompany?.identifier) {
          if (data.submitting_company?.identifier !== LoginStore.currentCompany?.identifier) {
            UIStore.setNotificationMessage(t('global.company_not_found_error'), 'error');
            history.push('/welcome');
          }
        } else {
          if (data.submitting_person?._id !== LoginStore.userInfo?.person?._id) {
            UIStore.setNotificationMessage(t('global.company_not_found_error'), 'error');
            history.push('/welcome');
          }
        }
      }
    }
  }, [props]);
};

interface IUseServerTableProps<T extends object> {
  name: string;
  defaultSort?: { id: keyof T; desc: boolean };
  defaultPageSize?: number;
}

export const useServerTable = <T extends object>(props: IUseServerTableProps<T>) => {
  const { name, defaultSort, defaultPageSize } = props;
  const [refetchAfterDelete, setRefetchAfterDelete] = useState<number | undefined>(undefined);
  const [refetchAfterChangeDefaultSort, setRefetchAfterChangeDefaultSort] = useState<number | undefined>(undefined);
  const lastDefaultSort = useRef<{ id: keyof T; desc: boolean } | undefined>(defaultSort);

  useEffect(() => {
    const stringLastSort = JSON.stringify(lastDefaultSort.current);
    const stringActualSort = JSON.stringify(defaultSort);

    if (stringLastSort !== stringActualSort) {
      setRefetchAfterChangeDefaultSort(Date.now());
      lastDefaultSort.current = defaultSort;
      ServerTableStore.setSort(name, null);
    }
  }, [defaultSort]);

  const getSortby = () => {
    if (defaultSort) {
      return [defaultSort];
    }

    // defaultSort som v GUI zmazal
    if (!defaultSort && lastDefaultSort.current !== undefined) {
      return [];
    }

    return ServerTableStore.getSort(name).sortBy ?? [];
  };

  return {
    name,
    initState: {
      sortBy: getSortby(),
      ...ServerTableStore.getPagination(name, defaultPageSize),
      ...ServerTableStore.getExpanded(name)
    },
    refetchAfterChangeDefaultSort,
    refetchAfterDelete,
    refetchAfterDeleteFn: () => setRefetchAfterDelete(Date.now())
  };
};

export const useRefresh = (refreshes: (number | undefined)[]) => {
  const arr: number[] = refreshes.filter((x) => x !== undefined) as number[];
  return { shouldRefresh: arr.length === 0 ? undefined : Math.max(...arr) };
};
