import { Menu, MenuItem } from '@mui/material';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XsButton, XsIconButton, XsLoading } from '.';

interface IMenuItem {
  item: string | JSX.Element;
  onClick: () => void;
  isLoading?: boolean;
  isHidden?: boolean;
}

type Props = {
  button: string | JSX.Element;
  items: IMenuItem[];
  disabled?: boolean;
  onClick?: () => void;
};

export const XsMenu = (props: Props) => {
  const { button, items, disabled, onClick } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (typeof onClick === 'function') onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
      {typeof button === 'string' ? (
        <XsButton
          className='white'
          disabled={disabled}
          onClick={handleClick}
          label={t(button)}
          endIcon={!disabled ? <i className='fas fa-caret-down'></i> : null}
        />
      ) : (
        <XsIconButton className='white' onClick={handleClick} size='small' icon={button} />
      )}
      {disabled !== true && items?.length > 0 && (
        <Menu
          id='basic-menu'
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {items
            .filter((x) => !x.isHidden)
            .map((x, idx) => (
              <XsMenuItem key={`mwnu-item-${idx}`} menuItem={x} handleClose={handleClose} />
            ))}
        </Menu>
      )}
    </div>
  );
};

type XsMenuItemProps = {
  menuItem: IMenuItem;
  handleClose: () => void;
};

const OFFSET = 50;
const LOADING_SIZE = 20;

const XsMenuItem = (props: XsMenuItemProps) => {
  const { menuItem, handleClose } = props;
  const { t } = useTranslation();
  const [wasClicked, setWasClicked] = useState(false);

  useEffect(() => {
    if (wasClicked && menuItem.isLoading === false) {
      handleClose();
    }
  }, [menuItem.isLoading]);

  const renderItemContent = () => {
    if (typeof menuItem.item === 'string') {
      return (
        <div style={{ position: 'relative', width: '100%' }}>
          <span>{t(menuItem.item)}</span>
          {menuItem.isLoading && (
            <span style={{ position: 'absolute', right: LOADING_SIZE - OFFSET }}>
              <XsLoading size={LOADING_SIZE} />
            </span>
          )}
        </div>
      );
    } else {
      return menuItem.item;
    }
  };

  return (
    <MenuItem
      disabled={menuItem.isLoading}
      style={{ paddingRight: OFFSET }}
      onClick={() => {
        menuItem.onClick();

        if (menuItem.hasOwnProperty('isLoading' as keyof IMenuItem)) {
          setWasClicked(true);
        } else {
          handleClose();
        }
      }}
    >
      {renderItemContent()}
    </MenuItem>
  );
};
