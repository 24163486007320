import React from 'react';
import { observer } from 'mobx-react-lite';
// import { FormattedMessage } from "react-intl"

import { Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography } from '@mui/material';

import './ConfDialog.scss';

import UIStore from '../../stores/UIStore';
import { XsButton } from '..';
import { IConfirmationDialog } from '../../types/confirmationDialog';
import { useTranslation } from 'react-i18next';

export default observer(
  ({
    name,
    title,
    titleIcon,
    subTitle,
    content,
    type,
    onConfirmation,
    confirmBtn,
    cancelBtn,
    onCancel,
    justifyActions = ['flex-end'],
    directionActions = 'row',
    hideCancelButton = false
  }: IConfirmationDialog) => {
    const { t } = useTranslation();

    const handleClose = (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        if (onCancel && typeof onCancel === 'function') {
          onCancel();
        }
        UIStore.closeConfirmationDialog();
      }
    };

    const handleConfirm = () => {
      if (onConfirmation && typeof onConfirmation === 'function') {
        onConfirmation();
      }
      UIStore.closeConfirmationDialog();
    };

    let cancelBtnClassName = '';
    let confirmBtnClassName = '';

    if (cancelBtn && cancelBtn.className) {
      cancelBtnClassName = cancelBtn.className;
    } else {
      cancelBtnClassName = `xs-outlined-${type}`;
    }

    if (confirmBtn && confirmBtn.className) {
      confirmBtnClassName = confirmBtn.className;
    } else {
      confirmBtnClassName = `xs-${type}`;
    }

    const confirmBtnVariant = confirmBtn?.variant ?? 'contained';
    const cancelBtnVariant = cancelBtn?.variant ?? 'outlined';

    const dialog =
      name === UIStore.confirmationDialogOpened ? (
        <Dialog
          open={true}
          keepMounted
          onClose={handleClose}
          maxWidth='md'
          className={`xs-confirmation-dialog ${name}`}
        >
          <DialogTitle className={type ? `xs-title xs-title-${type}` : 'xs-title'}>
            <Grid container direction='column'>
              {titleIcon && <Grid item>{titleIcon}</Grid>}
              {title ? (
                <Grid item>
                  <Typography variant='h6'>{t(title)}</Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant='h6'>{t('global.warning')}</Typography>
                </Grid>
              )}
              {subTitle && <Typography variant='body2'>{t(subTitle)}</Typography>}
            </Grid>
          </DialogTitle>
          <DialogContent className='dialog-content'>
            <Typography variant='body2'>{content && content}</Typography>
          </DialogContent>
          <DialogActions className='xs-footer'>
            <Grid container spacing={2} direction={directionActions} justifyContent={justifyActions}>
              {hideCancelButton === false && (
                <React.Fragment>
                  <Grid item>
                    <XsButton
                      variant={cancelBtnVariant}
                      className={cancelBtnClassName}
                      startIcon={cancelBtn?.icon ? cancelBtn.icon : <i className='fal fa-times' />}
                      label={cancelBtn?.label ? t(cancelBtn.label) : t('global.false')}
                      onClick={() => handleClose()}
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item>
                <XsButton
                  variant={confirmBtnVariant}
                  className={confirmBtnClassName}
                  startIcon={confirmBtn?.icon ? confirmBtn.icon : <i className='fal fa-check' />}
                  label={confirmBtn?.label ? t(confirmBtn.label) : t('global.true')}
                  onClick={() => handleConfirm()}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : null;

    return dialog;
  }
);
