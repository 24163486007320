import React from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TableStore from '../stores/TableStore';

import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useGroupBy,
  useExpanded
} from 'react-table';

import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  TextField,
  Tooltip
} from '@mui/material';

import { XsIconButton } from './XsIconButton';

// import {FormattedMessage, injectIntl} from "react-intl"

// import "./xsTable.less";

const RTable = ({
  name,
  columns,
  data,
  onRowClick,
  colorful,
  size,
  defaultSort,
  disabledSort,
  groupByCol,
  noPagination,
  summaryRows,
  rowPerPageDefault
}) => {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150
      // maxWidth: 400,
    }),
    []
  );

  let initialState = {
    pageIndex: TableStore.tables[name]?.page ?? 0,
    pageSize: noPagination ? 10000 : rowPerPageDefault,
    groupBy: [groupByCol]
  };

  if (defaultSort) {
    initialState['sortBy'] = [defaultSort];
  }

  const {
    getTableProps,
    getTableBodyProps,
    // footerGroups,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    // setGroupBy,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: initialState,
      showPagination: false,
      manualSortBy: true,
      disableSortBy: disabledSort
    },
    useResizeColumns,
    useFlexLayout,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const isRowClick = onRowClick && typeof onRowClick === 'function' ? true : false;
  return (
    <React.Fragment>
      <Table {...getTableProps()} className='r-table'>
        <TableHead>
          {headerGroups.map((headerGroup, idx) => (
            <TableRow key={idx} {...headerGroup.getHeaderGroupProps()} className='bgGreyLight'>
              {headerGroup.headers.map((column, i) => {
                // const {isResizing, getResizerProps} = column
                return (
                  <TableCell
                    key={`${idx}_${i}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames('th', { small: size === 'small' })}
                    // className={`td resizer ${isResizing ? "isResizing" : ""}`}
                  >
                    {/* {column.canGroupBy ? (
											<span {...column.getGroupByToggleProps()}>{column.isGrouped ? "🛑 " : "👊 "}</span>
										) : null} */}
                    <div style={{ textAlign: column.align ? column.align : 'left', whiteSpace: 'pre-line' }}>
                      {column.render('Header')}
                    </div>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span>
                            {' '}
                            <i className='fal fa-long-arrow-down'></i>
                          </span>
                        ) : (
                          <span>
                            {' '}
                            <i className='fal fa-long-arrow-up'></i>
                          </span>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    {/* <div {...getResizerProps()} className={`resizer ${isResizing ? "isResizing" : ""}`} /> */}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <React.Fragment key={idx}>
                <TableRow
                  {...row.getRowProps()}
                  className={classnames({
                    //fix me
                    bgWhite: !groupByCol && colorful && idx % 2 === 1
                  })}
                >
                  {row.cells.map((cell, i) => {
                    const cellClick = isRowClick && !cell.column.clickable;

                    const tooltip = cell.column.Tooltip ? cell.column.Tooltip(row.original) : null;

                    return (
                      <TableCell
                        key={`${idx}_${i}`}
                        onClick={() =>
                          cellClick
                            ? (onRowClick(row.original),
                              TableStore.saveSelectedRowByName(name, JSON.stringify(row.original)))
                            : null
                        }
                        {...cell.getCellProps()}
                        // style={{background: cell.isGrouped ? "#0aff0082" : "white"}}
                        className={classnames('td', {
                          pointer: cellClick,
                          small: size === 'small',
                          bgWhite: cell.isAggregated || cell.isGrouped,
                          bgGreenLight: TableStore.tables[name]?.selectedRow === JSON.stringify(row.original),
                          textRight: cell.column?.align === 'right',
                          textCenter: cell.column?.align === 'center'
                        })}
                        // style={{padding: padding}}
                      >
                        {cell.isGrouped ? (
                          <React.Fragment>
                            <div {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <i className='fas fa-caret-down fa-lg pr-1'></i>
                              ) : (
                                <i className='fas fa-caret-right fa-lg pr-1'></i>
                              )}{' '}
                              {cell.render('Cell')} ({row.subRows.length})
                            </div>
                          </React.Fragment>
                        ) : cell.isAggregated ? (
                          <React.Fragment>
                            {row.isGrouped ? (
                              <div
                                {...row.getToggleRowExpandedProps()}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                &nbsp;{cell.render('Aggregated')}{' '}
                                {/* hack, aby bolo mozne otvarat a zatvarat groupu, pri kliku na cely riadok */}
                              </div>
                            ) : (
                              cell.render('Aggregated')
                            )}
                          </React.Fragment>
                        ) : cell.isPlaceholder ? null : (
                          <React.Fragment>
                            {row.isGrouped ? (
                              <div {...row.getToggleRowExpandedProps()}>
                                {/* {cell.render("Cell")} */}
                                <div style={{ display: 'block' }}></div>
                              </div>
                            ) : (
                              <>
                                {tooltip ? (
                                  <Tooltip title={tooltip}>
                                    <div style={{ height: '100%' }} className={classnames('tooltiped-content')}>
                                      {cell.render('Cell')}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {summaryRows &&
                  summaryRows.length > 0 &&
                  page.length === idx + 1 &&
                  summaryRows.map((summaryRow, index) => {
                    const lastIdx = summaryRows.length - 1;
                    const isLastRow = index === lastIdx;

                    return (
                      <TableRow
                        {...row.getRowProps()}
                        key={`summaryRow_row_${index}`}
                        className={classnames({ bgGreyLight: !isLastRow, bgGreySummary: isLastRow })}
                      >
                        {row.cells.map((cell, i) => {
                          const sumValue = summaryRow.row.find((x) => x.cellPosition === i + 1);

                          return (
                            <TableCell
                              key={`summaryRow_${idx}_${i}_${index}`}
                              {...cell.getCellProps()}
                              className={classnames('td', {
                                bold: sumValue?.cellPosition === 1 || sumValue?.bold,
                                small: size === 'small',
                                textRight: cell.column?.align === 'right',
                                textCenter: cell.column?.align === 'center'
                              })}
                            >
                              {sumValue ? sumValue.value : ''}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      {!noPagination && (
        <Box pt={1}>
          <Grid container spacing={2} alignItems='center' justifyContent='flex-end'>
            <Grid item>{t('table.rows_per_page')}:</Grid>
            <Grid item>
              <TextField size='small' select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                <MenuItem key={5} value={5}>
                  5
                </MenuItem>
                <MenuItem key={10} value={10}>
                  10
                </MenuItem>
                <MenuItem key={20} value={20}>
                  20
                </MenuItem>
                <MenuItem key={50} value={50}>
                  50
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              {pageIndex * pageSize + 1} -{' '}
              {pageIndex * pageSize + pageSize > rows.length ? rows.length : pageIndex * pageSize + pageSize}{' '}
              {t('table.of')} {rows.length}
            </Grid>
            <Grid item>
              <XsIconButton
                size='small'
                icon={<i className='far fa-chevron-double-left' />}
                onClick={() => {
                  gotoPage(0);
                  TableStore.saveTablePageByName(name, 0);
                }}
                disabled={!canPreviousPage}
                color='default'
              />
            </Grid>
            <Grid item>
              <XsIconButton
                size='small'
                icon={<i className='far fa-chevron-left' />}
                onClick={() => {
                  previousPage();
                  TableStore.saveTablePageByName(name, pageIndex !== 0 ? pageIndex - 1 : 0);
                }}
                disabled={!canPreviousPage}
                color='default'
              />
            </Grid>
            <Grid item>
              <XsIconButton
                size='small'
                icon={<i className='far fa-chevron-right' />}
                onClick={() => {
                  nextPage();
                  TableStore.saveTablePageByName(name, pageIndex + 1);
                }}
                disabled={!canNextPage}
                color='default'
              />
            </Grid>
            <Grid item>
              <XsIconButton
                size='small'
                icon={<i className='far fa-chevron-double-right' />}
                onClick={() => {
                  gotoPage(pageCount - 1);
                  TableStore.saveTablePageByName(name, pageCount - 1);
                }}
                disabled={!canNextPage}
                color='default'
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

function XsTable({
  columns,
  data,
  onRowClick,
  name = '',
  size = 'small',
  colorful = false,
  summaryRows = [],
  rowPerPageDefault = 10,
  defaultSort = undefined,
  groupBy = undefined,
  noPagination = false,
  disabledSort = true
}) {
  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <RTable
        name={name}
        columns={columns}
        data={data}
        onRowClick={onRowClick ? (row) => onRowClick(row) : null}
        colorful={colorful}
        groupByCol={groupBy}
        defaultSort={defaultSort}
        size={size}
        disabledSort={disabledSort}
        noPagination={noPagination}
        summaryRows={summaryRows} //sumarny riadok
        rowPerPageDefault={rowPerPageDefault}
      />
    </div>
  );
}

export default observer(XsTable);
