import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps } from '@mui/material';

import { ControllerRenderProps } from 'react-hook-form';

import { CSSProperties, ReactElement } from 'react';
import { toBoolean } from '../helpers/actions';

interface ICheckboxProps {
  label?: ReactElement<any, any> | string;
  labelProps?: FormControlLabelProps;
  checkboxProps?: CheckboxProps;
  controllerProps?: Omit<ControllerRenderProps, 'ref'>;
  onChange?: (checked: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  tabIndex?: number;
  className?: string;
}

export const XsCheckbox = (props: ICheckboxProps) => {
  const {
    labelProps,
    checkboxProps,
    controllerProps,
    value,
    label,
    onChange,
    disabled,
    style,
    tabIndex,
    className
  } = props;

  let isChecked = false;
  if (controllerProps) {
    isChecked = typeof controllerProps.value === 'boolean' ? controllerProps.value : toBoolean(controllerProps.value);
  }

  return (
    <FormControl style={style}>
      <FormControlLabel
        {...labelProps}
        labelPlacement='end'
        label={label}
        style={{ marginLeft: 0 }}
        control={
          <Checkbox
            {...checkboxProps}
            className={className}
            color='primary'
            tabIndex={tabIndex}
            onChange={(_, checked) => {
              controllerProps && controllerProps.onChange(checked);
              onChange && onChange(checked);
            }}
            checked={value ?? isChecked}
          />
        }
        disabled={disabled}
      />
    </FormControl>
  );
};
