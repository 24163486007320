import { ICacheCodeList } from '../interfaces/requests/cache.request.interface';

export const codeListsConfig: ICacheCodeList[] = [
  {
    request: { url: '/cl/Entity.CL.Title' },
    name: 'titles'
  },
  {
    request: { url: '/cl/Entity.CL.Gender' },
    name: 'genders'
  },
  {
    request: { url: '/cl/Entity.CL.EmployeeCategory' },
    name: 'employeeCategories'
  },
  {
    request: { url: '/cl/Entity.CL.AddressType' },
    name: 'AddressType'
  },
  {
    request: { url: '/cl/Entity.CL.Country' },
    name: 'countries'
  },
  {
    request: { url: '/cl/Entity.CL.Address.Region' },
    name: 'addressRegion'
  },
  // fixme na toto treba ACL pre budovy.
  // {
  //   request: { url: '/entity/cl/county' },
  //   name: 'addressCounty',
  //   backendName: 'Entity.CL.Address.County'
  // },
  {
    request: { url: '/cl/Entity.CL.ContactType' },
    name: 'contactTypes'
  },
  {
    request: { url: '/cl/Fin.CL.BankAccountType' },
    name: 'bankAccountType'
  },
  {
    request: { url: '/cl/Entity.CL.OrgUnitParamType' },
    name: 'orgUnitParamType'
  },
  {
    request: { url: '/cl/ISEE.CL.CVS.ReportStatus' },
    name: 'reportStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.Data.Unit' },
    name: 'unit'
  },
  {
    request: { url: 'isee/cl/energy/medium', method: 'POST' },
    name: 'medium',
    backendName: 'ISEE.CL.Energy.Medium'
  },
  {
    request: { url: '/cl/ISEE.CL.OES.ConsumerGroup' },
    name: 'consumerGroup'
  },
  {
    request: { url: '/cl/ISEE.CL.OES.ReportStatus' },
    name: 'OES.ReportStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.Distribution.HeatingMedium' },
    name: 'heatingMedium'
  },
  {
    request: { url: '/cl/ISEE.CL.Distribution.Type' },
    name: 'distributionType'
  },
  {
    request: { url: '/cl/ISEE.CL.Distribution.ReportStatus' },
    name: 'Distribution.ReportStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.Category' },
    name: 'objectCategory'
  },
  {
    request: { url: '/cl/ISEE.CL.OwnershipType' },
    name: 'ownershipType'
  },
  {
    request: { url: '/cl/Entity.CL.Address.City' },
    name: 'city'
  },
  {
    request: { url: '/cl/ISEE.CL.LegislativeDuty' },
    name: 'LegislativeDuty'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.HeatingSystem' },
    name: 'ISEE.CL.Building.HeatingSystem'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.Material' },
    name: 'Building.Material'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.ConstructionSystem' },
    name: 'ISEE.CL.Building.ConstructionSystem'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.EnergyConsumptionRegulation' },
    name: 'ISEE.CL.Building.EnergyConsumptionRegulation'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.DHWPreparationMethod' },
    name: 'ISEE.CL.Building.DHWPreparationMethod'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.CoolingMethod' },
    name: 'ISEE.CL.Building.CoolingMethod'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.EnergyEquipment' },
    name: 'ISEE.CL.Building.EnergyEquipment'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.EnergyConsumptionRegulation' },
    name: 'ISEE.CL.Building.EnergyConsumptionRegulation'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.DHWPreparationMethod' },
    name: 'ISEE.CL.Building.DHWPreparationMethod'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.CoolingMethod' },
    name: 'ISEE.CL.Building.CoolingMethod'
  },
  {
    request: { url: '/cl/ISEE.CL.Building.EnergyEquipment' },
    name: 'ISEE.CL.Building.EnergyEquipment'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.Review' },
    name: 'EnergeticObject.Review'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.SpecificRelType' },
    name: 'EnergeticObject.SpecificRelType'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticAudit.Status' },
    name: 'EnergeticAudit.Status'
  },
  {
    request: { url: '/cl/ISEE.CL.Entity.CompanySector' },
    name: 'Entity.CompanySector'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticAudit.SubmissionReason' },
    name: 'EnergeticAudit.SubmissionReason'
  },
  {
    request: { url: '/cl/ISEE.CL.Entity.SK.NACE' },
    name: 'Entity.SK.NACE'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticAudit.Provision' },
    name: 'EnergeticAudit.Provision'
  },
  {
    request: { url: '/cl/ISEE.CL.CVS.ReportStatus' },
    name: 'CVS.ReportStatus'
  },
  {
    request: { url: '/cl/Entity.CL.LegalForm' },
    name: 'LegalForm'
  },
  {
    request: { url: '/cl/Entity.CL.CompanyType' },
    name: 'CompanyType'
  },
  {
    request: { url: '/cl/ISEE.CL.SavingsProspect.Status' },
    name: 'SavingsProspect.Status'
  },
  {
    request: { url: '/cl/ISEE.CL.SavingsProspect.SubmissionReason' },
    name: 'SavingsProspect.SubmissionReason'
  },
  {
    request: { url: '/cl/ISEE.CL.SavingsProspect.Provision' },
    name: 'SavingsProspect.Provision'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.Provision' },
    name: 'EnergeticObject.Provision'
  },
  {
    request: { url: '/cl/ISEE.CL.FundingSource' },
    name: 'FundingSource'
  },
  {
    request: { url: '/cl/Entity.CL.Address.County' },
    name: 'Address.County'
  },
  {
    request: { url: '/cl/ISEE.CL.SavingsProspect.CulturalRegion' },
    name: 'SavingsProspect.CulturalRegion'
  },
  {
    request: { url: '/cl/ISEE.CL.Data.Module' },
    name: 'DataModuleAccess'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.InternalLabel' },
    name: 'EnergeticObject.InternalLabel'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.SpaceType' },
    name: 'EnergeticObject.SpaceType'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.ObtainingDataMethod' },
    name: 'EnergeticObject.ObtainingDataMethod'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.OperationReport.Status' },
    name: 'EnergeticObject.OperationReport.Status'
  },
  {
    request: { url: '/cl/SIEA.CL.Data.Status.Error' },
    name: 'Backend.Error'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.SpecificRelChangeStatus' },
    name: 'ISEE.CL.EnergeticObject.SpecificRelChangeStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.Entity.SubjectStatus' },
    name: 'ISEE.CL.Entity.SubjectStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.EnergeticObject.ProblemStatus' },
    name: 'ISEE.CL.EnergeticObject.ProblemStatus'
  },
  {
    request: { url: '/cl/ISEE.CL.RenewableEnergy.Technology' },
    name: 'ISEE.CL.RenewableEnergy.Technology'
  },
  {
    request: { url: '/cl/ISEE.CL.DeliveryPoint.Tariff' },
    name: 'ISEE.CL.DeliveryPoint.Tariff'
  }
];
