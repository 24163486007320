import { makeAutoObservable } from 'mobx';
import { OESStatus, TOESSreens } from '../../../global/types/types';

export class OesStore {
  constructor() {
    makeAutoObservable(this);
  }

  oesTabScreen: TOESSreens = 'list';
  setOESTabScreen = (screen: TOESSreens) => {
    this.oesTabScreen = screen;
  };

  oesStatus?: OESStatus;
  setOESStatus = (eaStatus: OESStatus) => {
    this.oesStatus = eaStatus;
  };
}

const singleton = new OesStore();
export default singleton;
