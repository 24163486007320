import { createTheme } from '@mui/material/styles';
import { skSK } from '@mui/material/locale';

const black = '#22232c';

export const theme = createTheme(
  {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '10px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          sizeMedium: {
            padding: '7px 15px'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            paddingRight: '0px !important',
            paddingLeft: '0px !important'
          },
          input: {
            '&.Mui-disabled': {
              '-webkit-text-fill-color': black,
              color: black
            }
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            paddingRight: '10px'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: black,
            '&.Mui-disabled': {
              color: black
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled fieldset': {
              // "&.Mui-disabled": {
              borderStyle: 'dashed'
              // }
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: black,
            '&.Mui-disabled': {
              color: black
            }
          },
          root: {
            color: black,
            '&.Mui-disabled': {
              color: black
            }
          },
          disabled: {
            color: black
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          selected: {
            '&.xs-active': {
              color: 'red'
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: '#1e4e9d',
        light: '#39b0dd',
        dark: '#003976'
      },
      error: {
        main: '#c6184e',
        light: '#fce4ec',
        dark: '#c10b25'
      },
      warning: {
        main: '#ffb900',
        light: '#fff8e1',
        dark: '#ff8f00'
      },
      success: {
        main: '#00a787',
        light: '#f1f8e9',
        dark: '#05664F'
      }
    },
    typography: {
      h3: {
        fontSize: '2.25rem',
        fontWeight: 500
      },
      h6: {
        fontSize: '1.2rem'
      }
    }
  },
  skSK
);
