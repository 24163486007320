import { DatePicker, DatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import { DATE_FORMAT } from '../config/constants';
import { useError } from '../helpers/hooks';

interface IXsDatePickerProps extends Omit<DatePickerProps<Date>, 'renderInput'> {
  name: string;
  required?: boolean;
  tabIndex?: number;
}

export const XsDatePicker = (props: IXsDatePickerProps) => {
  const { name, label, tabIndex, required = false, inputFormat = DATE_FORMAT, mask = '__.__.____' } = props;
  const { error, helperText } = useError(name);

  return (
    <DatePicker
      {...props}
      value={props.value ?? null}
      inputFormat={inputFormat}
      mask={mask}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            required={required}
            InputLabelProps={{ shrink: true }}
            size='small'
            label={label}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              tabIndex
            }}
          />
        );
      }}
    />
  );
};
