import { Box, Container, Grid, Stack, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginStore from '../../modules/common/login/LoginStore';
import { IS_BO, IS_FO } from '../config/constants';
import { default as config, default as settings } from '../config/settings';
import {
  err,
  getAuthToken,
  getAvailableModulesForCompany,
  getAvailablePersonModules,
  getCompanies,
  getPerson,
  setCompany,
  windowOpen
} from '../helpers/actions';
import { IUserCompany } from '../interfaces/userInfo';
import UIStore from '../stores/UIStore';
import { TMenuItems } from '../types/types';
import { XsButton } from './XsButton';
import { XsChangePasswordDialog } from './XsChangePasswordDialog';
import { XsChangeUserDataDialog } from './XsChangeUserDataDialog';
import { XsConfirmChangePasswordDialog } from './XsConfirmChangeUserDataDialog';
import { XsMenu } from './XsMenu';

export const XsHeader = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleFormChanges = ({ onConfirm }: { onConfirm: () => void }) => {
    const changes = UIStore.checkFormChanges();

    if (changes?.length) {
      UIStore.openConfirmationDialog('leaveConfirmation', {
        discriminator: 'formChanges',
        discriminatorAction: onConfirm
      });
    } else {
      onConfirm();
    }
  };

  const logout = () => {
    handleFormChanges({
      onConfirm: () => {
        try {
          axios.post(config.API_PUBLIC_URL + '/sec/logout').then((response) => {
            // updateByKey(USER_DATA, response);
          });

          // deleteByKey(USER_DATA);

          LoginStore.LogOut();
          history.push('/login');
        } catch (error) {
          err(error);
        }
      }
    });
  };

  const getDocumentation = () => {
    windowOpen('https://www.siea.sk/monitorovaci-system/isee-dokumentacia/', '_blank');
  };

  const menuItems: TMenuItems = IS_BO ? LoginStore.menuItemsBO : LoginStore.menuItemsFO;

  //Prejdeme všetky moduly z companies.modules a vyfiltrujeme podľa companyId
  const companiesItems =
    getCompanies() !== undefined
      ? getCompanies()!
          .filter((c: IUserCompany) => getAvailableModulesForCompany(undefined, c).length > 0)
          .map((c: IUserCompany) => ({
            item: `${c.name} (${c.identifier})`,
            onClick: () => setCompany(c)
          }))
      : [];

  //Ak máme aspoň jeden person.module tak do menu pridáme meno používateľa
  const personItem =
    getAvailablePersonModules()?.length > 0
      ? [
          {
            item: `${getPerson()?.full_name} (${t('menu.fo')})`,
            onClick: () => LoginStore.setCurrentPerson()
          }
        ]
      : [];

  //Položky výberu company + person
  const selectCompanyPersonItems = [...companiesItems, ...personItem];

  const getCompanySelectLabel = () => {
    //Company selected -> returns IČO
    if (LoginStore.currentCompany) {
      return t('menu.ico_company', { ico: LoginStore.currentCompany?.identifier });
    }

    //No companies on person
    if (selectCompanyPersonItems?.length === 0) {
      return 'menu.fo';
    }

    //Person selected
    if (LoginStore.isPersonSelected) {
      return `${getPerson()?.full_name} (${t('menu.fo')})`;
    }

    return 'global.unknown_ico';
  };

  return (
    <div className={classnames('main_header', { bgBlueDark: IS_FO, bgGreenDark: IS_BO })}>
      <Container maxWidth={false} className='height_inherit'>
        <Grid container alignItems='center' className='height_inherit'>
          <Grid item container style={{ width: 200 }}>
            <Grid item>
              <Box
                mr={6}
                onClick={() => {
                  handleFormChanges({
                    onConfirm: () => {
                      UIStore.setMainMenu('welcome');
                      history.push('/welcome');
                    }
                  });
                }}
                className='pointer relative'
              >
                <img alt='ISEE' src='./images/ISEE_logo_big.png' style={{ width: 80, marginTop: 5 }} />
                {(settings.SERVERROLE === 'DEV' || settings.SERVERROLE === 'TEST') && (
                  <div
                    className='absolute bgRedDanger white bold px-1'
                    style={{ top: 0, right: -25, borderRadius: 5, fontSize: 11 }}
                  >
                    {settings.SERVERROLE === 'TEST' ? 'TEST' : 'DEV'}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs alignItems='center' justifyContent='space-between'>
            <Grid item xs>
              <Grid container alignItems='center'>
                {menuItems.map((menuItem, idx) => {
                  return (
                    UIStore.checkUserAccess(menuItem.module) && (
                      <Grid item key={idx}>
                        <XsButton
                          startIcon={menuItem.icon ? <i className={menuItem.icon + ' fa-lg'}></i> : null}
                          className={UIStore.main_menu_selected === menuItem.name ? 'white' : 'menuOpacity'}
                          label={menuItem.label}
                          onClick={() => {
                            handleFormChanges({
                              onConfirm: () => {
                                menuItem.action();
                                history.push(menuItem.push);
                              }
                            });
                          }}
                        />
                      </Grid>
                    )
                  );
                })}
                {settings.DEBUG && (
                  <>
                    <Grid item>
                      <XsButton
                        startIcon={<i className='fal fa-route fa-lg'></i>}
                        className={UIStore.main_menu_selected === 'routes' ? 'white' : 'menuOpacity'}
                        label='menu.routes'
                        onClick={() => {
                          UIStore.setMainMenu('routes');
                          history.push('/routes');
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <XsButton
                        startIcon={<i className='fal fa-route fa-lg'></i>}
                        className={'menuOpacity'}
                        label='menu.routes_v2'
                        onClick={() => {
                          fetch(`${settings.API_BASE_URL}/discovery/openapi-visual`, {
                            method: 'GET',
                            headers: new Headers({ Authorization: getAuthToken() })
                          })
                            .then((response) => response.blob())
                            .then((blob) => windowOpen(window.URL.createObjectURL(blob), '_blank')?.focus());
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {IS_FO && (
              <Grid item>
                <Grid container>
                  {LoginStore.currentCompany ? (
                    <Tooltip
                      open={openTooltip}
                      placement='bottom'
                      disableInteractive
                      onMouseEnter={() => setOpenTooltip(true)}
                      onMouseLeave={() => setOpenTooltip(false)}
                      title={
                        <Stack>
                          <Typography variant='body2' fontWeight={400}>
                            {`${t('global.subject')}: `}
                            {LoginStore.currentCompany?.name ?? ''}
                          </Typography>
                          <Stack direction='row'>
                            <Typography variant='body2' fontWeight={400}>
                              {`${t('global.ico')}: `}
                              <span style={{ fontWeight: '600' }}>{LoginStore.currentCompany?.identifier ?? ''}</span>
                            </Typography>
                          </Stack>
                        </Stack>
                      }
                    >
                      <Grid item>
                        <XsMenu
                          disabled={UIStore.main_menu_selected !== 'welcome' || selectCompanyPersonItems?.length === 0}
                          button={getCompanySelectLabel()}
                          onClick={() => setOpenTooltip(false)}
                          items={selectCompanyPersonItems}
                        />
                      </Grid>
                    </Tooltip>
                  ) : (
                    <XsMenu
                      disabled={UIStore.main_menu_selected !== 'welcome' || selectCompanyPersonItems?.length === 0}
                      button={getCompanySelectLabel()}
                      onClick={() => setOpenTooltip(false)}
                      items={selectCompanyPersonItems}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Grid container>
                <Grid item>
                  <XsMenu
                    button={LoginStore.userInfo?.person?.full_name ?? 'global.unknown_user'}
                    items={[
                      {
                        item: 'menu.documentation',
                        onClick: () => getDocumentation(),
                        isHidden: IS_BO
                      },
                      { item: 'menu.mySettings', onClick: () => UIStore.openChangeUserDataDialog() },
                      { item: 'menu.changePassword', onClick: () => UIStore.openChangePasswordDialog() },
                      { item: 'menu.logout', onClick: () => logout() }
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {UIStore.isOpenChangePasswordDialog && <XsChangePasswordDialog />}
      {UIStore.isOpenChangeUserDataDialog && <XsChangeUserDataDialog />}
      {UIStore.isOpenConfirmChangeUserDataDialog && <XsConfirmChangePasswordDialog />}
    </div>
  );
});
