import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import FormattedInputs from './examples/numberMask';
import { IS_FO } from './global/config/constants';
import { getUserModuleIds, setAxiosDefaults } from './global/helpers/actions'; //getAuthToken,
import { useServerCall } from './global/helpers/hooks';
import {
  llEABO,
  llEAFO,
  llLoginBO,
  llLoginFO,
  llRoutes,
  llWelcomeBO,
  llWelcomeFO,
  llCvsFO,
  llCvsBO,
  llAdminFO,
  llAdminBO,
  llOesFO,
  llOesBO,
  llDistBO,
  llDistFO,
  llSpFO,
  llSpBO,
  llEtlBO,
  llBuildingFO,
  llBuildingBO,
  llOzeFO,
  llOzeBO,
  llOomBO
} from './global/helpers/lazy';
import { IUserInfo } from './global/interfaces/userInfo';
import { XsHeader, XsLoading } from './global/ui';
import XsCautionModalDialog from './global/ui/XsCautionModalDialog';
import XsSnackbar from './global/ui/XsSnackbar';
import LoginStore from './modules/common/login/LoginStore';
import API from './global/config/api';
import { XsAutoLogout } from './global/ui/XsAutoLogout';

type TPrivateRoute = {
  path: string;
  moduleId: string;
  render: JSX.Element;
};

type TPublicRoute = {
  path: string;
  render: JSX.Element;
};

function App() {
  //Nastavanie default route
  setAxiosDefaults();

  const LoginModule = IS_FO ? llLoginFO() : llLoginBO();
  const WelcomeModule = IS_FO ? llWelcomeFO() : llWelcomeBO();
  const EAModule = IS_FO ? llEAFO() : llEABO();
  const RoutesModule = llRoutes();
  const CvsModule = IS_FO ? llCvsFO() : llCvsBO();
  const OesModule = IS_FO ? llOesFO() : llOesBO();
  const DistModule = IS_FO ? llDistFO() : llDistBO();
  const SpModule = IS_FO ? llSpFO() : llSpBO();
  const AdminModule = IS_FO ? llAdminFO() : llAdminBO();
  const BuildingModule = IS_FO ? llBuildingFO() : llBuildingBO();
  const OzeModule = IS_FO ? llOzeFO() : llOzeBO();
  const EtlModule = llEtlBO();
  const OomModule = llOomBO();

  const publicRoutes: TPublicRoute[] = [
    {
      path: '/',
      render: (
        <>
          {/* <BaseHeader /> */}
          <main className='main_container base_container pt-8'>
            {/* <LoginContainer /> */}
            <LoginModule component='LoginContainer' />
          </main>
        </>
      )
    },
    {
      path: '/login',
      render: (
        <>
          {/* <BaseHeader /> */}
          <main className='main_container base_container pt-8'>
            {/* <LoginContainer /> */}
            <LoginModule component='LoginContainer' />
          </main>
        </>
      )
    },
    {
      path: '/resetHesla',
      render: (
        <>
          {/* <BaseHeader /> */}
          <main className='main_container base_container pt-8'>
            {/* <ForgottenPasswordContainer /> */}
            <LoginModule component='ForgottenPasswordContainer' />
          </main>
        </>
      )
    },
    {
      path: '/passwordResetFinish',
      render: (
        <>
          {/* <BaseHeader /> */}
          <main className='main_container base_container pt-8'>
            {/* <ForgottenPasswordFinishForm /> */}
            <LoginModule component='ForgottenPasswordFinishForm' />
          </main>
        </>
      )
    },
    {
      path: '/registracia',
      render: (
        <>
          {/* <BaseHeader /> */}
          <main className='main_container base_container'>
            {/* <Register /> */}
            <LoginModule component='Register' />
          </main>
        </>
      )
    },
    {
      path: '/registerFinish',
      render: (
        <>
          <main className='main_container base_container'>
            {/* <RegisterFinish /> */}
            <LoginModule component='RegisterFinish' />
          </main>
        </>
      )
    },
    {
      path: '/routes',
      render: (
        <>
          <XsHeader />
          <main className='main_container base_container'>
            {/* <RoutesContainer /> */}
            <RoutesModule component='RoutesContainer' />
          </main>
        </>
      )
    },
    {
      path: '/test',
      render: (
        <>
          <main className='main_container'>
            <FormattedInputs />
          </main>
        </>
      )
    }
  ];

  const privateRoutes: TPrivateRoute[] = [
    {
      path: '/welcome',
      moduleId: 'PUBLIC',
      render: (
        <main className='main_container bgSnowWhite'>
          <WelcomeModule component='WelcomeContainer' />
        </main>
      )
    },
    {
      path: '/cvs',
      moduleId: IS_FO ? 'CVS:FO' : 'CVS:BO',
      render: (
        <main className='main_container bgWhite'>
          <CvsModule component='CvsContainer' />
        </main>
      )
    },
    {
      path: '/cvs_report',
      moduleId: 'CVS:FO',
      render: <main className='main_container bgWhite'>CvsReportContainer</main>
    },
    {
      path: '/oes',
      moduleId: IS_FO ? 'OES:FO' : 'OES:BO',
      render: (
        <main className='main_container bgWhite'>
          <OesModule component='OesContainer' />
        </main>
      )
    },
    {
      path: '/oes_report',
      moduleId: 'OES:FO',
      render: <main className='main_container bgWhite'>OesReportContainer</main>
    },
    {
      path: '/rozvody',
      moduleId: IS_FO ? 'DIST:FO' : 'DIST:BO',
      render: (
        <main className='main_container bgWhite'>
          <DistModule component='DistContainer' />
        </main>
      )
    },
    {
      path: '/rozvody_report',
      moduleId: 'DIST:FO',
      render: <main className='main_container bgWhite'>RozvodyReportContainer</main>
    },
    {
      path: '/admin',
      moduleId: IS_FO ? 'ADM:FO' : 'ADM:BO',
      render: (
        <main className='main_container bgWhite'>
          <AdminModule component='AdminContainer' />
        </main>
      )
    },
    {
      path: '/ea',
      moduleId: IS_FO ? 'EA:FO' : 'EA:BO',
      render: <EAModule component='EnergeticAuditContainer' />
    },
    {
      path: '/building',
      moduleId: IS_FO ? 'BDG:FO' : 'BDG:BO',
      render: (
        <main className='main_container bgWhite'>
          <BuildingModule component='BuildingContainer' />
        </main>
      )
    },
    {
      path: '/oze',
      moduleId: IS_FO ? 'OZE:FO' : 'OZE:BO',
      render: (
        <main className='main_container bgWhite'>
          <OzeModule component='OzeContainer' />
        </main>
      )
    },
    {
      path: '/pu',
      moduleId: IS_FO ? 'PU:FO' : 'PU:BO',
      render: (
        <main className='main_container bgWhite'>
          <SpModule component='SavingsPotentialContainer' />
        </main>
      )
    },
    {
      path: '/etl',
      moduleId: 'ETL:BO',
      render: (
        <main className='main_container bgWhite'>
          <EtlModule component='EtlContainer' />
        </main>
      )
    },
    {
      path: '/oom',
      moduleId: 'OOM:BO',
      render: (
        <main className='main_container bgWhite'>
          <OomModule component='OomContainer' />
        </main>
      )
    }
  ];

  return (
    <div className='App'>
      <Router>
        <Switch>
          {publicRoutes.map((route: TPublicRoute, idx: number) => {
            return <Route key={idx} exact path={route.path} render={() => route.render} />;
          })}
          {privateRoutes.map((route: TPrivateRoute, idx: number) => {
            return (
              <PrivateRoute key={idx} exact path={route.path} moduleId={route.moduleId}>
                {route.render}
              </PrivateRoute>
            );
          })}
          <PrivateRoute path='*' />
        </Switch>
      </Router>
      <XsSnackbar />
      <XsCautionModalDialog />
      <XsAutoLogout />
    </div>
  );
}

export default App;

interface IAcl {
  isAuthenticated?: boolean;
  isAuthorized: boolean;
}

function PrivateRoute({ children, ...rest }: any) {
  const { moduleId } = rest;
  const { t } = useTranslation();

  const LoginModule = IS_FO ? llLoginFO() : llLoginBO();

  const [acl, setAcl] = useState<IAcl>({ isAuthorized: false, isAuthenticated: undefined });

  const userInfo = useServerCall<any, IUserInfo>({
    key: ['userInfo'],
    request: {
      method: 'GET',
      url: API.Self()
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 0 },
    onSuccess: (res) => {
      if (res?.data?.user?.user_id) {
        LoginStore.setUserInfo(res.data);

        setAcl({
          isAuthenticated: true,
          isAuthorized: moduleId === 'PUBLIC' ? true : getUserModuleIds().includes(moduleId)
        });
        LoginStore.LogIn();
      }
    },
    onError: () => {
      setAcl({ isAuthenticated: false, isAuthorized: false });
    }
  });

  const userInfoRefresh = useServerCall<any, IUserInfo>({
    key: ['userInfoRefresh'],
    mode: 'control',
    request: {
      method: 'GET',
      url: API.Self()
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 0 },
    onSuccess: (res) => {
      if (res?.data?.user?.user_id) {
        LoginStore.setUserInfo(res.data);
      }
    }
  });

  LoginStore.refreshUser = userInfoRefresh.refetch;

  if (userInfo.isPending) {
    //&& !getAuthToken()
    return <XsLoading overlay={true} message={t('global.checking_auth')} />;
  }

  return (
    <>
      {acl.isAuthenticated !== undefined && (
        <Route
          {...rest}
          render={({ location }) =>
            acl.isAuthenticated && acl.isAuthorized ? (
              <>
                <XsHeader />
                <main className='main_container bgWhite'>{children}</main>
                <LoginModule component='ReLoginDialog' />
              </>
            ) : (
              <Redirect
                to={{
                  pathname: acl.isAuthenticated ? '/welcome' : '/login',
                  state: { from: location }
                }}
              />
            )
          }
        />
      )}
    </>
  );
}
