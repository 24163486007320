import { FormHelperText, Grid, GridProps, GridSize } from '@mui/material';
import React, { useState } from 'react';
import { FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';
import { IInlineFormData, XsButton } from '.';
import { useError } from '../helpers/hooks';
import UIStore from '../stores/UIStore';
import XsConfirmationDialog from './confirmationDialog/XsConfirmationDialog';

export interface IChildFormProps {
  disabled?: boolean;
  name?: string;
  onFormChange?: () => void;
  onFormCreated?: (formData: IInlineFormData, index: number) => void;
  parentForm?: UseFormReturn<FieldValues, object>;
}

interface IInlineFormProps<TFieldValues extends FieldValues = FieldValues, TContext extends object = object> {
  form: React.ReactElement;
  parentForm?: UseFormReturn<TFieldValues, TContext>;
  addNewLabel?: string;
  name: string;
  validateOnNewForm?: boolean;
  validateOnChange?: boolean;
  max?: number;
  min?: number;
  onChange: (data: any[]) => void;
  onBlur: (data: any[]) => void;
  removeMessage?: { info: string; question: string } | undefined;
  errors?: any;
  disabled?: boolean;
  value?: any;
  newButtonSize?: GridSize;
  styleOverrides?: {
    mainContainerProps?: GridProps;
    formContainerProps?: GridProps;
    buttonsContainerProps?: GridProps;
  };
}

export const XsInlineForm = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>({
  form: formElement,
  max,
  min = 1,
  addNewLabel = 'global.add.generic',
  validateOnNewForm = false,
  validateOnChange = false,
  errors,
  disabled = false,
  removeMessage = undefined,
  onChange,
  onBlur,
  name,
  value,
  styleOverrides,
  newButtonSize,
  ...rest
}: IInlineFormProps<TFieldValues, TContext>) => {
  const [forms, setForms] = useState<IInlineFormData[]>([]);
  const parentForm = useFormContext();
  const { error, helperText } = useError(name);

  if (min > 0 && forms.length === 0) {
    setForms([{ id: `${name}_0` }]);
  }

  const onFormChange = (inlineFormData: IInlineFormData) => {
    setForms((prevForms) => {
      const newForms = prevForms.map((form) => (form.id === inlineFormData.id ? inlineFormData : form));
      const formsValues = [...newForms].map((formData: IInlineFormData) => formData?.form?.getValues());
      parentForm.setValue(name, formsValues, { shouldValidate: validateOnChange });
      return newForms;
    });
  };

  const onFormCreated = ({ form }: IInlineFormData, index: number) => {
    const formData: IInlineFormData = forms[index];
    formData.form = form;
    formData.id = `${name}_${index}`;
    if (formData.defaultValues) {
      for (const [key, value] of Object.entries(formData.defaultValues)) {
        form.setValue(key, value);
      }
    } else {
      onChange([...forms].map((formData: IInlineFormData) => formData?.form?.getValues()));
    }

    setForms([...forms]);
  };

  const setFormData = () => {
    setForms([...forms]);
    onChange([...forms].map((formData: IInlineFormData) => formData?.form?.getValues()));
  };

  //Load data values
  if (value && Array.isArray(value)) {
    value.map((inlineFormValue: any, idx: number) => {
      //Form exists
      if (forms[idx]) {
        if (forms[idx].form && inlineFormValue !== undefined) {
          forms[idx].form.clearErrors();
          for (const [key, value] of Object.entries(inlineFormValue)) {
            forms[idx].form.setValue(key, value);
          }
        }
      } else {
        //Form is missing, create new one
        forms.push({ id: `${name}_${idx}`, defaultValues: inlineFormValue });
        setForms([...forms]);
      }
      return true;
    });
  }

  //Handle errors from parent
  if (errors) {
    const innerFormsErrors: [] = errors[name];

    if (innerFormsErrors) {
      forms.map((form, idx) => {
        if (innerFormsErrors[idx]) {
          form.form?.clearErrors();
          form.form?.trigger();
          // for (const [key, value] of Object.entries(innerFormsErrors[idx])) {
          //form.form?.setError(key, value);
          // }
        } else {
          form.form?.clearErrors();
        }
        return true;
      });
    }
  }

  const onRemoveClick = (idx: number) => {
    forms.splice(idx, 1);
    setFormData();
  };

  const renderButtons = (idx: number) => {
    return (
      <Grid container item xs={12} md={12} columnSpacing={2}>
        {min - 1 !== idx && (
          <Grid item xs={6}>
            <XsButton
              startIcon={<i className='fal fa-times fa-lg'></i>}
              color='error'
              variant='contained'
              label={'global.remove'}
              disabled={disabled}
              onClick={() => {
                if (removeMessage) {
                  UIStore.openConfirmationDialog('removeWarning', { idx });
                } else {
                  onRemoveClick(idx);
                }
              }}
            />
          </Grid>
        )}
        {forms?.length !== max && idx === forms.length - 1 && (
          <Grid item xs={6}>
            <XsButton
              startIcon={<i className='fal fa-plus fa-lg'></i>}
              variant='contained'
              label={addNewLabel}
              disabled={disabled}
              onClick={async () => {
                const isValid = await forms[idx]?.form?.trigger();
                if (isValid) {
                  forms.push({ id: `${name}_${idx + 1}` });
                  setFormData();
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderForms = () => {
    return forms?.length === 0 ? (
      <Grid item xs={newButtonSize ? newButtonSize : 2}>
        <XsButton
          startIcon={<i className='fal fa-plus fa-lg'></i>}
          variant='contained'
          label={addNewLabel}
          disabled={disabled}
          onClick={async () => {
            forms.push({ id: `${name}_0` });
            setFormData();
          }}
        />
      </Grid>
    ) : (
      forms?.map((f, idx) => (
        <Grid container item xs={12} md={12} spacing={2} key={f.id}>
          <Grid item xl={9} xs={8} {...styleOverrides?.formContainerProps}>
            {React.cloneElement(formElement, {
              disabled,
              parentForm,
              name,
              id: f.id,
              onFormChange,
              onFormCreated: (formData: IInlineFormData) => onFormCreated(formData, idx)
            })}
          </Grid>
          <Grid item xl={3} xs={4} {...styleOverrides?.buttonsContainerProps}>
            {renderButtons(idx)}
          </Grid>
        </Grid>
      ))
    );
  };

  return (
    <>
      <Grid container spacing={5} {...rest} {...styleOverrides?.mainContainerProps}>
        {renderForms()}
      </Grid>
      {error && (
        <Grid container item>
          {helperText.map((helperTextPart: string, idx: number) => (
            <Grid item xs={12} key={idx}>
              <FormHelperText error={true}>{helperTextPart}</FormHelperText>
            </Grid>
          ))}
        </Grid>
      )}
      <XsConfirmationDialog
        onConfirmation={() => onRemoveClick(UIStore.confParams.idx)}
        title={'energeticAudit.audit_final_energy_consumption.form.remove_warning_title'}
        titleIcon={<i className='fas fa-exclamation-triangle fa-2x'></i>}
        content={
          <Grid container className='greyDefault' direction='column' spacing={2}>
            <Grid item>{removeMessage!.info}</Grid>
            {removeMessage!.question && (
              <Grid item fontWeight='500'>
                {removeMessage!.question}
              </Grid>
            )}
          </Grid>
        }
        name={'removeWarning'}
        type={'warning'}
      />
    </>
  );
};
