export type TStorageValue = string | object;

const toStr = (value: TStorageValue) => (typeof value === 'object' ? JSON.stringify(value) : String(value));

export const getByKey = <TKey extends TStorageValue>(key: TKey): TStorageValue | null => {
  let parsedValue = window.localStorage.getItem(toStr(key));
  if (parsedValue)
    try {
      parsedValue = JSON.parse(parsedValue);
    } catch {}
  return parsedValue;
};

export const updateByKey = <TKey extends TStorageValue, TVal extends TStorageValue>(key: TKey, value: TVal) => {
  window.localStorage.setItem(toStr(key), toStr(value));
};

export const deleteByKey = <TKey extends TStorageValue>(key: TKey) => {
  window.localStorage.removeItem(toStr(key));
};
