import { makeObservable } from 'mobx';

type TSort = {
  id: string;
  desc?: boolean;
};

interface ITableState {
  page: number;
  pageSize: number;
  selectedRow?: string;
  sort: TSort[];
  filters?: any;
}

interface ITableStates {
  [key: string]: ITableState;
}

class TableStore {
  tables: ITableStates = {};

  defaultPageSize: number = 10;

  //ulozenie strany
  saveTablePageByName(name: string, page: number) {
    if (name in this.tables) {
      this.tables[name].page = page;
    } else {
      const tableState: ITableState = { page: page, pageSize: this.defaultPageSize, selectedRow: undefined, sort: [] };
      this.tables[name] = tableState;
    }
  }

  //ulozenie poctu zaznamov na stranu
  saveTablePageSizeByName(name: string, pageSize: number) {
    if (name in this.tables) {
      this.tables[name].pageSize = pageSize;
    } else {
      const tableState: ITableState = { page: 0, pageSize: pageSize, selectedRow: undefined, sort: [] };
      this.tables[name] = tableState;
    }
  }

  //ulozenie posledne zvoleneho riadku
  saveSelectedRowByName(name: string, row: string) {
    if (name in this.tables) {
      this.tables[name].selectedRow = row;
    } else {
      const tableState: ITableState = { page: 0, pageSize: this.defaultPageSize, selectedRow: row, sort: [] };
      this.tables[name] = tableState;
    }
  }

  //ulozenie triedenia
  saveTableSortByName(name: string, sortId: string, sortDesc?: boolean) {
    if (name in this.tables) {
      this.tables[name].sort = [{ id: sortId, desc: sortDesc }];
    } else {
      const tableState: ITableState = { page: 0, pageSize: this.defaultPageSize, selectedRow: undefined, sort: [] };
      this.tables[name] = tableState;
    }
  }

  //odstranenie triedenia
  clearTableSort(name: string, sortId: string) {
    if (
      name in this.tables &&
      this.tables[name].sort &&
      this.tables[name].sort.length > 0 &&
      this.tables[name].sort[0].id === sortId
    ) {
      this.tables[name].sort = [];
    }
  }

  //ulozenie filtrov
  saveTableFiltersByName(name: string, filterData: any) {
    if (name in this.tables) {
      this.tables[name].filters = filterData;
      this.tables[name].page = 0;
    } else {
      const tableState: ITableState = {
        page: 0,
        pageSize: this.defaultPageSize,
        selectedRow: undefined,
        sort: [],
        filters: filterData
      };
      this.tables[name] = tableState;
    }
  }

  //odstranenie filtrov
  clearTableFilters(name: string) {
    if (name in this.tables) this.tables[name].filters = undefined;
  }

  //ziskanie filtrov
  getTableInitParams(name: string) {
    if (name in this.tables && this.tables[name].filters) {
      return this.tables[name].filters;
    }

    return {};
  }

  constructor() {
    makeObservable(this, {});
  }
}

const singleton = new TableStore();
export default singleton;
