import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

export interface IIconButtonProps extends IconButtonProps {
  icon: JSX.Element;
  isVisible?: boolean;
  tooltip?: NonNullable<ReactNode>;
}

export const XsIconButton = (props: IIconButtonProps) => {
  const { isVisible = true, icon, tooltip = '', ...rest } = props;
  return (
    <>
      {isVisible ? (
        <Tooltip title={tooltip} placement='top'>
          <span>
            <IconButton {...rest}>{icon}</IconButton>
          </span>
        </Tooltip>
      ) : null}
    </>
  );
};
