import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IButtonProps extends ButtonProps {
  label: string;
  isVisible?: boolean;
}

export const XsButton = (props: IButtonProps) => {
  const { label, isVisible = true, ...rest } = props;
  const { t } = useTranslation();
  return (
    <>
      {isVisible ? (
        <Button fullWidth {...rest}>
          {t(label)}
        </Button>
      ) : null}
    </>
  );
};
