import { action, makeObservable, observable } from 'mobx';
import { VariantType } from 'notistack';
import AdminStore from '../../modules/common/admin/AdminStore';
import CvsStore from '../../modules/common/cvs/CvsStore';
import DistStore from '../../modules/common/distributions/DistStore';
import EnergeticAuditStore from '../../modules/common/energeticAudit/EnergeticAuditStore';
import OesStore from '../../modules/common/oes/OesStore';
import SavingsPotentialStore from '../../modules/common/pu/SavingsPotentialStore';
import { getUserModuleIds, logger } from '../helpers/actions';
import { IFormFieldChange } from '../helpers/hooks';
import { IBreadcrumb } from '../interfaces/common';
import { IUserFormFO } from '../interfaces/forms/admin/adminUser.form.interface';
import {
  TBuildingTab,
  TCvsContainerTabs,
  TDistContainerTabs,
  TEaContainerTabs,
  TEtlContainerTabs,
  TMenuItems,
  TOesContainerTabs,
  TOomTab,
  TOzeTabs,
  TPuMainTabs
} from '../types/types';
import { ISnackbarProps } from '../ui/XsSnackbar';

type TMainMenu =
  | 'welcome'
  | 'admin'
  | 'ea'
  | 'cvs'
  | 'oes'
  | 'rmee'
  | 'etl'
  | 'dist'
  | 'budovy'
  | 'routes'
  | 'sp'
  | 'oze'
  | 'oom';

type TCautionVariant = 'error' | 'warning' | null;

interface ICautionObj {
  message: string;
  variant: TCautionVariant;
  action?: Function;
}

interface ICheckingForm {
  getChanges: () => IFormFieldChange[];
  inDialog: boolean;
}

class UIStore {
  main_menu_selected: TMainMenu = 'welcome';

  energetic_object_tab: string = 'building';
  ea_tab: TEaContainerTabs = 'audit';
  pu_tab: TPuMainTabs = 'main';
  cvs_tab: TCvsContainerTabs = 'reports';
  oes_tab: TOesContainerTabs = 'reports';
  dist_tab: TDistContainerTabs = 'reports';
  etl_tab: TEtlContainerTabs = 'status';
  building_tab: TBuildingTab = 'buildingList';
  oze_tab: TOzeTabs = 'ozeObjects';
  oom_tab: TOomTab = 'oomList';

  notifications: ISnackbarProps[] = [];
  errorNotifications: number[] = [];
  cautionObj: ICautionObj | null = null;

  confirmationDialogOpened: string | undefined = undefined;
  confParams: { [name: string]: any } = {};
  formDialogParams: { [name: string]: any } = {};

  formDialogOpened: string | undefined = undefined;

  breadcrumbs: IBreadcrumb[] = [];

  isLoading: boolean = false;

  isOpenChangePasswordDialog = false;
  isOpenChangeUserDataDialog = false;
  isOpenConfirmChangeUserDataDialog = false;
  tmpChangedUserData: IUserFormFO | undefined = undefined;

  isMaintenance: boolean = false;

  //** FORM CHANGES */
  private checkingForms: Map<string, ICheckingForm> = new Map();

  addCheckingForm = (formId: string, checkingForm: ICheckingForm) => {
    this.checkingForms.set(formId, checkingForm);
  };

  removeCheckingForm = (formId: string) => {
    this.checkingForms.delete(formId);
  };

  checkFormChanges: (formId?: string) => IFormFieldChange[] = (formId?: string) => {
    const checkingFormsArr = Array.from(this.checkingForms.values());

    // when some form is in modal, check only form on the top
    const isSomeFormInDialog = checkingFormsArr.some((form) => form.inDialog);
    if (isSomeFormInDialog) {
      return checkingFormsArr.slice(-1)[0]?.getChanges() ?? [];
    }

    // check specific form
    if (formId) {
      const checkingForm = this.checkingForms.get(formId);
      return checkingForm?.getChanges() ?? [];
    }

    // check all forms
    let changes: IFormFieldChange[] = [];
    this.checkingForms.forEach((form) => {
      changes = changes.concat(form.getChanges());
    });

    return changes;
  };

  setDistTab = (value: TDistContainerTabs) => (this.dist_tab = value);
  setBuildingTab = (value: TBuildingTab) => {
    this.building_tab = value;
  };

  setOzeTab = (value: TOzeTabs) => {
    this.oze_tab = value;
  };

  setOomTab = (value: TOomTab) => {
    this.oom_tab = value;
  };

  //** FULLSCREEN BLOCK UI LOADING */
  loading(nowLoading: boolean) {
    this.isLoading = nowLoading;
  }

  //** NOTIFICATION */
  setNotificationMessage(message: string, variant: VariantType = 'info', action?: Function) {
    if (!!message) {
      if (variant === 'error' || variant === 'warning') {
        this.setCautionMessage(message, variant, action);
      } else {
        this.notifications.push({ key: new Date().getTime() + Math.random(), message, variant });
      }
    }
  }

  setCautionMessage(message: string, variant: TCautionVariant, action?: Function) {
    this.cautionObj = { message, variant, action };
  }

  removeNotificationMessage(key: number) {
    this.notifications = this.notifications.filter((n: ISnackbarProps) => n.key !== key);
  }

  removeCautionMessage() {
    this.cautionObj = null;
  }

  //** CONFIRMATION DIALOG */

  openConfirmationDialog(dialogName: string, params: object = {}) {
    logger('params', params);
    logger('dialogName', dialogName);
    this.confParams = {};
    this.confParams = params;
    this.confirmationDialogOpened = dialogName;
  }

  closeConfirmationDialog() {
    this.confirmationDialogOpened = undefined;
  }

  //** FORM DIALOG */

  openFormDialog(dialogFormName: string | undefined = undefined, params: object = {}) {
    this.formDialogOpened = dialogFormName;
    this.formDialogParams = params;
  }

  closeFormDialog() {
    this.openFormDialog(undefined);
  }

  setMainMenu = (selected: TMainMenu) => {
    this.main_menu_selected = selected;
  };

  //** BREADCRUMBS */

  //prepisanie aktualneho breadcrumbs
  setBreadcrumbs = (breadcrumbs: IBreadcrumb[]) => {
    this.breadcrumbs = breadcrumbs;
  };

  //pushnutie dalsieho linku a setnutie textu...
  pushBreadcrumbs = (breadcrumb: IBreadcrumb) => {
    const currLastIdx: number = this.breadcrumbs.length - 1;

    if (currLastIdx >= 0 && this.breadcrumbs[currLastIdx].ID !== breadcrumb.ID) this.breadcrumbs.push(breadcrumb);
  };

  goToPreviousBreadcrumbsStep = (newLabelForLastStep?: JSX.Element | string) => {
    // remove last element
    this.breadcrumbs = this.breadcrumbs.slice(0, -1);

    const lastIdx: number = this.breadcrumbs.length - 1;
    if (newLabelForLastStep) {
      this.breadcrumbs[lastIdx].label = newLabelForLastStep;
    }

    this.breadcrumbs[lastIdx].action();
  };

  changeLastBreadcrumbsStep = (newLabel: JSX.Element | string, newAction?: () => void) => {
    const lastIdx: number = this.breadcrumbs.length - 1;

    this.breadcrumbs[lastIdx].label = newLabel;

    if (newAction) {
      this.breadcrumbs[lastIdx].action = newAction;
    }
  };

  //breadcrumbs step 1, 2, 3, 4...
  goToBreadcrumbsStep = (step: number) => {
    //ziskanie posledneho stepu
    const lastStep: IBreadcrumb = this.breadcrumbs[step - 1];

    //odpalis action v linku na ktory sa klikol
    lastStep.action();

    //slice links (odstranenie stepov) a setnutie links, text
    this.setBreadcrumbs(this.breadcrumbs.slice(0, step - 1));
  };

  checkUserAccess = (moduleName: string) => {
    return getUserModuleIds().includes(moduleName);
  };

  openChangePasswordDialog = () => {
    this.isOpenChangePasswordDialog = true;
  };

  closeChangePasswordDialog = () => {
    this.isOpenChangePasswordDialog = false;
  };

  openChangeUserDataDialog = () => {
    this.isOpenChangeUserDataDialog = true;
  };

  closeChangeUserDataDialog = () => {
    this.isOpenChangeUserDataDialog = false;
  };

  openConfirmChangeUserDataDialog = (changedUserDataForm: IUserFormFO) => {
    this.tmpChangedUserData = changedUserDataForm;
    this.isOpenConfirmChangeUserDataDialog = true;
  };

  closeConfirmChangeUserDataDialog = () => {
    this.isOpenConfirmChangeUserDataDialog = false;
  };

  setMaintenance = (value: boolean) => {
    this.isMaintenance = value;
  };

  menuItems: TMenuItems = [
    {
      name: 'cvs',
      module: 'CVS:FO',
      label: 'menu.cvs',
      description: 'menu.cvs_desc',
      action: () => {
        this.setMainMenu('cvs');
        CvsStore.setCVSTabScreen('list');
      },
      push: '/cvs',
      icon: 'fal fa-city'
    },
    {
      name: 'cvs',
      module: 'CVS:BO',
      label: 'menu.cvs',
      description: 'menu.cvs_desc',
      action: () => {
        this.setMainMenu('cvs');
        CvsStore.setCVSTabScreen('list');
      },
      push: '/cvs',
      icon: 'fal fa-city'
    },
    {
      name: 'oes',
      module: 'OES:FO',
      label: 'menu.oes',
      description: 'menu.oes_desc',
      action: () => {
        this.setMainMenu('oes');
        OesStore.setOESTabScreen('list');
      },
      push: '/oes',
      icon: 'fal fa-analytics'
    },
    {
      name: 'oes',
      module: 'OES:BO',
      label: 'menu.oes',
      description: 'menu.oes_desc',
      action: () => {
        this.setMainMenu('oes');
        OesStore.setOESTabScreen('list');
      },
      push: '/oes',
      icon: 'fal fa-analytics'
    },
    {
      name: 'dist',
      module: 'DIST:FO',
      label: 'menu.rozvody',
      description: 'menu.rozvody_desc',
      action: () => {
        this.setMainMenu('dist');
        DistStore.setDistReportScreen('list');
      },
      push: '/rozvody',
      icon: 'fal fa-industry'
    },
    {
      name: 'dist',
      module: 'DIST:BO',
      label: 'menu.rozvody',
      description: 'menu.rozvody_desc',
      action: () => {
        this.setMainMenu('dist');
        DistStore.setDistReportScreen('list');
      },
      push: '/rozvody',
      icon: 'fal fa-industry'
    },
    {
      name: 'budovy',
      module: 'BDG:FO',
      label: 'menu.budovy',
      description: 'menu.budovy_desc',
      action: () => this.setMainMenu('budovy'),
      push: '/building',
      icon: 'fal fa-building'
    },
    {
      name: 'budovy',
      module: 'BDG:BO',
      label: 'menu.budovy',
      description: 'menu.budovy_desc',
      action: () => this.setMainMenu('budovy'),
      push: '/building',
      icon: 'fal fa-building'
    },
    {
      name: 'admin',
      module: 'ADM:FO',
      label: 'menu.admin',
      description: 'menu.admin_desc',
      action: () => AdminStore.resetAdminModul(),
      push: '/admin',
      icon: 'fal fa-hammer'
    },
    {
      name: 'admin',
      module: 'ADM:BO',
      label: 'menu.admin',
      description: 'menu.admin_desc',
      action: () => AdminStore.resetAdminModul(),
      push: '/admin',
      icon: 'fal fa-hammer'
    },
    {
      name: 'ea',
      module: 'EA:FO',
      label: 'menu.ea',
      description: 'menu.ea_desc',
      action: () => {
        this.setMainMenu('ea');
        EnergeticAuditStore.setEaTabScreen('EAList');
      },
      push: '/ea',
      icon: 'fal fa-analytics fa-lg'
    },
    {
      name: 'ea',
      module: 'EA:BO',
      label: 'menu.ea',
      description: 'menu.ea_desc',
      action: () => {
        this.setMainMenu('ea');
        EnergeticAuditStore.setEaTabScreen('EAList');
      },
      push: '/ea',
      icon: 'fal fa-analytics fa-lg'
    },
    {
      name: 'sp',
      module: 'PU:FO',
      label: 'menu.pu',
      description: 'menu.pu_desc',
      action: () => {
        this.setMainMenu('sp');
        SavingsPotentialStore.setSPTabScreen('SPList');
      },
      push: '/pu',
      icon: 'fal fa-file-alt'
    },
    {
      name: 'sp',
      module: 'PU:BO',
      label: 'menu.pu',
      description: 'menu.pu_desc',
      action: () => {
        this.setMainMenu('sp');
        SavingsPotentialStore.setSPTabScreen('SPList');
      },
      push: '/pu',
      icon: 'fal fa-file-alt'
    },
    {
      name: 'etl',
      module: 'ETL:BO',
      label: 'menu.etl',
      description: 'menu.etl',
      action: () => {
        this.setMainMenu('etl');
      },
      push: '/etl',
      icon: 'fal fa-file-signature'
    },
    {
      name: 'oom',
      module: 'OOM:BO',
      label: 'menu.oom',
      description: 'menu.oom',
      action: () => {
        this.setMainMenu('oom');
      },
      push: '/oom',
      icon: 'fal fa-file-signature'
    },
    {
      name: 'oze',
      module: 'OZE:FO',
      label: 'menu.oze',
      description: 'menu.oze_desc',
      action: () => this.setMainMenu('oze'),
      push: '/oze',
      icon: 'fal fa-building'
    },
    {
      name: 'oze',
      module: 'OZE:BO',
      label: 'menu.oze',
      description: 'menu.oze_desc',
      action: () => this.setMainMenu('oze'),
      push: '/oze',
      icon: 'fal fa-building'
    }
  ];

  constructor() {
    makeObservable(this, {
      main_menu_selected: observable,
      setMainMenu: action,
      energetic_object_tab: observable,
      ea_tab: observable,
      cvs_tab: observable,
      oes_tab: observable,
      etl_tab: observable,
      dist_tab: observable,
      setDistTab: action,
      building_tab: observable,
      setBuildingTab: action,
      notifications: observable,
      setNotificationMessage: action,
      removeNotificationMessage: action,
      cautionObj: observable,
      setCautionMessage: action,
      removeCautionMessage: action,
      confirmationDialogOpened: observable,
      openConfirmationDialog: action,
      closeConfirmationDialog: action,
      formDialogOpened: observable,
      isLoading: observable,
      loading: action,
      openFormDialog: action,
      closeFormDialog: action,
      breadcrumbs: observable,
      setBreadcrumbs: action,
      pushBreadcrumbs: action,
      goToBreadcrumbsStep: action,
      changeLastBreadcrumbsStep: action,
      goToPreviousBreadcrumbsStep: action,
      checkUserAccess: action,
      addCheckingForm: action,
      removeCheckingForm: action,
      pu_tab: observable,
      isOpenChangePasswordDialog: observable,
      openChangePasswordDialog: action,
      closeChangePasswordDialog: action,
      isOpenChangeUserDataDialog: observable,
      openChangeUserDataDialog: action,
      closeChangeUserDataDialog: action,
      isOpenConfirmChangeUserDataDialog: observable,
      openConfirmChangeUserDataDialog: action,
      closeConfirmChangeUserDataDialog: action,
      isMaintenance: observable,
      setMaintenance: action,
      oze_tab: observable,
      setOzeTab: action,
      oom_tab: observable
    });
  }
}

const singleton = new UIStore();
export default singleton;
