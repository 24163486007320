import { action, makeObservable, observable } from 'mobx';
import UIStore from '../../../global/stores/UIStore';
import {
  TAdminContainerTabs,
  TAdminECBTab,
  TAdminOKTETab,
  TAdminSPPDTab,
  TAdminSubjectDetailTab,
  TAdminSubjectScreen,
  TAdminUserDetailNewDataDefs,
  TAdminUserDetailTab,
  TAdminUserScreen
} from '../../../global/types/types';

class AdminStore {
  adminSubjectTabScreen: TAdminSubjectScreen = 'subjectList';
  admin_detail_tab: TAdminSubjectDetailTab = 'address';
  admin_tab: TAdminContainerTabs = 'subjectContainer';

  accessesData = [
    {
      _id: 'N',
      name: 'bez prístupu do modulu'
    },
    {
      _id: 'W',
      name: 'plný prístup do modulu'
    },
    {
      _id: 'R',
      name: 'modul iba na čítanie'
    }
  ];

  setAdminTab(tab: TAdminContainerTabs) {
    this.admin_tab = tab;
  }

  setAdminTabScreen(screen: TAdminSubjectScreen) {
    this.adminSubjectTabScreen = screen;
  }

  setAdminSubjectDetailTab(tab: TAdminSubjectDetailTab) {
    this.admin_detail_tab = tab;
  }

  adminUserTabScreen: TAdminUserScreen = 'userList';

  setAdminUserTabScreen(screen: TAdminUserScreen) {
    this.adminUserTabScreen = screen;
  }

  adminUserDetailTab: TAdminUserDetailTab = 'subjects';

  setAdminUserDetailTab(tab: TAdminUserDetailTab) {
    this.adminUserDetailTab = tab;
  }

  adminSPPDTab: TAdminSPPDTab = 'imports';

  setAdminSPPDTab(tab: TAdminSPPDTab) {
    this.adminSPPDTab = tab;
  }

  adminOKTETab: TAdminOKTETab = 'imports';

  setAdminOKTETab(tab: TAdminOKTETab) {
    this.adminOKTETab = tab;
  }

  isOpenNewSubjectDialog: boolean = false;

  openNewSubjectDialog() {
    this.isOpenNewSubjectDialog = true;
  }

  closeNewSubjectDialog() {
    this.isOpenNewSubjectDialog = false;
  }

  adminECBTab: TAdminECBTab = 'imports';

  setAdminECBTab(tab: TAdminECBTab) {
    this.adminECBTab = tab;
  }

  resetAdminModul = () => {
    UIStore.setMainMenu('admin');
    this.setAdminTabScreen('subjectList'); //nastvi subjekt na zoznam
    this.setAdminUserTabScreen('userList'); //nastavi user na zoznam
    this.setAdminTab('subjectContainer'); //nastavi tab na subjekt
  };

  assignUserData: TAdminUserDetailNewDataDefs = null;

  setAssignUserData = (data: TAdminUserDetailNewDataDefs) => {
    this.assignUserData = data;
  };

  constructor() {
    makeObservable(this, {
      adminSubjectTabScreen: observable,
      admin_detail_tab: observable,
      admin_tab: observable,
      setAdminTab: action,
      setAdminTabScreen: action,
      setAdminSubjectDetailTab: action,
      adminUserTabScreen: observable,
      adminUserDetailTab: observable,
      setAdminUserDetailTab: action,
      adminSPPDTab: observable,
      setAdminSPPDTab: action,
      adminOKTETab: observable,
      setAdminOKTETab: action,
      adminECBTab: observable,
      setAdminECBTab: action,
      isOpenNewSubjectDialog: observable,
      openNewSubjectDialog: action,
      closeNewSubjectDialog: action,
      resetAdminModul: action,
      setAdminUserTabScreen: action,
      setAssignUserData: action
    });
  }
}

const singleton = new AdminStore();
export default singleton;
