import axios from 'axios';
import { format, isDate, isEqual, isValid, set } from 'date-fns';
import FileSaver from 'file-saver';
import i18next from 'i18next';
import { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format';
import LoginStore from '../../modules/common/login/LoginStore';
// import API from '../config/api';
import { AUTH_TOKEN, DATETIME_FORMAT, DATE_FORMAT } from '../config/constants';
import settings from '../config/settings';
import { IExportToExcel } from '../interfaces/requests/exportToExcel.request.interface';
import { IPDFOptions } from '../interfaces/requests/printer.request.interface';
import { IUserCompany, IUserPerson } from '../interfaces/userInfo';
import { getByKey } from '../storage/StorageEx';
import UIStore from '../stores/UIStore';
import {
  BDGListStatus,
  BDGProposedStatus,
  DistReportStatus,
  ECVSStatus,
  OESStatus,
  TBuildingOperationReportStatus,
  TEnergeticAuditStatus,
  TOperationReportStatus,
  TSavingsPotentialStatus
} from '../types/types';
import { IServerCallErrorResponse } from './hooks';
import { IDataLakeRaSearch } from '../interfaces/responses/dataLakeRa.response.interface';
// import { UseFormSetValue } from 'react-hook-form';

/** prevod akejkolvek hodnoty na boolean hodnotu */
export const toBoolean = (value: any): boolean => {
  return (value && typeof value === 'string' && value.toLowerCase() === 'true') || +value === 1;
};

export const toNumber = (value: any): number => {
  return value ? +value : value;
};

export const getPriceFormat = (price: number, digits?: number) => {
  return (
    price?.toLocaleString('sk-SK', { minimumFractionDigits: digits ?? 2, maximumFractionDigits: digits ?? 2 }) ?? '-'
  );
};

export const getDateFormat = (date: any): string => {
  if (typeof date === 'string') {
    const newDate = new Date(date);
    const isValidDate = isValid(newDate);

    return isValidDate ? format(newDate, DATE_FORMAT) : '';
  }

  if (isDate(date)) {
    return format(date, DATE_FORMAT);
  }

  return '';
};

export const getDateTimeFormat = (date: any): string => {
  if (typeof date === 'string') {
    const newDate = new Date(date);
    const isValidDate = isValid(newDate);

    return isValidDate ? format(newDate, DATETIME_FORMAT) : '';
  }

  if (isDate(date)) {
    return format(date, DATETIME_FORMAT);
  }

  return '';
};

export const toCurrencyString = (value: any, currency: string = 'EUR') => {
  const formater = new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency
  });
  return formater.format(value);
};

export const toPercent = (value: any) => `${value} %`;
export const trimValue = (value: any) => value?.trim();

export const equalsDate = (value1: Date, value2: Date) =>
  isEqual(
    set(value1, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
    set(value2, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );

export const NumberFormat = (args: { decimalScale?: number; fixedDecimalScale?: boolean }) => {
  const { decimalScale = 2, fixedDecimalScale = true } = args;
  return {
    thousandSeparator: ' ',
    decimalSeparator: ',',
    decimalScale,
    fixedDecimalScale
  };
};

export const NumberFormatEx = (args: Partial<NumberFormatPropsBase>) => {
  const { size, color, suffix, prefix, ...rest } = args;
  return {
    ...rest,
    thousandSeparator: ' ',
    decimalSeparator: ','
  };
};

export const maxNumber = (places: number) => {
  const limit = Math.pow(10, places);
  return (values: NumberFormatValues) => -limit < (values.floatValue ?? 0) && (values.floatValue ?? 0) < limit;
};

//------------------------------------------------------

export const getAuthToken = () =>
  getByKey<string>(AUTH_TOKEN) ? `SIEA ${getByKey<string>(AUTH_TOKEN)}` : ('' as string);

export const getUserId = () => {
  const user = LoginStore.userInfo?.user;

  if (user) {
    return user.user_id;
  }

  return null;
};

export const getCompanyId = () => {
  return LoginStore.currentCompany?.company_id ? LoginStore.currentCompany.company_id : null;
};

export const getCompanyInfo = () => {
  return LoginStore.currentCompany ?? null;
};

export const getPerson = (): IUserPerson | undefined => {
  return LoginStore.userInfo?.person;
};

export const getCompanies = (): IUserCompany[] | undefined => {
  return LoginStore.userInfo?.companies;
};

export const isModuleVisible = (module: string): boolean => {
  const keySplitted = module.split(':');
  if (keySplitted.length > 1 && keySplitted[1] === settings.MODULE) {
    return true;
  }
  return false;
};

export const getAvailablePersonModules = (): string[] => {
  if (LoginStore.userInfo?.person) {
    const personModules = Object.keys(LoginStore.userInfo.person.modules);

    if (personModules) {
      return personModules.filter((m) => isModuleVisible(m));
    }
  }
  return [];
};

export const getAvailableModulesForCompany = (
  companyId: string | undefined,
  company: IUserCompany | null | undefined
): string[] => {
  const companyFound: IUserCompany | undefined = company ?? getCompanies()?.find((c) => c.company_id === companyId);

  if (companyFound) {
    return Object.keys(companyFound.modules).filter((k) => isModuleVisible(k));
  }
  return [];
};

export const setCompany = (company: IUserCompany) => {
  LoginStore.isPersonSelected = false;
  return LoginStore.setCurrentCompany(company);
};

export const getFilters = (expressions: string[], extras?: any) => {
  const operators = ['='];
  const filters = expressions.map((expr: string) => {
    if (operators.some((o) => expr.includes(o))) {
      const operator: string | undefined = operators.find((o) => expr.includes(o));

      if (operator) {
        const exprSplit = expr.split(operator);
        const column = exprSplit[0];
        const values = exprSplit[1];
        const valuesArr = values.split(';');

        const valuesJSON = valuesArr.map((val) => {
          let idval: string | boolean = val;
          if (val.toLowerCase() === 'true') {
            idval = true;
          }
          if (val.toLowerCase() === 'false') {
            idval = false;
          }
          return { id_value: idval };
        });

        return {
          associated_column: column,
          predicate: operator,
          values: valuesJSON
        };
      }
    }
    return {};
  });

  return Object.assign({}, { filters: filters }, extras);
};

export const setAxiosDefaults = () => {
  axios.defaults.baseURL = settings.API_BASE_URL;
  axios.defaults.headers.common['Authorization'] = getAuthToken();

  //Nie je to potrebne rieši to useServerCall hook
  //Pri logine chceme aj na login stranke nastavit token
  // if (force === false) {
  //   // V pripade ze sme na public route neposielat token
  //   const path = window.location.pathname;
  //   const publicPaths = ['/login', '/resetHesla', '/passwordResetFinish', '/registracia', '/registerFinish', '/'];
  //   if (path && publicPaths.includes(path)) {
  //     axios.defaults.headers.common['Authorization'] = '';
  //   }
  // }
};

export const fakeRequest = (delay: number = 1000, status: number = 200): Promise<any> => {
  return new Promise((resolve) => setTimeout(() => resolve({ status }), delay));
};

export const getSPStatusColor = (statusId: TSavingsPotentialStatus) => {
  let color: string = '';
  switch (statusId) {
    case 'pre':
      color = 'blueInfo';
      break;
    case 'snt':
      color = 'amberWarning';
      break;
    case 'rea':
      color = 'greenSuccess';
      break;
  }

  return color;
};

export const getEnergeticStatusColor = (statusId: TEnergeticAuditStatus) => {
  let color: string = '';
  switch (statusId) {
    case 'can':
      color = 'greyLight';
      break;
    case 'clo':
      color = 'greenSuccess';
      break;
    case 'den':
      color = 'redDanger';
      break;
    case 'pre':
      color = 'blueInfo';
      break;
    case 'snt':
      color = 'amberWarning';
      break;
    case 'wai':
      color = 'purple';
      break;
  }

  return color;
};

export const getBuildingOpreationReportStatusColor = (statusId: TBuildingOperationReportStatus) => {
  let color: string = '';
  switch (statusId) {
    case 'can':
      color = 'greyLight';
      break;
    case 'clo':
      color = 'greenSuccess';
      break;
    case 'den':
      color = 'redDanger';
      break;
    case 'pre':
      color = 'blueInfo';
      break;
    case 'snt':
      color = 'amberWarning';
      break;
    case 'wai':
      color = 'purple';
      break;
  }

  return color;
};

export const getOpreationReportStatusColor = (statusId: TOperationReportStatus) => {
  let color: string = '';
  switch (statusId) {
    case 'can':
      color = 'greyLight';
      break;
    case 'clo':
      color = 'greenSuccess';
      break;
    case 'den':
      color = 'redDanger';
      break;
    case 'pre':
      color = 'blueInfo';
      break;
    case 'snt':
      color = 'amberWarning';
      break;
    case 'wai':
      color = 'purple';
      break;
  }

  return color;
};

export const getCVSStatusColor = (statusId: ECVSStatus) => {
  let color: string = '';
  switch (statusId) {
    // case 'can':
    //   color = 'greyLight';
    //   break;
    // case 'clo':
    //   color = 'greenSuccess';
    //   break;
    // case 'den':
    //   color = 'redDanger';
    //   break;
    case ECVSStatus.INPROGRESS:
      color = 'blueInfo';
      break;
    case ECVSStatus.SENT:
      color = 'amberWarning';
      break;
    // case 'wai':
    //   color = 'purple';
    //   break;
  }

  return color;
};

export const getOESStatusColor = (statusId: OESStatus) => {
  let color: string = '';
  switch (statusId) {
    case OESStatus.INPROGRESS:
      color = 'blueInfo';
      break;
    case OESStatus.SENT:
      color = 'amberWarning';
      break;
  }

  return color;
};

export const getDistStatusColor = (statusId: DistReportStatus) => {
  let color: string = '';
  switch (statusId) {
    case DistReportStatus.INPROGRESS:
      color = 'blueInfo';
      break;
    case DistReportStatus.SENT:
      color = 'amberWarning';
      break;
  }

  return color;
};

export const getBDGStatusColor = (statusId: BDGListStatus) => {
  let color: string = '';

  switch (statusId) {
    case BDGListStatus.OK:
      color = 'greenSuccess';
      break;
    case BDGListStatus.PCH:
      color = 'amberWarning';
      break;
    case BDGListStatus.NPC:
      color = 'redDanger';
      break;
    case BDGListStatus.WOU:
      color = 'greyLight';
      break;
    case BDGListStatus.WMA:
      color = 'blueInfo';
      break;
    case BDGListStatus.WOLD:
      color = 'purple';
      break;
  }

  return color;
};

export const getBDGProposedStatusColor = (statusId: BDGProposedStatus) => {
  let color: string = '';

  switch (statusId) {
    case BDGProposedStatus.WOLD:
      color = 'redDanger';
      break;
    case BDGProposedStatus.WOU:
      color = 'amberWarning';
      break;
    case BDGProposedStatus.WMA:
      color = 'blueInfo';
      break;
    case BDGProposedStatus.OK:
      color = 'greenSuccess';
      break;
  }

  return color;
};

export const getLegislativeDutieIds = () => {
  const legDuties = getCompanyInfo()?.legislative_duties;

  if (legDuties) {
    return Object.keys(legDuties);
  }

  return [];
};

export const isAuditor = () => {
  // EA
  const legislativeDuties = getLegislativeDutieIds();

  if (legislativeDuties) {
    return legislativeDuties.includes('EA');
  }

  return null;
};

type TAuditor = {
  auditors: {
    company_id: string;
    email: string;
    email2: string;
    phone_number: string;
    certificate: string;
    _id: string;
    person_id: string;
  }[];
};

export const getAuditor = () => {
  const eaModul: TAuditor = getUserModuleById('EA:FO');

  if (eaModul?.auditors && eaModul.auditors.length > 0) {
    const compId = getCompanyId();
    const personId = getPerson()?._id;

    if (compId) {
      return eaModul.auditors.find((x) => x.company_id === compId);
    }

    return eaModul.auditors.find((x) => x.person_id === personId);
  }

  return null;
};

export const isSubmitter = () => {
  // OC
  const legislativeDuties = getLegislativeDutieIds();

  if (legislativeDuties) {
    return legislativeDuties.includes('OC') || legislativeDuties.includes('OCM');
  }

  return null;
};

export const getUserModuleIds = () => {
  if (LoginStore.userInfo?.modules) {
    return Object.keys(LoginStore.userInfo!.modules);
  }

  return [];
};

export const getUserModuleById = (id: string) => {
  const modules: any = getUserModules();

  return modules[id];
};

export const getUserModules = () => {
  return LoginStore.userInfo?.modules ?? {};
};

export const exportToExcel = (
  req: IExportToExcel,
  action?: () => void,
  errorAction?: () => void,
  custom_url: string = '/object/export'
) => {
  UIStore.loading(true);
  if (!req.row_count_show) req.row_count_show = 100000;
  if (!req.row_offset) req.row_offset = 1;
  if (!req.row_count_full) req.row_count_full = 100000;

  fetch(`${settings.API_BASE_URL}${custom_url}`, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      if (res?.ok) {
        const contentDisposition: string = res?.headers?.get('content-disposition') ?? '';
        const filename = contentDisposition?.split('filename=')[1];
        res.blob().then((blob) => {
          FileSaver.saveAs(blob, filename ?? 'export');
        });
        if (typeof action === 'function') {
          action();
        }
      } else {
        // Kedze response pride ako file tak ho treba prekonvertovat na json aby sme vedeli zobrazit chybu
        res.json().then((data) => {
          showServerError(data.errors);
        });
      }
    })
    .catch(() => {
      err('error export to excel');
      if (typeof errorAction === 'function') {
        errorAction();
      }
    })
    .finally(() => UIStore.loading(false));
};

//ZOZNAM MODULOV ID-nazov...

// BDG	Budovy
// BDG:BO	Budovy - Back office
// BDG:FO	Budovy - Front office
// CVS	Celková vlastná spotreba
// CVS:BO	Celková vlastná spotreba - Back office
// CVS:FO	Celková vlastná spotreba - Front office
// DD	Dobrovoľná dohoda
// DD:BO	Dobrovoľná dohoda - Back office
// DD:FO	Dobrovoľná dohoda - Front office
// DIST	Rozvody
// DIST:BO	Rozvody - Back office
// DIST:FO	Rozvody - Front office
// EA	Energetický audit
// EA:BO	Energetický audit - Back office
// EA:FO	Energetický audit - Front office
// EP	Energetické plány
// EP:BO	Energetické plány - Back office
// EP:FO	Energetické plány - Front office
// ES	ES
// ES:BO	ES - Back office
// ES:FO	ES - Front office
// KVET	Kombinovaná výroba elektriny a tepla
// KVET:BO	Kombinovaná výroba elektriny a tepla - Back office
// KVET:FO	Kombinovaná výroba elektriny a tepla - Front office
// OES	OES
// OES:BO	OES - Back office
// OES:FO	OES - Front office

//Print or download PDF
//ONLY SUMMARY-DATA PRINT REPORT FOR NOW
export const printPDF = (
  template: string,
  payload?: any,
  pdfOptions?: IPDFOptions,
  download: boolean = false,
  fileName: string | undefined = undefined
) => {
  UIStore.loading(true);
  axios
    .post(
      `http://localhost:1337/print`,
      { _template: template, _options: pdfOptions, values: payload },
      {
        responseType: 'blob'
      }
    )
    // axios
    // .get(API.EASummaryPDF(payload?.audit?.header?._id), {
    //   responseType: 'blob'
    // })
    .then((res: any) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

      //Printing PDF in the new tab
      if (!download) {
        const fileURL = window.URL.createObjectURL(pdfBlob);
        let tab = windowOpen();
        if (tab) {
          tab.location.href = fileURL;
        }
      } else {
        //Download PDF as file
        const filename: string = fileName ?? 'print';
        FileSaver.saveAs(pdfBlob, `${filename}.pdf`);
      }
      UIStore.loading(false);
    })
    .catch((error) => {
      console.warn(error.message);
      UIStore.loading(false);
    });
};

export const downloadPDF = (url: string, download: boolean = false, fileName: string | undefined = undefined) => {
  UIStore.loading(true);
  axios
    .get(url, {
      responseType: 'blob'
    })
    .then((res: any) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

      //Printing PDF in the new tab
      if (!download) {
        const fileURL = window.URL.createObjectURL(pdfBlob);
        let tab = windowOpen();
        if (tab) {
          tab.location.href = fileURL;
        }
      } else {
        //Download PDF as file
        const filename: string = fileName ?? 'print';
        FileSaver.saveAs(pdfBlob, `${filename}.pdf`);
      }
      UIStore.loading(false);
    })
    .catch((error) => {
      console.warn(error.message);
      UIStore.loading(false);
    });
};

export const base64ToBlob = (base64Data: string, sliceSize = 512, type = 'text/csv;') => {
  const binaryData = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < binaryData.length; offset += sliceSize) {
    const slice = binaryData.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob([...byteArrays], { type });
};

//Override console
export const logger = settings.DEBUG ? console.log.bind(window.console) : () => {}; //eslint-disable-line

export const warn = settings.DEBUG ? console.warn.bind(window.console) : () => {}; //eslint-disable-line

export const err = settings.DEBUG ? console.error.bind(window.console) : () => {}; //eslint-disable-line

export const tryParseJSON = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    err('JSON parse error', e, jsonString);
  }
};

export const windowOpen = (url?: string, target?: string, features?: string) => {
  // eslint-disable-next-line no-restricted-properties
  const tab = window.open(url, target, features);
  if (tab == null || typeof tab == 'undefined') {
    UIStore.setNotificationMessage(i18next.t('global.error_popup'), 'error');

    return null;
  }
  return tab;
};

export const isUnique = <T extends unknown>(
  [value, index, self]: [value: T, index: number, self: T[]],
  identifier?: keyof T
) => {
  if (identifier) {
    return self.map((x: T) => x[identifier]).indexOf(value[identifier]) === index;
  } else {
    return self.indexOf(value) === index;
  }
};

export const copyToClipboard = async (text: string): Promise<boolean> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    return true;
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy');
    } catch {
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return false;
};

export const htmlToPlain = (text: string) => {
  if (text) {
    return String(text)
      .replace(/&nbsp;/gm, ' ')
      .replace(/<p/gm, '\n<p')
      .replace(/<\/p/gm, '\n</p')
      .replace(/<div/gm, '\n<div')
      .replace(/<\/div/gm, '\n</div')
      .replace(/<br/gm, '\n<br')
      .replace(/<[^>]+>/gm, '')
      .replace(/\n\n/gm, '\n')
      .trim();
  } else {
    return '';
  }
};

export const showServerError = (errors: IServerCallErrorResponse[] | undefined) => {
  if (!errors || errors.length === 0) return;

  // Pri exporte do excelu pri chybe nechodi desc ale description, takze treba skontrolovat aj description a takto to hacknut..... :(
  const { code, desc, description, params } = errors[0];
  const translateExist = i18next.exists(`error_codes.backend.${code}`);

  if (translateExist) {
    UIStore.setNotificationMessage(i18next.t(`error_codes.backend.${code}`, { ...params }), 'error');
    // example of translate KEY with PARAMS
    // "-1221": "Tento účet nemá právo na backoffice modul (###: {{param0}}, ###: {{param1}}, ###: {{param2}})"
  } else {
    UIStore.setNotificationMessage((desc || description) ?? '', 'error');
  }
};

export const getBuildingLabel = <T extends IDataLakeRaSearch>(data?: T) => {
  if (!data) return '';
  return `${data.municipality_name}, ${data.street_name} ${data.property_registration_number}/${data.building_number}`;
};

export function stringToHashCode(str: string) {
  return str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
    .toString();
}
