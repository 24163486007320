import { Autocomplete, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce, useError } from '../../global/helpers/hooks';
import { stringToHashCode } from '../helpers/actions';

interface IXsAutocompleteComplete extends Omit<ControllerRenderProps, 'ref'> {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  request: {
    uri: string;
    payload: {
      load: (value: any) => any;
      search: (value: string) => any;
    };
  };
  identFn: (option: any) => string | number;
  labelFn: (option: any) => string;
  onApply?: (option: any, isLoad: boolean) => void;
  freeText?: boolean;
  objectToCreate?: (inputVal: string) => object;
  minLengthForSearch?: number;
  noOptionsText?: string;
  placeholder?: string;
  limit?: number;
}

export const XsAutocompleteServer = (props: IXsAutocompleteComplete) => {
  const {
    value,
    name,
    request,
    identFn,
    labelFn,
    onApply,
    label,
    required = false,
    disabled,
    noOptionsText,
    freeText = false,
    objectToCreate,
    minLengthForSearch = 0,
    placeholder = '',
    limit = 100
  } = props;
  const { uri, payload } = request;
  const { load, search } = payload;

  const { t } = useTranslation();
  const form = useFormContext();
  const { error, helperText } = useError(name);

  const [selectedValue, setSelectedValue] = useState<any>();
  const [searchList, setSearchList] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const currReqId = useRef('');

  const isMounted = useRef(false);
  const isLoading = useRef(true);
  const canLoadData = useRef(true);

  const debouncedValue = useDebounce(inputValue, 500);

  const handleLoad = useCallback(async () => {
    if (!isMounted.current || canLoadData.current) {
      isMounted.current = true;
      if (value) {
        const req = load(value);
        req.row_count_show = limit;
        req.row_offset = 1;

        const response = await axios.post<any>(uri, req);
        const data = response.data.rows;
        setSearchList(data);
        if (data.length > 0) {
          setSelectedValue(data[0]);
          if (onApply) onApply(data[0], true);
        } else setSelectedValue(null);
      } else {
        setSelectedValue(null);
      }
      isLoading.current = false;
    } else {
      canLoadData.current = true;
    }
  }, [value]);

  const handleSearch = useCallback(async () => {
    if (!isLoading.current) {
      if (debouncedValue) {
        setSearchList([]);
        let data = [];

        if (debouncedValue?.length >= minLengthForSearch) {
          const req = search(debouncedValue);
          req.row_count_show = limit;
          req.row_offset = 1;

          currReqId.current = stringToHashCode(debouncedValue);

          const response = await axios.post<any>(uri, req, {
            headers: { 'SIEA-REQUESTID': stringToHashCode(debouncedValue) }
          });

          const responseRequestId = response.headers['siea-requestid'];
          if (responseRequestId === currReqId.current) {
            data = response?.data?.rows ?? [];
            setSearchList(data);
          }
        }
      } else {
        setSelectedValue(null);
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const tooltipText = selectedValue && labelFn ? labelFn(selectedValue) : '';

  return (
    <Autocomplete
      clearText={t('xs_autocompleteServer.clear_text')}
      openText={t('xs_autocompleteServer.open_text')}
      closeText={t('xs_autocompleteServer.close_text')}
      noOptionsText={noOptionsText ? noOptionsText : t('xs_autocompleteServer.no_options_text')}
      options={searchList}
      disabled={disabled}
      filterOptions={(options, params) => {
        if (freeText) {
          const { inputValue } = params;
          // Podhodi moznost vytvorenia novej hodnoty
          const isExisting = options?.some((option) => identFn(option) ?? null);
          if (inputValue !== '' && !isExisting) {
            options.push(
              objectToCreate
                ? objectToCreate(inputValue)
                : {
                    inputValue,
                    name_ext: inputValue
                  }
            );
          }
        }

        return options;
      }}
      selectOnFocus={!freeText}
      clearOnBlur={true}
      freeSolo={freeText}
      value={selectedValue ?? null}
      popupIcon={<i className='fal fa-ellipsis-h'></i>}
      onChange={(_, v) => {
        canLoadData.current = false;
        form.setValue(name, v ? identFn(v) : null, { shouldValidate: true });
        setSelectedValue(v);
        if (onApply) onApply(v, false);
      }}
      isOptionEqualToValue={(option, value) => identFn(option) === identFn(value)}
      getOptionLabel={(option) => labelFn(option) ?? ''}
      renderOption={(props, option) => {
        return (
          <li {...props} key={identFn(option)}>
            {labelFn(option)}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <Tooltip title={tooltipText}>
            <form noValidate>
              <TextField
                {...params}
                required={required}
                size='small'
                label={label}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setInputValue(e.target.value)}
                error={error}
                helperText={helperText}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }
                }}
              />
            </form>
          </Tooltip>
        );
      }}
    />
  );
};
