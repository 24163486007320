import { tryParseJSON } from '../helpers/actions';

export type TSessionStorageValue = string | object;

const toStr = (value: TSessionStorageValue) => (typeof value === 'object' ? JSON.stringify(value) : String(value));

export const getByKeySession = <TKey extends TSessionStorageValue>(key: TKey): TSessionStorageValue | null => {
  let parsedValue = window.sessionStorage.getItem(toStr(key));
  if (parsedValue) {
    return tryParseJSON(parsedValue);
  }
  return null;
};

export const updateByKeySession = <TKey extends TSessionStorageValue, TVal extends TSessionStorageValue>(
  key: TKey,
  value: TVal
) => {
  window.sessionStorage.setItem(toStr(key), toStr(value));
};

export const deleteByKeySession = <TKey extends TSessionStorageValue>(key: TKey) => {
  window.sessionStorage.removeItem(toStr(key));
};
