import { FormHelperText } from '@mui/material';
import classnames from 'classnames';
import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  components,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  MenuPlacement,
  OptionsOrGroups
} from 'react-select';
import { useError } from '../helpers/hooks';

export type TSelectProps<T> = Omit<ControllerRenderProps, 'ref'> & {
  options?: OptionsOrGroups<T, GroupBase<T>>;
  getOptionLabel?: GetOptionLabel<T>;
  getOptionValue?: GetOptionValue<T>;
  label?: string;
  required?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  controllerProps?: Omit<ControllerRenderProps, 'ref'>;
  postAction?: (e: any) => void;
  disabled?: boolean;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  menuWidth?: string; //viete posielat % aj px
  menuLimit?: number;
  tabIndex?: number;
};

export const XsSearchSelect = <T extends unknown>(props: TSelectProps<T>) => {
  const {
    name,
    label,
    required,
    isClearable,
    isLoading,
    postAction,
    controllerProps,
    options,
    isMulti,
    getOptionValue,
    getOptionLabel,
    disabled,
    menuPlacement = 'auto',
    menuWidth = '100%',
    menuLimit,
    tabIndex,
    ...rest
  } = props;

  let customStyles: any;

  const { error, helperText } = useError(name);
  const { t } = useTranslation();

  customStyles = {
    control: (styles: any, { menuIsOpen }: any) => {
      return {
        ...styles,
        '&:hover': {
          borderColor: '#000000 !important'
        },
        backgroundColor: '#FFFFFF',
        // border: `1px solid ${menuIsOpen ? '#000000de' : error ? '#ea7070' : '#c4c4c4'}`,
        borderWidth: 1,
        borderColor: menuIsOpen ? '#000000de' : error ? '#ea7070' : '#c4c4c4',
        borderStyle: disabled ? 'dashed' : 'solid',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        height: '40px'
        // boxShadow: 'none'
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      width: menuWidth,
      zIndex: '2'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#22232c'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    dropdownIndicator: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#54bebb',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
      };
    }
  };

  const labelStyles: React.CSSProperties = {
    position: 'absolute',
    zIndex: 1,
    fontSize: '0.75rem',
    marginLeft: '10px',
    // color: error ? '#c6184e' : disabled ? 'rgba(0, 0, 0, 0.4)' : '#22232c',
    color: error ? '#c6184e' : '#22232c',
    top: '-7px',
    background: 'white',
    padding: '0px 6px 0px 4px'
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {Array.isArray(props.children) ? props.children.slice(0, menuLimit ? menuLimit : 50) : props.children}
      </components.MenuList>
    );
  };

  let selectComponents: Record<string, (props: any) => JSX.Element | null> = { MenuList };
  if (disabled) {
    selectComponents = {
      ...selectComponents,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null
    };
  }

  return (
    <div className='flex-col w-full relative'>
      {label && (
        <div className={classnames({ bold: required })} style={labelStyles}>
          {required ? `${label} *` : label}
        </div>
      )}
      <ReactSelect<T>
        {...rest}
        tabIndex={tabIndex}
        closeMenuOnSelect={!isMulti}
        noOptionsMessage={() => t('xs_searchSelect.no_options_message')}
        options={options}
        components={selectComponents}
        onChange={(e) => {
          rest.onChange(e);
          if (postAction && typeof postAction === 'function') {
            postAction(e);
          }
        }}
        placeholder={''}
        menuPlacement={menuPlacement}
        menuPosition={'absolute'}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        styles={customStyles}
        isClearable={isClearable}
        isLoading={isLoading}
        isDisabled={disabled}
      />
      {error && (
        <FormHelperText error={true} style={{ marginLeft: '14px' }}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};
