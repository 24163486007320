import React from 'react';
import { ITextFieldProps, XsTextField } from './XsTextField';
import { useTranslation } from 'react-i18next';

type Props = Omit<ITextFieldProps, 'counter'> & {
  counter: number;
};

export const XsNoteField = (props: Props) => {
  const { t } = useTranslation();
  return <XsTextField {...props} type='text' label={props.label ?? t('global.note')} />;
};
