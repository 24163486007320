import { joiResolver } from '@hookform/resolvers/joi';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import Joi from 'joi';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { load } from 'recaptcha-v3';
import API from '../config/api';
import settings from '../config/settings';
import { getUserId } from '../helpers/actions';
import { useCodeList, useServerCall } from '../helpers/hooks';
import { isEmailValid, isPhoneNumberValid } from '../helpers/validators';
import { IUserFormFO, IUserObject } from '../interfaces/forms/admin/adminUser.form.interface';
import { ICodeListObject, ICodeListResponse } from '../interfaces/responses/codeList.response.interface';
import UIStore from '../stores/UIStore';
import { XsButton } from './XsButton';
import { XsCheckbox } from './XsCheckbox';
import { XsSearchSelect } from './XsSearchSelect';
import { XsTextField } from './XsTextField';
import { XsNoteField } from './XsNoteField';

export const XsChangeUserDataDialog = observer(() => {
  const { t } = useTranslation();

  const [generateDisabled, setGenerateDisabled] = useState<boolean>(false);

  const { data: titles } = useCodeList<ICodeListResponse>('titles');

  const userId = getUserId();

  const loadFormData = useServerCall<void, IUserObject>({
    key: ['loadUser', userId],
    mode: 'control',
    request: {},
    queryOptions: { refetchOnWindowFocus: false, staleTime: 0 }
  });

  useEffect(() => {
    if (userId) {
      loadFormData.run({
        url: API.AdmUser(userId),
        method: 'GET'
      });
    }
  }, []);

  useEffect(() => {
    if (loadFormData?.data && !loadFormData.isPending && !loadFormData.isError) {
      const data = loadFormData?.data;

      setFormValue(data);
    }
  }, [loadFormData?.data]);

  const schema = Joi.object<IUserFormFO>({
    _id: Joi.allow(''),
    title_before: Joi.allow(''),
    login: Joi.string().regex(isEmailValid()).message('email_message').required(),
    phone: Joi.string().regex(isPhoneNumberValid()).allow('').message('phone_message').required(),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    auditor: Joi.boolean().default(false),
    auditor_certificate: Joi.string().when(Joi.ref('auditor'), {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.string().allow('')
    }),
    title_after: Joi.allow(''),
    note: Joi.string().max(100).allow('')
  });

  const form = useForm<IUserFormFO>({
    resolver: joiResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { setValue, watch } = form;

  const setFormValue = (data?: IUserObject) => {
    if (data) {
      setValue('_id', data?._id);
      setValue('first_name', data?.first_name);
      setValue('last_name', data?.last_name);
      setValue('login', data?.login);
      setValue('phone', data?.phone_number);
      setValue('auditor', data?.auditor);
      setValue('auditor_certificate', data?.auditor_certificate);
      if (data?.auditor_certificate) {
        setGenerateDisabled(true);
      }
      setValue('note', data?.note);
      setValue('title_before', titles?.rows.find((x: ICodeListObject) => x._id === data?.title_before_id) ?? null);
      setValue('title_after', titles?.rows.find((x: ICodeListObject) => x._id === data?.title_after_id) ?? null);
    }
  };

  const closeDialog = () => {
    UIStore.closeChangeUserDataDialog();
  };

  const generateNumberCall = useServerCall<any, any>({
    key: 'generateNumberPost',
    mode: 'control',
    request: {
      url: settings.API_PUBLIC_URL + '/client/auditor-certificate',
      method: 'GET'
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 0 },
    onSuccess: () => {
      setGenerateDisabled(false);
    },
    onError: () => {
      setGenerateDisabled(false);
    }
  });

  useEffect(() => {
    if (generateNumberCall.data && generateNumberCall?.data?.certificate) {
      setValue('auditor_certificate', generateNumberCall?.data?.certificate);
    }
  }, [generateNumberCall.data]);

  const generateEaNumber = () => {
    setGenerateDisabled(true);
    load(settings.SITE_KEY ?? '')
      .then((recaptcha) => {
        recaptcha
          .execute('auditor_certificate')
          .then((recToken) => {
            generateNumberCall.run({
              url: settings.API_PUBLIC_URL + '/client/auditor-certificate?g-recaptcha-response=' + recToken
            });
          })
          .catch(() => {
            setGenerateDisabled(false);
          });
      })
      .catch(() => {
        setGenerateDisabled(false);
      });
  };

  return (
    <Dialog open={UIStore.isOpenChangeUserDataDialog} maxWidth='md' fullWidth>
      <DialogTitle>
        <Grid container>
          <Grid item>{t('menu.mySettings')}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <Controller
                name='title_before'
                render={({ field }) => (
                  <XsSearchSelect
                    {...field}
                    isClearable={true}
                    options={titles ? titles.rows.filter((row) => row.before === true) : []}
                    label={t('global.titles_before')}
                    getOptionLabel={(obj: ICodeListObject) => obj.code_ext}
                    getOptionValue={(obj: ICodeListObject) => obj._id}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='login'
                render={({ field: { ref, ...rest } }) => (
                  <XsTextField
                    {...rest}
                    required
                    disabled
                    type='text'
                    label={t('adminContainer.users.userlist.login')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='phone'
                render={({ field: { ref, ...rest } }) => (
                  <XsTextField {...rest} type='text' label={t('global.phone_number')} required />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <Controller
                name='first_name'
                render={({ field: { ref, ...rest } }) => (
                  <XsTextField {...rest} type='text' label={t('global.first_name')} required />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='last_name'
                render={({ field: { ref, ...rest } }) => (
                  <XsTextField {...rest} required type='text' label={t('global.last_name')} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='title_after'
                render={({ field }) => (
                  <XsSearchSelect
                    {...field}
                    isClearable={true}
                    options={titles ? titles.rows.filter((row) => row.before !== true) : []}
                    label={t('global.titles_after')}
                    getOptionLabel={(obj: ICodeListObject) => obj.code_ext}
                    getOptionValue={(obj: ICodeListObject) => obj._id}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <Controller
                name='auditor'
                render={({ field: { ref, ...rest } }) => (
                  <XsCheckbox
                    {...rest}
                    label={t('adminContainer.users.userlist.auditor')}
                    onChange={(val) => {
                      setValue('auditor', val);
                      if (!val) {
                        setValue('auditor_certificate', '');
                        setGenerateDisabled(false);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {watch('auditor') && (
              <>
                <Grid item xs={4}>
                  <Controller
                    name='auditor_certificate'
                    render={({ field: { ref, ...rest } }) => (
                      <XsTextField
                        {...rest}
                        type='text'
                        label={t('global.auditor_certificate')}
                        disabled={!watch('auditor')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <XsButton
                    label='adminContainer.users.detail.generate_number_ea'
                    variant='contained'
                    disabled={generateDisabled}
                    onClick={() => generateEaNumber()}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Controller
                name='note'
                render={({ field: { ref, ...rest } }) => (
                  <XsNoteField {...rest} label={t('adminContainer.detail.note')} counter={100} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={'flex-end'} mt={2}>
            <Grid item>
              <XsButton
                startIcon={<i className='fal fa-times fa-lg'></i>}
                label='global.cancel'
                variant='outlined'
                onClick={() => {
                  closeDialog();
                }}
              />
            </Grid>
            <Grid item>
              <XsButton
                startIcon={<i className='fal fa-save fa-lg'></i>}
                label='global.save'
                variant='contained'
                onClick={() => {
                  UIStore.openConfirmChangeUserDataDialog(form.getValues());
                  closeDialog();
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
});
