import { makeAutoObservable } from 'mobx';
import { DistReportStatus, TDistRegisterScreen, TDistReportScreen } from '../../../global/types/types';

type TCompanyBase = {
  id: string;
  identifier: string;
  owner: 'report' | 'register';
};

class DistStore {
  constructor() {
    makeAutoObservable(this);
  }

  distReportScreen: TDistReportScreen = 'list';
  distRegisterScreen: TDistRegisterScreen = 'list';
  distStatus?: DistReportStatus;
  distAddRegisterModalIsOpen = false;
  company: TCompanyBase | null = null;

  setDistReportScreen(screen: TDistReportScreen) {
    this.distReportScreen = screen;
  }

  setDistRegisterScreen(screen: TDistRegisterScreen) {
    this.distRegisterScreen = screen;
  }

  setDistStatus(eaStatus: DistReportStatus) {
    this.distStatus = eaStatus;
  }

  distAddRegisterModalOpen() {
    this.distAddRegisterModalIsOpen = true;
  }

  distAddRegisterModalClose() {
    this.distAddRegisterModalIsOpen = false;
  }

  setCompany = (comp: TCompanyBase | null) => {
    this.company = comp;
  };
}

const singleton = new DistStore();
export default singleton;
