import { Column, Row } from 'react-table';
import { ISummaryColumn } from '../interfaces/common';

export type TScreen = 'login' | 'register' | 'forgot';
export type TRegisterScreen = '1' | '2';
export type TEaContainerTabs = 'info' | 'audit' | '';
export type TCvsContainerTabs = 'reports';
export type TOesContainerTabs = 'reports';
export type TDistContainerTabs = 'reports' | 'distRegister';
export type TEaTabScreen =
  | 'EAList'
  | 'header'
  | 'finalEnergyConsumption'
  | 'energeticObjects'
  | 'provisions'
  | 'energyCarriers'
  | 'summary';
export type TEnergeticAuditStatus = 'wai' | 'snt' | 'pre' | 'can' | 'clo' | 'den';
export type TBuildingOperationReportStatus = 'wai' | 'snt' | 'pre' | 'can' | 'clo' | 'den';
export type TOperationReportStatus = 'wai' | 'snt' | 'pre' | 'can' | 'clo' | 'den';
export type TEtlContainerTabs = 'status' | 'target' | 'projects' | 'expenditures' | 'indicators';

export type TColumnExt<T extends object> = Column & {
  align?: string;
  disableClick?: boolean;
  canOverflow?: boolean;
  Tooltip?: (row: Row<T>) => JSX.Element | string;
  summaries?: ISummaryColumn<T>[];
  classNames?: string;
};

export type TCVSSreens = 'list' | 'addReport' | 'reportDetail';
export type TOESSreens = 'list' | 'addReport' | 'reportDetail';
export type TDistReportScreen = 'list' | 'formContainer' | 'detail';
export type TDistRegisterScreen = 'list' | 'form';

export enum ECVSStatus {
  INPROGRESS = 'InProgress',
  SENT = 'Sent'
}

export enum OESStatus {
  INPROGRESS = 'InProgress',
  SENT = 'Sent'
}

export enum DistReportStatus {
  INPROGRESS = 'InProgress',
  SENT = 'Sent'
}

export enum BDGListStatus {
  OK = 'OK',
  NPC = 'NPC',
  PCH = 'PCH',
  WOLD = 'WOLD',
  WOU = 'WOU',
  WMA = 'WMA'
}

export enum BDGProposedStatus {
  WOLD = 'WOLD',
  WOU = 'WOU',
  WMA = 'WMA',
  OK = 'OK'
}

export type TMenuItem = {
  name: string;
  module: string;
  label: string;
  action: () => void;
  push: string;
  icon?: string;
  description?: string;
};

export type TAdminContainerTabs =
  | 'userList'
  | 'subjectContainer'
  | 'sppd'
  | 'okte'
  | 'ecb'
  | 'registrations'
  | 'codeList';

export type TAdminSubjectScreen = 'subjectList' | 'subjectDetail' | 'addSubject';

export type TMenuItems = TMenuItem[];

export type TAdminUserScreen = 'userList' | 'userDetail';

export type TAdminUserDetailTab = 'subjects' | 'modules' | 'legislation';

export type TAdminUserDetailNewDataDefs = { email: string; orgunit_id: string } | null;

export type TAdminSPPDTab = 'imports' | 'consumptions';

export type TAdminOKTETab = 'imports' | 'consumptions';

export type TAdminECBTab = 'imports' | 'certifications';

export type TObjectScreen = 'objectList' | 'objectCreateForm';

export type TRenewableEnergySourceScreen = 'renewableList' | 'renewableDetail';

export enum AddressType {
  OA = 'OA',
  BA = 'BA',
  BBA = 'BBA',
  CA = 'CA',
  L = 'L'
}

export enum SubmitterType {
  FO = 'FO',
  PO = 'PO',
  WHITHOUT = 'WHITHOUT'
}

export enum ObjectTypeId {
  BUILDING = 'ISEE.Data.Building',
  OTHER = 'ISEE.Data.GenericObject'
}

export type TAdminSubjectDetailTab = 'address' | 'contacts' | 'persons' | 'legislativeDuty' | 'subordinate';

export type TPuTabScreen = 'SPList' | 'header' | 'provisions' | 'energyCarriers' | 'summary' | 'energeticObjects';
export type TPuMainTabs = 'main';
export type TSavingsPotentialStatus = 'snt' | 'pre' | 'rea';

export type TBuildingTab =
  | 'buildingList'
  | 'operationReportList'
  | 'operationReportImport'
  | 'changeProvider'
  | 'identification_data'
  | 'tzb'
  | 'bulding_operation_report'
  | 'proposedBuilding'
  | 'submittedBuilding'
  | 'sppd'
  | 'okte'
  | 'cases'
  | 'ecb'
  | 'oze';
export type TBuildingScreen = 'list' | 'createFrom' | 'betweenMerge';

export type TBuildingOZEScreen = 'list' | 'form';

export type TOzeTabs = 'ozeObjects' | 'ozeList' | 'identificationData' | 'ozeTechnology';

export type TOomScreen = 'list' | 'createFrom';

export type TOomTab = 'oomList' | 'consuption_oom' | 'identification_data' | 'omm_consumption_graph';
