const settings = {
  DEBUG: process.env.REACT_APP_DEBUG !== '0' || false,
  DEBUG_I18N: process.env.REACT_APP_DEBUG_I18N !== '0' || false,
  DEBUG_APP_STATUS: process.env.REACT_APP_DEBUG_APP_STATUS !== '0' || false,
  SERVERROLE: process.env.REACT_APP_SERVERROLE || 'PROD',
  MODULE: process.env.REACT_APP_MODULE || 'FO',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  CI_COMMIT_HASH: process.env.REACT_APP_CI_COMMIT_HASH,
  BUILD_TIME: process.env.REACT_APP_CI_BUILD_TIME,
  API_PUBLIC_URL: process.env.REACT_APP_API_PUBLIC_URL,
  SITE_KEY: process.env.REACT_APP_SITE_KEY,
  API_LENGTH_THRESHOLD: 1048576,
  UPLOAD_FILE_SIZE_LIMIT: 50 * 1024 * 1024,
  LANGUAGE: 'sk',
  LOGIN_BY_TOKEN_EMAIL: 'loginbytoken@loginbytoken.invalid',
  HOMEPAGE: process.env.REACT_APP_HOMEPAGE || '/',
  DB_DATETIME_FORMAT: process.env.REACT_APP_DB_DATETIME_FORMAT || 'YYYY-MM-DDTHH:mm:ssZ',
  DB_DATE_FORMAT: process.env.REACT_APP_DB_DATE_FORMAT || 'YYYY-MM-DD',
  API_CLIENT_TIMEOUT: 1000 * 60 * 20, // default timeout
  API_CLIENT_TIMEOUT_GAP: 1000 * 60 * 10, // safety gap
  AUTOLOGOUT_PROMPT_VISIBILITY: 1000 * 60 // prompt dialog is displayed fo 60s
};

export default settings;
