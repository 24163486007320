import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { XsButton } from '.';
import UIStore from '../stores/UIStore';

function XsCautionModalDialog() {
  const { t } = useTranslation();

  const titleClassName = `xs-title-${UIStore.cautionObj?.variant === 'error' ? 'danger' : 'warning'}`;
  const titleText = UIStore.cautionObj?.variant === 'error' ? t('global.error_occurred') : t('global.warning_occurred');
  const buttonColor = UIStore.cautionObj?.variant === 'error' ? 'error' : 'warning';

  const onClose = () => {
    if (UIStore.cautionObj?.action) UIStore.cautionObj.action();

    UIStore.removeCautionMessage();
  };

  const dialog = UIStore.cautionObj?.message ? (
    <Dialog open={true} onClose={onClose} maxWidth='md' className={`xs-confirmation-dialog`}>
      <DialogTitle className={`xs-title ${titleClassName}`}>
        <Grid container direction='column'>
          <Grid item>
            <i className='fas fa-exclamation-triangle fa-2x'></i>
          </Grid>
          <Grid item>
            <Typography variant='h6'>{titleText}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className='dialog-content'>
        <Grid container justifyContent='center'>
          <Typography variant='body2' fontWeight={500}>
            {UIStore.cautionObj.message}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className='xs-footer'>
        <Grid container spacing={1} justifyContent='flex-end'>
          <Grid item>
            <XsButton
              variant='contained'
              color={buttonColor}
              startIcon={<i className='fal fa-times' />}
              label={'global.ok'}
              onClick={onClose}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  ) : null;

  return dialog;
}

export default observer(XsCautionModalDialog);
