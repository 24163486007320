import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { AUTH_TOKEN, IS_BO, SELECTED_COMPANY } from '../../../global/config/constants';
import {
  getAvailableModulesForCompany,
  getAvailablePersonModules,
  isModuleVisible
} from '../../../global/helpers/actions';
import { IUserCompany, IUserInfo } from '../../../global/interfaces/userInfo';
import { deleteByKeySession, getByKeySession, updateByKeySession } from '../../../global/storage/SessionStorageEx';
import { deleteByKey } from '../../../global/storage/StorageEx';
import UIStore from '../../../global/stores/UIStore';
import { TMenuItems } from '../../../global/types/types';
import AutoLogoutStore from '../../../global/stores/AutoLogoutStore';
import ServerTableStore from '../../../global/stores/ServerTableStore';

interface LoginAlreadyInUseDialogData {
  step: '1' | '2';
  proposition: boolean;
  ico?: string;
  name?: string;
}

class LoginStore {
  isAuthorized = false;
  isReloginDialog = false;
  isPersonSelected?: boolean = false;

  userInfo?: IUserInfo;
  currentCompany?: IUserCompany;
  availableModules?: string[];

  AutoLogoutOnStart?: Function;
  Refetch: (() => Promise<any>)[] = [];
  refreshUser?: Function;

  menuItemsFO: TMenuItems = [];
  menuItemsBO: TMenuItems = [];

  isLoginAlreadyInUseOpen = false;
  loginAlreadyInUseData: LoginAlreadyInUseDialogData = { step: '1', proposition: false };

  constructor() {
    makeObservable(this, {
      isAuthorized: observable,
      isReloginDialog: observable,
      isPersonSelected: observable,
      userInfo: observable,
      currentCompany: observable,
      availableModules: observable,
      menuItemsBO: observable,
      menuItemsFO: observable,
      isLoginAlreadyInUseOpen: observable,
      loginAlreadyInUseData: observable,

      LogIn: action,
      LogOut: action,

      ShowReloginDialog: action,
      HideReloginDialog: action,

      setCurrentCompany: action,
      setUserInfo: action,

      openLoginAlreadyInUse: action,
      openLoginAlreadyInUseStep2: action,
      setPropositionLoginAlreadyInUse: action,
      closeLoginAlreadyInUse: action,
      hideLoginAlreadyInUse: action
    });
  }

  RegisterAutoLogoutOnStartFn = (callback: Function) => (this.AutoLogoutOnStart = callback);

  LogIn = () => {
    if (this.AutoLogoutOnStart) this.AutoLogoutOnStart();
    this.isAuthorized = true;
  };
  LogOut = () => {
    deleteByKey(AUTH_TOKEN);
    deleteByKeySession(SELECTED_COMPANY);
    this.userInfo = undefined;
    this.currentCompany = undefined;
    this.isPersonSelected = false;
    this.availableModules = undefined;
    this.menuItemsFO = [];
    this.menuItemsBO = [];
    this.isAuthorized = false;
    axios.defaults.headers.common['Authorization'] = '';
    AutoLogoutStore.setTokenExpDateUTC(null);
    ServerTableStore.resetStoreOnLogout();
  };

  ShowReloginDialog = (refetch?: () => Promise<any>) => {
    if (refetch) this.Refetch.push(refetch);
    this.isReloginDialog = true;
  };

  HideReloginDialog = async () => {
    this.isReloginDialog = false;
    for (const refetch of this.Refetch) {
      await refetch();
    }
    this.Refetch = [];
  };

  setCurrentCompany(company: IUserCompany | undefined) {
    this.currentCompany = company;
    this.setAvailableModules(company ? getAvailableModulesForCompany(undefined, company) : undefined);
    updateByKeySession(SELECTED_COMPANY, JSON.stringify(company));
  }

  setCurrentPerson() {
    //Zrušiť aktuálne zvolené company a nastaviť dostupné moduly z person modules
    this.isPersonSelected = true;
    this.setCurrentCompany(undefined);
    this.setAvailableModules(getAvailablePersonModules());
  }

  setAvailableModules(availableModules: string[] | undefined) {
    this.availableModules = availableModules;
    this.menuItemsFO = UIStore.menuItems.filter(
      (m) => isModuleVisible(m.module) && this.availableModules?.includes(m.module)
    );
    this.menuItemsBO = UIStore.menuItems.filter((m) => isModuleVisible(m.module));
  }

  setUserInfo(userInfo: IUserInfo) {
    this.userInfo = userInfo;
    //Ak je to BO tak nastavujeme rovno dostupné moduly z person.modules
    if (IS_BO) {
      this.setAvailableModules(getAvailablePersonModules());
      return;
    }

    //Ak nemáme zvolenú company ani person a setujeme userInfo
    //Pozrieme či máme nejaké companies - ak áno pozrieme ešte do sessionStorage či tam niečo nemáme, ak nie tak predvolíme prvú
    //Ak nemáme žiadne companies pozrieme či person nemá moduly - ak áno zvolíme person
    if (this.currentCompany === undefined && !this.isPersonSelected) {
      if (userInfo.companies?.length > 0) {
        const companyFromStorage = getByKeySession(SELECTED_COMPANY) as IUserCompany;
        if (companyFromStorage) {
          this.setCurrentCompany(companyFromStorage);
        } else {
          const firstCompany = userInfo.companies.find((c) => getAvailableModulesForCompany(undefined, c)?.length > 0);
          this.setCurrentCompany(firstCompany);
        }
      } else if (getAvailablePersonModules()?.length > 0) {
        this.setCurrentPerson();
      }
    }
  }

  openLoginAlreadyInUse = (ico: string) => {
    this.isLoginAlreadyInUseOpen = true;
    this.loginAlreadyInUseData = {
      ico,
      proposition: false,
      step: '1'
    };
  };

  setPropositionLoginAlreadyInUse = (proposition: boolean) => {
    this.isLoginAlreadyInUseOpen = true;
    this.loginAlreadyInUseData.proposition = proposition;
  };

  hideLoginAlreadyInUse = () => {
    this.isLoginAlreadyInUseOpen = false;
  };

  openLoginAlreadyInUseStep2 = (ico: string, name: string) => {
    this.isLoginAlreadyInUseOpen = true;
    this.loginAlreadyInUseData = {
      ico,
      name,
      proposition: false,
      step: '2'
    };
  };

  closeLoginAlreadyInUse = () => {
    this.isLoginAlreadyInUseOpen = false;
    this.loginAlreadyInUseData = {
      proposition: false,
      step: '1'
    };
  };
}
const singleton = new LoginStore();
export default singleton;
