import { action, makeObservable, observable } from 'mobx';
import { IEnergeticAuditResponse } from '../../../global/interfaces/responses/energeticAudit.response.interface';
import { TEaTabScreen, TEnergeticAuditStatus } from '../../../global/types/types';

type IEnergeticAuditHeaderDTO = {
  id: string;
  identifier: string;
  submitter_name: string;
  submitter_ico: string;
  auditor_name: string;
  preparation_date: Date;
};

interface IEditSummaryProvisionArgs {
  objectId: string;
  provisionId: number;
  data: IEnergeticAuditResponse | null;
}

interface IEditSummaryObjectArgs {
  objectId: string;
}

interface IEditSummaryCarrierArgs {
  objectId: string;
  provisionId: number;
  carrierId: string;
  data: IEnergeticAuditResponse | null;
}

class EnergeticAuditStore {
  eaTabScreen: TEaTabScreen = 'EAList';
  eaStatus?: TEnergeticAuditStatus;

  editSummaryProvision?: IEditSummaryProvisionArgs;
  editSummaryConsumptions?: {};
  editSummaryObject?: IEditSummaryObjectArgs;
  editSummaryCarrier?: IEditSummaryCarrierArgs;

  energeticAuditBaseInfo: IEnergeticAuditHeaderDTO | undefined;

  setEaTabScreen(screen: TEaTabScreen) {
    this.eaTabScreen = screen;
  }

  setEAStatus(eaStatus: TEnergeticAuditStatus) {
    this.eaStatus = eaStatus;
  }

  clearSummaryData() {
    this.editSummaryProvision = undefined;
    this.editSummaryConsumptions = undefined;
    this.editSummaryObject = undefined;
    this.editSummaryCarrier = undefined;
  }

  constructor() {
    makeObservable(this, {
      eaTabScreen: observable,
      eaStatus: observable,
      setEaTabScreen: action
    });
  }
}

const singleton = new EnergeticAuditStore();
export default singleton;
