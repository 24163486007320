import settings from './settings';

export const AUTH_TOKEN = 'auth-token';
export const SELECTED_COMPANY = 'selected-company';
export const IS_BO = settings.MODULE === 'BO';
export const IS_FO = settings.MODULE === 'FO';

export const DATE_FORMAT: string = 'dd.MM.yyyy';
export const DATE_FORMAT_EN: string = 'yyyy-MM-dd';
export const TIME_FORMAT: string = 'HH:mm';
export const DATETIME_FORMAT: string = 'dd.MM.yyyy HH:mm';
export const DATETIMESEC_FORMAT: string = 'dd.MM.yyyy HH:mm:ss';
export const MONTH_FORMAT: string = 'MM/yyyy';

export const REGEX_PARCEL_NUMBER = /^\s*(\d+(\/\d+)?(\s*,\s*?\d+(\/\d+)?)*)\s*$/
export const REGEX_BDG_ECB_IDENTIFIER =
  /^\d{6}\/(19|20|21)\d\d\/[1-9][1-5](\/|\/EC|\/[^\/]+|\/[^\/]+\/|\/[^\/]+\/EC)?$/; // eslint-disable-line

export const SPPD_PREFIX = 'SKSPPDIS';
export const REGEX_SPPD_POD = new RegExp(`^${SPPD_PREFIX}[\\d]{12}$`);

export const OTHER_FUNDING_CODE = '13';
export const ERR_CODE_MAINTENANCE = -1413; // error code from backend
export const BDG_CATEGORY_CODE_UNSPECIFIED = 'UN'; // kategoria "neurcene"
export const BDG_CATEGORY_CODE_OTHER = 'OT'; // kategoria "ine"
export const CVS_MEDIUM_CODE_ELECTRICITY = '100'; // energonosic "elektrina"
