import { QueryClient } from 'react-query';

interface IQueryGuardProps<T> {
  isPending: boolean;
  isError: boolean;
  isNew?: boolean;
  data: T;
  LoadingElement?: React.ReactNode;
  ErrorElement?: React.ReactNode;
  children: ((data: Exclude<T, undefined>) => JSX.Element) | JSX.Element;
}

const isDefined = <T extends unknown>(data: T): data is Exclude<T, undefined> => {
  return data !== undefined;
};

export const QueryGuard = <T extends unknown>(props: IQueryGuardProps<T>) => {
  const { isPending, isError, data, LoadingElement, ErrorElement, children, isNew = false } = props;
  if (isError && ErrorElement) {
    return <>{ErrorElement}</>;
  }
  if (isNew && typeof children !== 'function') {
    return children;
  }
  if (isDefined(data)) {
    return typeof children === 'function' ? children(data) : children;
  }
  if (isPending) {
    return <>{LoadingElement}</>;
  }
  return null;
};

interface ISaveGuardProps {
  isPending: boolean;
  LoadingElement?: React.ReactNode;
  children: (() => JSX.Element) | JSX.Element;
}

export const SaveGuard = (props: ISaveGuardProps) => {
  const { isPending, LoadingElement, children } = props;
  const Content = typeof children === 'function' ? children() : children;

  return (
    <>
      {isPending ? LoadingElement : null}
      {Content}
    </>
  );

  // if (isPending) {
  //   return (
  //     <>
  //       {Content}
  //       {LoadingElement}
  //     </>
  //   );
  // } else {
  //   return Content;
  // }
};

// eslint-disable-next-line
export default new QueryClient();
