import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import * as React from 'react';
// import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  );
});

interface State {
  textmask: string;
  numberformat: string;
}

export default function FormattedInputs() {
  const [values, setValues] = React.useState<State>({
    textmask: '(100) 000-0000',
    numberformat: '1320'
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Box
      sx={{
        '& > :not(style)': {
          m: 1
        }
      }}
    >
      <TextField
        label='react-number-format'
        value={values.numberformat}
        onChange={handleChange}
        name='numberformat'
        id='formatted-numberformat-input'
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
        variant='standard'
      />
    </Box>
  );
}
