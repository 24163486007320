import { observer } from 'mobx-react-lite';
import { Box, Grid, GridSize, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IEnergeticAuditHelp {
  help: (string | JSX.Element)[];
  footer?: string | JSX.Element;
  xs?: GridSize;
}

function EnergeticAuditHelp({ help, footer, xs = 3 }: IEnergeticAuditHelp) {
  const { t } = useTranslation();

  return (
    <Grid item xs={xs} className='bgWhite' alignSelf={footer ? 'flex-start' : 'unset'}>
      <Box p={2}>
        <Grid container direction='column'>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Typography variant='body2' fontWeight='bold'>
                <i className='fas fa-lightbulb fa-2x amberWarning pr-2'></i>
                {t('global.helper')}
              </Typography>
            </Grid>
            {help.map((helpText: string | JSX.Element, i: number) => {
              return (
                <Grid item key={i} container direction='row' spacing={1} alignItems='baseline'>
                  <Grid item fontSize='8px'>
                    <i className='fas fa-circle'></i>
                  </Grid>
                  <Grid item xs>
                    <Typography variant='body2'>{helpText}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {footer && (
            <Grid container mt={4}>
              <Grid item>{footer}</Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default observer(EnergeticAuditHelp);
