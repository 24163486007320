import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDividerProps {
  text: ReactElement<any, any> | string;
}

export const XsDivider = (props: IDividerProps) => {
  const { text } = props;
  const { t } = useTranslation();

  const renderText = typeof text === 'string' ? t(text) : text;

  return (
    <div className='XsDivider'>
      <Typography variant='body2' className='dividerText'>
        {renderText}
      </Typography>
      <div className='dividerHR'></div>
    </div>
  );
};
