import { Breadcrumbs, Link, Typography } from '@mui/material';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { IFormFieldChange } from '../helpers/hooks';
import UIStore from '../stores/UIStore';

interface IXsBreadcrumbsProps {
  changes: IFormFieldChange[] | (() => IFormFieldChange[]);
}

export const XsBreadcrumbs = observer((props: IXsBreadcrumbsProps) => {
  const { changes } = props;
  const breadcrumbsLength = UIStore.breadcrumbs.length;

  const handleLinkClick = (step: number) => {
    if (step < breadcrumbsLength) {
      let changeValues: IFormFieldChange[] = [];
      if (typeof changes === 'function') {
        changeValues = changes();
      } else {
        changeValues = changes;
      }
      if (changeValues && changeValues.length) {
        UIStore.openConfirmationDialog('leaveConfirmation', { discriminator: 'breadcrumb', breadcrumbStep: step });
      } else {
        UIStore.goToBreadcrumbsStep(step);
      }
    }
  };

  return (
    <>
      <Breadcrumbs>
        {UIStore.breadcrumbs?.map((link, key) => {
          const step = key + 1;

          return (
            <Link
              key={step}
              component='button'
              color='inherit'
              underline='none'
              // onClick={() => (step < breadcrumbsLength ? UIStore.goToBreadcrumbsStep(step) : null)}
              onClick={() => handleLinkClick(step)}
            >
              <Typography
                variant='body2'
                color='textPrimary'
                className={classnames({
                  breadcrumbsLink: step < breadcrumbsLength,
                  cursor_default: step === breadcrumbsLength
                })}
              >
                {link.label}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
});
