import { makeAutoObservable } from 'mobx';

export class AutoLogoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _tokenExpDateUTC: Date | null = null;

  get tokenExpDateUTC() {
    return this._tokenExpDateUTC;
  }

  setTokenExpDateUTC = (expDate: Date | null) => {
    this._tokenExpDateUTC = expDate;
  };
}

const singleton = new AutoLogoutStore();
export default singleton;
