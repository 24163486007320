import { action, makeObservable, observable } from 'mobx';
import { TCVSSreens, ECVSStatus } from '../../../global/types/types';

class CvsStore {
  cvsTabScreen: TCVSSreens = 'list';
  cvsStatus?: ECVSStatus;

  setCVSTabScreen(screen: TCVSSreens) {
    this.cvsTabScreen = screen;
  }

  setCVSStatus(eaStatus: ECVSStatus) {
    this.cvsStatus = eaStatus;
  }

  constructor() {
    makeObservable(this, {
      cvsTabScreen: observable,
      cvsStatus: observable,
      setCVSTabScreen: action,
      setCVSStatus: action
    });
  }
}

const singleton = new CvsStore();
export default singleton;
