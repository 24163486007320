import { FormControl, FormControlLabel, Grid, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { getCompanyId, getFilters, getPerson } from '../../../../../global/helpers/actions';

import {
  XsDatePicker,
  XsCodelistSearchSelect,
  XsTextField,
  XsAutocompleteServer,
  XsNumberMaskField,
  XsSearchSelect,
  XsLoading
} from '../../../../../global/ui';
import SavingsPotentialStore, { ISubjectType } from '../../SavingsPotentialStore';
import { SPTypeEnum } from './SPHeaderContainer';
import API from '../../../../../global/config/api';
import { useCodeList, useServerCall } from '../../../../../global/helpers/hooks';
import {
  ISubject,
  ISubjectResponse
} from '../../../../../global/interfaces/responses/sp/savingsPotentialSubject.response.interface';
import {
  ICodeListObject,
  ICodeListResponse
} from '../../../../../global/interfaces/responses/codeList.response.interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ISPHeaderForm } from '../../../../../global/interfaces/forms/sp/savingsPotentialHeader.form.interface';
import { XsFieldset } from '../../../../../global/ui/XsFieldset';
import { IEnergeticAuditEnergeticObjectListResponse } from '../../../../../global/interfaces/responses/energeticAuditEnergeticObject.response.interface';
import { XsNoteField } from '../../../../../global/ui/XsNoteField';

type THeaderForm = {
  spId: string;
  savingPotentialSubject: [ISubjectType | null, Dispatch<SetStateAction<ISubjectType | null>>];
  initData: React.MutableRefObject<ISPHeaderForm | null>;
  addNewSP: boolean;
};

function SPHeaderForm({ spId, savingPotentialSubject, initData, addNewSP }: THeaderForm) {
  const { t } = useTranslation();

  const { control, watch, setValue, reset, getValues } = useFormContext();

  const { data: sectors } = useCodeList<ICodeListResponse>('Entity.CompanySector');
  const { data: skNace } = useCodeList<ICodeListResponse>('Entity.SK.NACE');

  const [loading, setLoading] = useState<boolean>(false);
  const [, setSavingPotentialSubject] = savingPotentialSubject;

  const sentDisabled = SavingsPotentialStore.spStatus !== 'pre';

  const isPO = watch('sp_type') === SPTypeEnum.PO;
  const isFO = watch('sp_type') === SPTypeEnum.FO;
  const isREGION = watch('sp_type') === SPTypeEnum.REGION;

  const companyId = getCompanyId();

  const subjectCall = useServerCall<any, ISubjectResponse>({
    disabled: companyId ? false : true,
    key: ['company_subjects', companyId],
    request: {
      url: API.GetCompanySubject(),
      method: 'POST',
      data: {
        filters: getFilters([`company_id=${companyId}`]).filters
      }
    },
    onSuccess: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 5 * 100 }
  });

  const { data: companySubjects } = subjectCall;

  useEffect(() => {
    if (companyId) setLoading(true);

    const person = getPerson();

    setValue('first_name_info', person?.first_name);
    setValue('last_name_info', person?.last_name);
    setValue('email_1_info', person?.email);
    setValue('phone_info', person?.phone);
  }, []);

  useEffect(() => {
    //naplnenie Subjectu potencialu uspor (pretoze z BE nechodi cely object, takze taky "hack") update
    if (spId && getValues('subject_id') && !getValues('subject')) {
      const subj: ISubject | undefined = companySubjects?.rows.find((sub) => sub._id === getValues('subject_id'));

      if (subj) {
        setValue('subject', subj);
        setSubjectForm(subj);
      }
    }

    //ak sa jedna o novy zaznam, predplni subject
    if (!spId && !getValues('subject') && companySubjects?.rows && companySubjects?.rows.length > 0) {
      setValue('subject', companySubjects?.rows[0]);

      setSubjectForm(companySubjects?.rows[0]);

      const currFormVals = getValues() as ISPHeaderForm;
      initData.current = currFormVals;
    }
  }, [companySubjects, getValues('subject_id')]);

  const setSubjectForm = (subject?: ISubject) => {
    setValue('street', subject?.street_plain);
    setValue('zip', subject?.zip_plain);
    setValue('houseNumber', subject?.house_number);
    setValue('city', subject?.city_id);

    if (sectors) {
      const sector: ICodeListObject | undefined = sectors.rows.find(
        (x: ICodeListObject) => x._id === subject?.sector_id
      );

      if (sector) {
        setValue('sector', sector);
      }
    }

    if (skNace) {
      const sknace: ICodeListObject | undefined = skNace.rows.find(
        (x: ICodeListObject) => x._id === subject?.classification_id
      );

      if (sknace) {
        setValue('skNace', sknace);
      }
    }
  };

  const resetSPForm = () => {
    const spType = getValues('sp_type');
    reset({
      name: getValues('name'),
      sp_type: spType,
      preparationDate: getValues('preparationDate'),
      submitionReason: getValues('submitionReason')
    });

    //Reset typu formularu pre dalsi krok
    setSavingPotentialSubject(null);

    if (spType === SPTypeEnum.PO) {
      setValue('subject', companySubjects?.rows[0]);
      setSubjectForm(companySubjects?.rows[0]);
    }
  };

  const objectData = useServerCall<void, IEnergeticAuditEnergeticObjectListResponse>({
    disabled: spId && !addNewSP ? false : true,
    key: `SPEnergeticObjectList${spId}`,
    mode: 'auto',
    request: {
      url: API.SPObject(spId),
      method: 'POST'
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 5 * 100 }
  });

  const existObjectData: boolean = objectData.data?.rows && objectData.data.rows.length > 0 ? true : false;

  const disabledSPForm = spId && existObjectData ? true : false;

  return (
    <>
      {loading && <XsLoading overlay />}
      <Grid item container xs={9} pr={8}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name='preparationDate'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsDatePicker
                    label={t('sp.list.preparation_date')}
                    {...field}
                    required
                    disabled={sentDisabled}
                    maxDate={new Date()}
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='submitionReason'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsCodelistSearchSelect
                    required
                    menuWidth='175%'
                    disabled={sentDisabled}
                    codelistKey={'SavingsProspect.SubmissionReason'}
                    label={t('sp.header.reason')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container alignItems='baseline' mt={4}>
            <Grid item container xs={6} pr={4}>
              <XsFieldset label={t('sp.header.info_about_sp')}>
                <Grid item container spacing={3} alignContent='baseline'>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component='fieldset'>
                        <Controller
                          name='sp_type'
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={value === SPTypeEnum.FO}
                                    onChange={(e) => {
                                      onChange(e);
                                      resetSPForm();
                                    }}
                                    value={SPTypeEnum.FO}
                                    disabled={disabledSPForm}
                                  />
                                }
                                label={t('sp.header.radio_fo')}
                              />
                              {companyId && (
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={value === SPTypeEnum.PO}
                                      onChange={(e) => {
                                        onChange(e);
                                        resetSPForm();
                                      }}
                                      value={SPTypeEnum.PO}
                                      disabled={disabledSPForm}
                                    />
                                  }
                                  label={t('sp.header.radio_po')}
                                />
                              )}
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={value === SPTypeEnum.REGION}
                                    onChange={(e) => {
                                      onChange(e);
                                      resetSPForm();
                                    }}
                                    value={SPTypeEnum.REGION}
                                    disabled={disabledSPForm}
                                  />
                                }
                                label={t('sp.header.radio_reg')}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {isPO && (
                    <>
                      <Grid item container spacing={2}>
                        <Grid item xs={7}>
                          <Controller
                            name='subject'
                            render={({ field: { ref, ...rest } }) => (
                              <XsSearchSelect
                                {...rest}
                                required
                                label={t('sp.header.subject')}
                                options={companySubjects?.rows ?? []}
                                getOptionLabel={(obj: ISubject) => obj.name}
                                getOptionValue={(obj: ISubject) => obj._id}
                                postAction={(subject: ISubject) => {
                                  setSubjectForm(subject);
                                }}
                                disabled={disabledSPForm}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={7}>
                          <Controller
                            name='street'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField type='text' label={t('global.street')} {...field} disabled required={isPO} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            name='houseNumber'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField
                                type='text'
                                label={t('global.house_number')}
                                {...field}
                                disabled
                                required={isPO}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={7}>
                          <Controller
                            name='city'
                            render={({ field: { ref, ...rest } }) => (
                              <XsAutocompleteServer
                                {...rest}
                                disabled
                                required={isPO}
                                label={t('global.village')}
                                request={{
                                  uri: '/isee/cl/city',
                                  payload: {
                                    load: (value) => getFilters([`code=${value}`]),
                                    search: (value) => getFilters([`search_column=${value}`])
                                  }
                                }}
                                identFn={(option) => option._id}
                                labelFn={(option) => `${option.name_ext} (${option.county_name})`}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            name='zip'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsNumberMaskField
                                {...field}
                                label={t('global.psc')}
                                format='### ##'
                                align='left'
                                disabled
                                required={isPO}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={7}>
                          <Controller
                            name='sector'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsCodelistSearchSelect
                                {...field}
                                required={isPO}
                                menuWidth='150%'
                                codelistKey={'Entity.CompanySector'}
                                label={t('sp.header.sector')}
                                disabled={disabledSPForm}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            name='skNace'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsCodelistSearchSelect
                                {...field}
                                required={isPO}
                                disabled
                                menuWidth='200%'
                                codelistKey={'Entity.SK.NACE'}
                                getOptionLabel={(option) => `${option.code_ext} - ${option.name_ext}`}
                                label={t('global.sk_nace')}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {isFO && (
                    <>
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name='firstName'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField
                                {...field}
                                type='text'
                                label={t('global.first_name')}
                                required
                                disabled={disabledSPForm}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name='lastName'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField
                                {...field}
                                type='text'
                                label={t('global.last_name')}
                                required
                                disabled={disabledSPForm}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name='email'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField type='text' label={t('global.email1')} {...field} disabled={sentDisabled} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name='phone'
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <XsTextField
                                type='text'
                                label={t('global.phone_contact')}
                                {...field}
                                disabled={sentDisabled}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {isREGION && (
                    <Grid item container spacing={2}>
                      <Grid item xs={7}>
                        <Controller
                          name='subject_type'
                          render={({ field: { ref, ...rest } }) => (
                            <XsSearchSelect
                              {...rest}
                              required
                              label={t('sp.header.subject')}
                              options={SavingsPotentialStore.subjectTypes}
                              getOptionLabel={(obj: ISubjectType) => obj.name}
                              getOptionValue={(obj: ISubjectType) => obj._id}
                              disabled={disabledSPForm}
                              postAction={(subject: ISubjectType) => {
                                setSavingPotentialSubject(subject);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </XsFieldset>
            </Grid>
            <Grid item container xs={6} pr={4} pl={2} spacing={3} alignContent='baseline'>
              <XsFieldset label={t('sp.header.info_about_sp_2_FO')}>
                <Grid item container spacing={3} alignContent='baseline'>
                  <Grid item container spacing={2} pb={3}>
                    <Grid item xs={12}>
                      <Controller
                        name='name'
                        render={({ field: { ref, ...rest } }) => (
                          <XsNoteField
                            {...rest}
                            required
                            disabled={sentDisabled}
                            rows={5}
                            maxLength={200}
                            multiline
                            label={t('sp.header.projectName')}
                            counter={200}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name='first_name_info'
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <XsTextField type='text' label={t('global.first_name')} {...field} disabled required />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name='last_name_info'
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <XsTextField type='text' label={t('global.last_name')} {...field} disabled required />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name='email_1_info'
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <XsTextField type='text' label={t('global.email1')} {...field} disabled />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name='phone_info'
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <XsTextField type='text' label={t('global.phone_contact')} {...field} disabled />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name='email_2'
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <XsTextField type='text' label={t('global.email2')} {...field} disabled={sentDisabled} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </XsFieldset>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default SPHeaderForm;
