import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { useState } from 'react';
import UIStore from '../stores/UIStore';

export interface ISnackbarProps {
  key: number;
  message: string;
  variant: VariantType;
}

const XsSnackbar = () => {
  const [displayed, setDisplayed] = useState<Number[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  UIStore.notifications?.forEach((notification: ISnackbarProps) => {
    // Do not display duplicates
    if (displayed.includes(notification.key)) {
      return;
    }

    const action = (key: number) => {
      return (
        <Button onClick={() => closeSnackbar(key)}>
          <i className='fas fa-times fa-lg snowWhite'></i>
        </Button>
      );
    };

    const isError: boolean = notification.variant === 'error';

    const onEntered = (e: HTMLElement, isAppearing: boolean, snackbarKey: SnackbarKey) => {
      const key = snackbarKey as number;

      if (isError && !UIStore.errorNotifications.includes(key)) {
        //tu sa settuju error Snackbar key, na zaklade ktorych viem zatvarat notifikacie
        UIStore.errorNotifications.push(key);
      } else {
        //Ak su errorve hlasky a pride neerorova, tak errorove sa zatvoria
        if (UIStore.errorNotifications.length > 0) {
          UIStore.errorNotifications.forEach((notKey) => {
            closeSnackbar(notKey);
          });

          UIStore.errorNotifications = [];
        }
      }
    };

    // Display snackbar (default from notistack)
    enqueueSnackbar(notification.message, {
      variant: notification.variant,
      autoHideDuration: isError ? 60 * 1000 : 5000,
      action: isError ? action : null,
      onEntered: onEntered
    });

    //Keeping track of displayed
    setDisplayed(displayed.concat(notification.key));
    // Remove from queue
    UIStore.removeNotificationMessage(notification.key);
  });

  return null;
};

export default observer(XsSnackbar);
