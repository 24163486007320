import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import Joi from 'joi';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API from '../../../../../global/config/api';
import { copyToClipboard, getCompanyId, getPerson } from '../../../../../global/helpers/actions';
import { useCodeList, useFormChanges, useLoadData, useServerCall } from '../../../../../global/helpers/hooks';
import { QueryGuard, SaveGuard } from '../../../../../global/helpers/query';
import { isEmailValid, isPhoneNumberValid, isZipValid } from '../../../../../global/helpers/validators';
import { ISPHeaderForm } from '../../../../../global/interfaces/forms/sp/savingsPotentialHeader.form.interface';
import { ISPHeaderRequest } from '../../../../../global/interfaces/requests/sp/savingsPotentialHeader.request.interface';
import {
  ICodeListObject,
  ICodeListResponse
} from '../../../../../global/interfaces/responses/codeList.response.interface';
import { ISPResponse, ISubjectPerson } from '../../../../../global/interfaces/responses/sp/sp.response.interface';
import UIStore from '../../../../../global/stores/UIStore';
import { TSavingsPotentialStatus } from '../../../../../global/types/types';
import { XsBreadcrumbs, XsButton, XsLoading } from '../../../../../global/ui';
import XsLeaveConfirmationDialog from '../../../../../global/ui/confirmationDialog/XsLeaveConfirmationDialog';
import SavingsPotentialStore, { ISubjectType } from '../../SavingsPotentialStore';
import SPHelp from '../SPHelp';
import SPHeaderForm from './SPHeaderForm';

type TSPHeader = {
  savingsPotentialID: [string, Dispatch<SetStateAction<string>>];
  savingPotentialSubject: [ISubjectType | null, Dispatch<SetStateAction<ISubjectType | null>>];
  onBack: () => void;
};

export type TSaveState = 'next' | 'stay' | 'back';

export enum SPTypeEnum {
  FO = 'per',
  PO = 'cor',
  REGION = 'reg'
}

export enum SPBreadcrumb {
  HEADER_LIST = 'SPHeaderList',
  HEADER_DETAIL = 'SPHeaderDetail',
  ENERGETIC_OBJECTS = 'SPEnergeticObject',
  PROVISIONS = 'SPProvisions',
  CARRIERS = 'SPCarriers',
  SUMMARY = 'SPSummary'
}

const schema = Joi.object<ISPHeaderForm>({
  preparationDate: Joi.date().max('now').min(new Date(new Date().getFullYear(), 0, 1)).required(),
  submitionReason: Joi.object().required(),
  sp_type: Joi.string().required(),
  subject_id: Joi.string(),
  subject: Joi.object().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.object().required(),
    otherwise: Joi.object().allow(null)
  }),
  firstName: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.FO),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').allow(null)
  }),
  lastName: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.FO),
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').allow(null)
  }),
  email: Joi.string().regex(isEmailValid()).message('email_message').allow(''),
  phone: Joi.string().regex(isPhoneNumberValid()).allow('').message('phone_message'),
  email_2: Joi.string().regex(isEmailValid()).message('email_message').allow(''),
  street: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.string().max(50).allow('').allow(null),
    otherwise: Joi.string().allow(null).allow('')
  }),
  zip: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.string().regex(isZipValid()).disallow('00000').allow('').allow(null),
    otherwise: Joi.string().allow(null).allow('')
  }),
  houseNumber: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.string().max(20).allow('').allow(null),
    otherwise: Joi.string().allow(null).allow('')
  }),
  city: Joi.string().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.string().allow('').allow(null),
    otherwise: Joi.string().allow('').allow(null)
  }),
  sector: Joi.object().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.object().required(),
    otherwise: Joi.object().allow(null)
  }),
  skNace: Joi.object().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.PO),
    then: Joi.object().allow(null),
    otherwise: Joi.object().allow(null)
  }),
  first_name_info: Joi.string().allow(''),
  last_name_info: Joi.string().allow(''),
  email_1_info: Joi.string().allow(''),
  phone_info: Joi.string().allow(''),
  subject_type: Joi.object().when(Joi.ref('sp_type'), {
    is: Joi.string().equal(SPTypeEnum.REGION),
    then: Joi.object().required(),
    otherwise: Joi.object().allow(null)
  }),
  name: Joi.string().max(200).required()
});

type TSuccessSaveResponse = {
  data: {
    _id: string;
    identifier: string;
    submitting_person: any;
    submitting_company: any;
    preparation_date: string;
    submitter: {
      _id: string;
    };
  };
};

function SPHeaderContainer({ savingsPotentialID, onBack, savingPotentialSubject }: TSPHeader) {
  const { t } = useTranslation();
  const [, setSavingPotentialSubject] = savingPotentialSubject;
  const next = useRef<TSaveState>('stay');
  const [spId, setSPID] = savingsPotentialID;

  const { data: sectors } = useCodeList<ICodeListResponse>('Entity.CompanySector');
  const { data: reasons } = useCodeList<ICodeListResponse>('SavingsProspect.SubmissionReason');
  const { data: skNace } = useCodeList<ICodeListResponse>('Entity.SK.NACE');

  const initData = useRef<ISPHeaderForm | null>(null);

  const addNewSP = useRef<boolean>(false); //flag, aby sa pri pridavani noveho, neodpaloval useLoadData

  const saveSavingsPotential = useServerCall<ISPHeaderRequest, any>({
    key: 'saveSavingsPotentialHeader',
    mode: 'control',
    request: {
      url: API.SPSave(),
      method: 'PUT'
    },
    onSuccess: (res: TSuccessSaveResponse) => {
      UIStore.setNotificationMessage(t('sp.header.save_success'), 'success');

      const formValues: ISPHeaderForm = form.getValues();

      addNewSP.current = true;
      setSPID(res.data._id);

      SavingsPotentialStore.spBaseInfo = {
        id: res?.data?._id,
        identifier: res?.data?.identifier,
        submitter_name: '',
        submitter_ico: '',
        auditor_name: '',
        preparation_date: new Date(res?.data?.preparation_date)
      };

      if (next.current === 'next') {
        UIStore.changeLastBreadcrumbsStep(
          <span>
            {t('sp.savings_potential')}: <span className='bold'>{res.data.identifier}</span>
          </span>
        );
        SavingsPotentialStore.setSPTabScreen('energeticObjects');
      }

      if (next.current === 'stay') {
        initData.current = formValues;
      }
    },
    onError: (err) => {
      UIStore.setNotificationMessage(err, 'error');
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 500 }
  });

  const form = useForm<ISPHeaderForm>({
    resolver: joiResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      sp_type: !spId ? SPTypeEnum.PO : undefined
    }
  });

  const { handleSubmit, setValue, getValues } = form;
  const onSubmit = (formVals: ISPHeaderForm) => {
    const spType: SPTypeEnum = getValues('sp_type') as SPTypeEnum;

    let req: ISPHeaderRequest = {
      _id: spId ? spId : null,
      preparation_date: format(formVals.preparationDate, 'yyyy-MM-dd'),
      submission_reason_id: formVals.submitionReason?._id,
      prospect_type_id: formVals.sp_type,
      name: formVals.name,
      prospected_object_type_id: 'eo',
      status_id: 'pre',
      subject_company: null,
      subject_person: null,
      submitting_company: getCompanyId()
        ? {
            _id: getCompanyId()
          }
        : null,
      submitting_person: {
        _id: getPerson()?._id,
        email2: formVals.email_2
      },
      auto_realize: false
    };

    switch (spType) {
      case SPTypeEnum.REGION:
        req.prospected_object_type_id = formVals.subject_type?._id;
        break;
      case SPTypeEnum.FO:
        req.subject_person = {
          email: formVals.email ?? '',
          first_name: formVals.firstName ?? '',
          last_name: formVals.lastName ?? '',
          phone_number: formVals.phone ?? ''
        };
        break;
      case SPTypeEnum.PO:
        req.subject_company = {
          _id: formVals.subject?._id,
          sector_id: formVals.sector?._id
        };
    }

    saveSavingsPotential.run({
      url: API.SPSave(),
      data: { ...req }
    });
  };

  const initBreadcrumbs = (identifier?: string) => {
    UIStore.setBreadcrumbs([
      {
        ID: SPBreadcrumb.HEADER_LIST,
        label: t('sp.header.breadcrumb'),
        action: () => onBack()
      },
      {
        ID: SPBreadcrumb.HEADER_DETAIL,
        label: (
          <span>
            {t('sp.savings_potential')}: <span className='bold'>{identifier}</span>
          </span>
        ),
        action: () => SavingsPotentialStore.setSPTabScreen('header')
      }
    ]);
  };

  const formLoad = useLoadData<void, ISPResponse, ISPHeaderForm>({
    disabled: spId && !addNewSP.current ? false : true,
    key: ['savingsPotentialHeader', spId],
    request: {
      url: API.SPList(spId, 'CDL2'),
      method: 'GET'
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 5 * 1000 },
    transform: (data) => {
      let transJSON: ISPHeaderForm = {
        sp_type: data.prospect_type_id,
        subject: null,
        email_2: data.submitting_person.email2,
        preparationDate: new Date(data.preparation_date),
        name: data.name,
        sector: null,
        skNace: null,
        submitionReason: null,
        zip: '',
        city: '',
        street: '',
        houseNumber: ''
      };

      if (data.prospect_type_id === SPTypeEnum.FO && data.subject_person) {
        const subjPerson: ISubjectPerson = data.subject_person;

        transJSON.firstName = subjPerson.first_name;
        transJSON.lastName = subjPerson.last_name;
        transJSON.email = subjPerson.email;
        transJSON.phone = subjPerson.phone_number;
      }

      if (data.prospected_object_type_id) {
        const subject = SavingsPotentialStore.subjectTypes.find((sp) => sp._id === data.prospected_object_type_id);
        transJSON.subject_type = subject;
        if (transJSON.sp_type === SPTypeEnum.REGION) {
          setSavingPotentialSubject(subject || null);
        } else {
          setSavingPotentialSubject(null);
        }
      }

      if (reasons) {
        const reason: ICodeListObject | undefined = reasons.rows.find(
          (x: ICodeListObject) => x._id === data.submission_reason_id
        );

        if (reason) {
          transJSON.submitionReason = reason;
        }
      }

      if (data.subject_company?._id) {
        transJSON.subject_id = data.subject_company._id;
        transJSON.street = data.subject_company.street_plain || '';
        transJSON.houseNumber = data.subject_company.house_number || '';
        transJSON.city = data.subject_company.city_id || '';
        transJSON.zip = data.subject_company.zip_plain || '';
      }

      if (data.subject_company?.sector_id) {
        const sector: ICodeListObject | undefined = sectors?.rows.find(
          (x: ICodeListObject) => x._id === data.subject_company?.sector_id
        );

        if (sector) {
          transJSON.sector = sector;
        }
      }

      if (data.subject_company?.classification_id) {
        const naceSk: ICodeListObject | undefined = skNace?.rows.find(
          (x: ICodeListObject) => x._id === data.subject_company?.classification_id
        );

        if (naceSk) {
          transJSON.skNace = naceSk;
        }
      }

      initBreadcrumbs(data.identifier);

      SavingsPotentialStore.spBaseInfo = {
        id: data._id,
        identifier: data.identifier,
        auditor_name: data.auditor ? `${data.auditor?.first_name} ${data.auditor?.last_name}` : undefined,
        submitter_name: data.submitter?.name,
        submitter_ico: data.submitter?.identifier,
        preparation_date: new Date(data.preparation_date)
      };

      initData.current = transJSON;

      SavingsPotentialStore.setSPStatus(data.status_id as TSavingsPotentialStatus);

      return transJSON;
    },
    form
  });

  // useCompany({ ...formLoadData, companyIdSelector: (data) => data.auditor.company_id });

  const getChanges = useFormChanges<ISPHeaderForm>({
    exceptions: ['subject', 'first_name_info', 'last_name_info', 'email_1_info', 'phone_info', 'subject_id'],
    form,
    initDataRef: initData
  });

  useEffect(() => {
    //ak sa nejedna o update, tak predpln auditora/submitter
    if (!spId) {
      setValue('sp_type', SPTypeEnum.FO);
      setValue('preparationDate', new Date());

      initData.current = form.getValues();
      initBreadcrumbs();
    }
  }, [spId, setValue]);

  const onFormError = (formErrors: any, e: any) => {
    UIStore.setNotificationMessage(t('global.checkReqiredFields'), 'error');
  };

  const isNewSP: boolean = spId && !addNewSP.current ? false : true;

  return (
    <Box p={3}>
      <QueryGuard isNew={isNewSP} {...formLoad} LoadingElement={<XsLoading />} ErrorElement={<span></span>}>
        <FormProvider {...form}>
          <SaveGuard isPending={saveSavingsPotential.isPending} LoadingElement={<XsLoading overlay={true} />}>
            <>
              <Grid container justifyContent='space-between' alignItems='baseline'>
                <Grid item>
                  <XsBreadcrumbs changes={() => getChanges(initData.current)} />
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <i
                      className='fas fa-link blueLight'
                      style={{ paddingRight: 10, cursor: 'pointer' }}
                      title={t('global.link_step')}
                      onClick={async () => {
                        const isCopied = await copyToClipboard(window.location.href);
                        if (isCopied) {
                          UIStore.setNotificationMessage(t('global.copy_to_clipboard_success'), 'success');
                        } else {
                          UIStore.setNotificationMessage(t('global.copy_to_clipboard_failed'), 'warning');
                        }
                      }}
                    ></i>
                    {t('sp.header.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container py={2}>
                <SPHeaderForm
                  spId={spId}
                  savingPotentialSubject={savingPotentialSubject}
                  initData={initData}
                  addNewSP={addNewSP.current}
                />
                <SPHelp help={[t('sp.header.help_1'), t('sp.header.help_2')]} />
              </Grid>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <XsButton
                    startIcon={<i className='fas fa-arrow-left fa-lg'></i>}
                    variant='contained'
                    label={'sp.header.back'}
                    onClick={() => {
                      const changes = getChanges(initData.current);
                      if (!(SavingsPotentialStore.spStatus !== 'pre') && changes?.length) {
                        UIStore.openConfirmationDialog('leaveConfirmation', { discriminator: 'list' });
                      } else {
                        onBack();
                      }
                    }}
                  />
                </Grid>
                <Grid item container xs justifyContent='flex-end' spacing={2}>
                  <Grid item>
                    <XsButton
                      startIcon={<i className='fas fa-save fa-lg'></i>}
                      variant='outlined'
                      disabled={SavingsPotentialStore.spStatus !== 'pre'}
                      label={'global.save'}
                      onClick={handleSubmit<ISPHeaderForm>(onSubmit, onFormError)}
                    />
                  </Grid>
                  <Grid item>
                    <XsButton
                      endIcon={<i className='fas fa-arrow-right fa-lg'></i>}
                      variant='contained'
                      label={'sp.header.next'}
                      onClick={() => {
                        //update existujuceho
                        if (spId) {
                          const changes = getChanges(initData.current);
                          if (!(!(SavingsPotentialStore.spStatus !== 'pre') && changes?.length)) {
                            SavingsPotentialStore.setSPTabScreen('energeticObjects');
                          } else {
                            UIStore.openConfirmationDialog('leaveConfirmation');
                          }
                        } else {
                          next.current = 'next';
                          handleSubmit<ISPHeaderForm>(onSubmit, (formErrors, e) => {
                            onFormError(formErrors, e);
                            next.current = 'stay';
                          })();
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <XsLeaveConfirmationDialog
                onConfirmation={() => {
                  if (UIStore.confParams?.discriminator === 'list') {
                    onBack();
                  } else if (UIStore.confParams?.discriminator === 'breadcrumb') {
                    UIStore.goToBreadcrumbsStep(UIStore.confParams?.breadcrumbStep);
                  } else if (
                    UIStore.confParams?.discriminator === 'formChanges' &&
                    UIStore.confParams?.discriminatorAction
                  ) {
                    UIStore.confParams.discriminatorAction();
                  } else {
                    if (initData.current?.sp_type !== SPTypeEnum.REGION) {
                      setSavingPotentialSubject(null);
                    }
                    SavingsPotentialStore.setSPTabScreen('energeticObjects');
                  }
                }}
              />
            </>
          </SaveGuard>
        </FormProvider>
      </QueryGuard>
    </Box>
  );
}

export default observer(SPHeaderContainer);
