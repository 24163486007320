import { joiResolver } from '@hookform/resolvers/joi';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import Joi from 'joi';
import { observer } from 'mobx-react-lite';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API from '../config/api';
import { getUserId } from '../helpers/actions';
import { useServerPutCall } from '../helpers/hooks';
import { isPasswordValid } from '../helpers/validators';
import UIStore from '../stores/UIStore';
import { XsButton } from './XsButton';
import { XsPasswordInput } from './XsPasswordInput';
import CloseIcon from '@mui/icons-material/Close';

export const XsChangePasswordDialog = observer(() => {
  const { t } = useTranslation();

  const schema = Joi.object<any>({
    _id: Joi.allow(''),
    current_password: Joi.string().regex(isPasswordValid()),
    password_new: Joi.string().regex(isPasswordValid()),
    password_new_confirmation: Joi.string().regex(isPasswordValid())
  });

  const form = useForm<any>({
    resolver: joiResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { handleSubmit, getValues } = form;

  const onSaveSuccess = () => {
    UIStore.closeChangePasswordDialog();
  };

  const saveUser = useServerPutCall<any, any>('saveUser', 'change_password.password_success_changed', onSaveSuccess);

  const onSubmit = (formVals: any) => {
    let request: any = {
      _id: getUserId(),
      current_password: formVals.current_password,
      password: formVals.password_new
    };

    saveUser.run({ data: { ...request }, url: API.AdmUser() });
  };

  const onError = () => {
    UIStore.setNotificationMessage(t('global.checkReqiredFields'), 'error');
  };

  const formSubmit = () => {
    handleSubmit<any>(onSubmit, onError)();
  };

  return (
    <Dialog open={UIStore.isOpenChangePasswordDialog} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container justifyContent={'center'}>
          <Grid item className='blueDark'>
            {t('menu.changePassword')}
          </Grid>
        </Grid>
        <IconButton
          onClick={() => UIStore.closeChangePasswordDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>{t('register.password_rules')}</Typography>
        <ul style={{ paddingInlineStart: '30px' }}>
          <li>
            <Typography variant='body2'>{t('register.password_rules1')}</Typography>
          </li>
          <li>
            <Typography variant='body2'>{t('register.password_rules2')}</Typography>
          </li>
          <li>
            <Typography variant='body2'>{t('register.password_rules3')}</Typography>
          </li>
          <li>
            <Typography variant='body2'>{t('register.password_rules4')}</Typography>
          </li>
          <li>
            <Typography variant='body2'>{t('register.password_rules5')}</Typography>
          </li>
        </ul>
        <FormProvider {...form}>
          <Grid container spacing={2} mt={4}>
            <Grid item xs={12}>
              <Controller
                name='current_password'
                render={({ field: { ref, ...rest } }) => (
                  <XsPasswordInput
                    {...rest}
                    required
                    label={t('global.current_password')}
                    inputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='password_new'
                render={({ field: { ref, ...rest } }) => (
                  <XsPasswordInput {...rest} required label={t('adminContainer.users.userlist.new_password')} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='password_new_confirmation'
                render={({ field: { ref, ...rest } }) => (
                  <XsPasswordInput {...rest} required label={t('adminContainer.users.userlist.new_password_confirm')} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <XsButton
                label='global.save'
                variant='contained'
                onClick={() => {
                  if (getValues('password_new') === getValues('password_new_confirmation')) {
                    formSubmit();
                  } else {
                    UIStore.setNotificationMessage(t('adminContainer.users.userlist.new_password_not_match'), 'error');
                  }
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
});
