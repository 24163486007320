export interface IFieldsetProps {
  label: string;
  children: JSX.Element;
}

export const XsFieldset = (props: IFieldsetProps) => {
  const { label, children } = props;

  return (
    <fieldset style={{ width: '100%', border: '1px solid #A3A3A3', borderRadius: '5px', fontWeight: 500 }}>
      <legend>{label}</legend>
      {children}
    </fieldset>
  );
};
