import { observer } from 'mobx-react-lite';
import { Box, Grid, GridSize, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ISPHelp {
  help: (string | JSX.Element)[];
  xs?: GridSize;
}

function SPHelp({ help, xs = 3 }: ISPHelp) {
  const { t } = useTranslation();

  return (
    <Grid item xs={xs} className='bgWhite'>
      <Box p={1}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Typography variant='body2' fontWeight='bold'>
              <i className='fas fa-lightbulb fa-2x amberWarning pr-2'></i>
              {t('global.helper')}
            </Typography>
          </Grid>
          {help.map((helpText: string | JSX.Element, i: number) => {
            return (
              <Grid item key={i} container direction='row' spacing={1} alignItems='baseline'>
                <Grid item fontSize='8px'>
                  <i className='fas fa-circle'></i>
                </Grid>
                <Grid item xs>
                  <Typography variant='body2'>{helpText}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
}

export default observer(SPHelp);
