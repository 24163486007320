export const isPhoneNumber421Valid = () => {
  return /^(\+421)?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/;
};

export const isEmailValid = () => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
};

export const isPasswordValid = () => {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{};':"|,.<>/?])\S{8,50}$/;
};

export const isPhoneNumberValid = () => {
  // return /^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/;

  return /^\+\d{7,15}$/;
};

export const isIcoValid = () => {
  return /^\d{8}$/;
};

export const isForeignIcoValid = () => {
  return /^([A-Za-z0-9]\s|[A-Za-z0-9]){1,30}$/;
};

export const isZipValid = () => {
  return /^\d{5}$/;
};

export const isPhoneNumberWithout421Valid = () => {
  return /^\d{9}$/;
};

export const isEICValid = () => {
  return /^[1-9][0-9][XYZWTVA][A-Z0-9\\-]{12}[A-Z0-9]$/;
};

export const isEICListValid = () => {
  return /^([1-9][0-9][XYZWTVA][A-Z0-9\\-]{12}[A-Z0-9])(,\s*([1-9][0-9][XYZWTVA][A-Z0-9\\-]{12}[A-Z0-9]))*$/;
};
