import Tooltip from '@mui/material/Tooltip';
import { useState, useRef, useEffect } from 'react';

export const XsOverflowCell = ({ children }: any) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<any>(null);

  useEffect(() => {
    if (textElementRef?.current) {
      const tolerance: number = 2;
      setIsOverflow(
        (textElementRef.current?.offsetHeight ?? 0) + tolerance < (textElementRef.current?.scrollHeight ?? 0)
      );
    }
  }, []);
  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div ref={textElementRef} className='ellipsis-2-rows'>
        {children}
      </div>
    </Tooltip>
  );
};
