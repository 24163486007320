import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRemoveConfirmationDialog } from '../../types/confirmationDialog';
import XsConfirmationDialog from './XsConfirmationDialog';

export default observer(({ onConfirmation, content }: IRemoveConfirmationDialog) => {
  const { t } = useTranslation();

  return (
    <XsConfirmationDialog
      type='danger'
      name='removeObject'
      titleIcon={<i className='fas fa-trash-alt fa-2x'></i>}
      content={content ? content : t('global.remove_confirmation')}
      confirmBtn={{
        icon: <i className='fal fa-trash-alt fa-lg' />,
        label: 'global.remove'
      }}
      cancelBtn={{
        icon: <i className='fal fa-times fa-lg' />,
        label: 'global.cancel'
      }}
      onConfirmation={onConfirmation}
    />
  );
});
