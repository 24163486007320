import settings from './settings';

export type TOzeModule = 'eo' | 'oze';

const moduleSuffix: string = `-${settings.MODULE?.toLowerCase()}`;

export default class API {
  /** Register */

  public static CheckUnCompletedRegistrations(): string {
    return `/isee/adm-bo/registration`;
  }

  /** ENERGETIC AUDIT */
  public static EAAudit(energeticAuditId?: string, levelFlag?: string): string {
    return `/isee/ea${moduleSuffix}/energetic-audit${energeticAuditId ? `/${energeticAuditId}` : ''}${
      levelFlag ? `?level_flag=${levelFlag}` : ''
    }`;
  }

  public static EAObject(energeticAuditId: string, objectId?: string): string {
    return `/isee/ea${moduleSuffix}/energetic-audit/${energeticAuditId}/object${objectId ? `/${objectId}` : ''}`;
  }

  public static EAComputeProvision(): string {
    return `/isee/ea${moduleSuffix}/compute/provision`;
  }

  public static EASummaryData(energeticAuditId?: string): string {
    return `/isee/ea${moduleSuffix}/energetic-audit/${energeticAuditId}/summary-data`;
  }

  public static EASummaryPDF(energeticAuditId?: string): string {
    return `/isee/ea${moduleSuffix}/energetic-audit/${energeticAuditId}/summary`;
  }

  public static EASubmitter(): string {
    return `/isee/ea${moduleSuffix}/submitter`;
  }

  public static EAAuditor(): string {
    return `/isee/ea${moduleSuffix}/auditor`;
  }

  public static EACompanySubject(): string {
    return `/isee/ea${moduleSuffix}/company-subject`;
  }

  public static EACompanySubmitter(id?: string): string {
    return `/isee/ea${moduleSuffix}/company-submitter${id ? `/${id}` : ''}`;
  }

  public static EAPrepareSummaryExport(typeId: string): string {
    return `/isee/ea${moduleSuffix}/batch/prepare/${typeId} `;
  }

  public static EAOutputSummaryExport(guid: string): string {
    return `/isee/ea${moduleSuffix}/batch/output/${guid} `;
  }

  /** CADASTRE */
  public static CADCadastralUnitsByCity(cityCode: string): string {
    return `/isee/cadastre/cadastralunitsbycity/${cityCode}`;
  }

  /** CVS */
  public static CvsReports(): string {
    return `/isee/cvs${moduleSuffix}/reports`;
  }

  public static CvsSumaryReport(): string {
    return `/isee/cvs${moduleSuffix}/summary-report`;
  }

  public static CVSReportSave(): string {
    return `/isee/cvs${moduleSuffix}/report`;
  }

  public static CVSRecordtSave(): string {
    return `/isee/cvs${moduleSuffix}/record`;
  }

  public static CvsRecords(): string {
    return `/isee/cvs${moduleSuffix}/records`;
  }

  public static CvsRecord(recordId: string): string {
    return `/isee/cvs${moduleSuffix}/record/${recordId}`;
  }

  public static CvsReport(reportId: string): string {
    return `/isee/cvs${moduleSuffix}/report/${reportId}`;
  }

  public static CvsReportStatus(reportId: string): string {
    return `/isee/cvs${moduleSuffix}/report/${reportId}/status`;
  }
  public static CvsReportNote(): string {
    return `/isee/cvs${moduleSuffix}/reportnote`;
  }

  public static CvsMediumGraphData(): string {
    return `/isee/cvs${moduleSuffix}/energyconsumptiongraphdata`;
  }

  public static CvsReportMissingYears(companyId: string): string {
    return `/isee/cvs${moduleSuffix}/report-company/${companyId}/missing-years`;
  }
  public static CvsSubmitter(): string {
    return `/isee/cvs${moduleSuffix}/submitter`;
  }

  public static CvsSummaryData(reportId: string) {
    return `/isee/cvs${moduleSuffix}/report/${reportId}/summary-data`;
  }

  public static CvsSummary(reportId: string) {
    return `/isee/cvs${moduleSuffix}/report/${reportId}/summary`;
  }

  /** OES */
  public static OesReports(): string {
    return `/isee/oes${moduleSuffix}/reports`;
  }

  public static OesReport(reportId: string): string {
    return `/isee/oes${moduleSuffix}/report/${reportId}`;
  }

  public static OESReportSave(): string {
    return `/isee/oes${moduleSuffix}/report`;
  }

  public static OesReportNote(): string {
    return `/isee/oes${moduleSuffix}/reportnote`;
  }

  public static OesReportStatus(reportId: string): string {
    return `/isee/oes${moduleSuffix}/report/${reportId}/status`;
  }

  public static OesReportMissingYears(companyId: string): string {
    return `/isee/oes${moduleSuffix}/report-company/${companyId}/missing-years`;
  }

  public static OesRecords(): string {
    return `/isee/oes${moduleSuffix}/records`;
  }

  public static OesRecord(recordId: string): string {
    return `/isee/oes${moduleSuffix}/record/${recordId}`;
  }

  public static OesRecordSave(): string {
    return `/isee/oes${moduleSuffix}/record`;
  }

  public static OesMediumGraphData(): string {
    return `/isee/oes${moduleSuffix}/energyconsumptiongraphdata`;
  }

  public static OesSubmitter(): string {
    return `/isee/oes${moduleSuffix}/submitter`;
  }

  public static OesSummaryData(reportId: string) {
    return `/isee/oes${moduleSuffix}/report/${reportId}/summary-data`;
  }

  public static OesSummary(reportId: string) {
    return `/isee/oes${moduleSuffix}/report/${reportId}/summary`;
  }

  /** DIST */
  public static DistReports(): string {
    return `/isee/dist${moduleSuffix}/reports`;
  }

  public static DistReport(reportId: string): string {
    return `/isee/dist${moduleSuffix}/report/${reportId}`;
  }

  public static DistRecords(): string {
    return `/isee/dist${moduleSuffix}/records`;
  }

  public static DistRecord(recordId: string): string {
    return `/isee/dist${moduleSuffix}/record/${recordId}`;
  }

  public static DistRecordSave(): string {
    return `/isee/dist${moduleSuffix}/record`;
  }

  public static DistReportMissingYears(companyId: string): string {
    return `/isee/dist${moduleSuffix}/report-company/${companyId}/missing-years`;
  }

  public static DistReportMissingDistributors(): string {
    return `/isee/dist${moduleSuffix}/distributorreport`;
  }

  public static DistReportMissingDistTypes(companyId: string, year: string): string {
    return `/isee/dist${moduleSuffix}/report-company/${companyId}/year/${year}/missing-types`;
  }

  public static DistReportNote(): string {
    return `/isee/dist${moduleSuffix}/reportnote`;
  }

  public static DistReportStatus(reportId: string): string {
    return `/isee/dist${moduleSuffix}/report/${reportId}/status`;
  }

  public static DistRegisters(): string {
    return `/isee/dist${moduleSuffix}/distributor`;
  }

  public static DistRegister(distributorId: string): string {
    return `/isee/dist${moduleSuffix}/distributor/${distributorId}`;
  }

  public static DistributorItemOrder(companyId: string | null, distributiontypeid: string): string {
    return `/isee/dist${moduleSuffix}/distributoritemorder-company/${companyId}/distributiontypeid/${distributiontypeid}`;
  }

  public static DistributorCompany(): string {
    return `/isee/dist${moduleSuffix}/submitter`;
  }

  public static DistSummaryData(reportId: string) {
    return `/isee/dist${moduleSuffix}/report/${reportId}/summary-data`;
  }

  public static DistSummary(reportId: string) {
    return `/isee/dist${moduleSuffix}/report/${reportId}/summary`;
  }

  public static DistPrepareSummaryExport(typeId: string): string {
    return `/isee/dist${moduleSuffix}/batch/prepare/${typeId} `;
  }

  public static DistOutputSummaryExport(guid: string): string {
    return `/isee/dist${moduleSuffix}/batch/output/${guid} `;
  }

  //** ADMIN  */
  public static AdmSubjects(subjectId?: string): string {
    return `/isee/adm${moduleSuffix}/company${subjectId ? `/${subjectId}` : ''}`;
  }

  public static AdmFoundedSubjects(subjectId: string): string {
    return `/isee/adm${moduleSuffix}/company/${subjectId}/founded`;
  }

  public static AdmUser(userId?: string): string {
    return `/isee/adm${moduleSuffix}/user${userId ? `/${userId}` : ''}`;
  }

  public static AdmAddUserToOrgunit(orgunit_id: string): string {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunit_id}/register-person`;
  }

  public static AdmUserCheckLogin(orgunitId: string, login: string) {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/check-login/${login}`;
  }

  public static AdmClientCheck(orgunitId: string) {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/client-check`;
  }

  public static AdmSPPDFileUpload(): string {
    return `/isee/eo${moduleSuffix}/sppd-consumption`;
  }

  public static AdmSPPDImports(guid?: string): string {
    return `/isee/eo${moduleSuffix}/sppd-imports${guid ? `/${guid}` : ''}`;
  }

  public static AdmSPPDImportLog(guid: string): string {
    return `/isee/eo${moduleSuffix}/sppd-import-log/${guid}`;
  }

  public static AdmOKTEConsumptions(): string {
    return `/isee/eo${moduleSuffix}/okte-consumptions`;
  }

  public static AdmOKTEImport(guid?: string): string {
    return `/isee/eo${moduleSuffix}/okte-import${guid ? `/${guid}` : ''}`;
  }

  public static AdmOKTEImportLog(guid: string): string {
    return `/isee/eo${moduleSuffix}/okte-import/${guid}/log`;
  }

  public static AdmBlockUser(userId: string): string {
    return `/isee/adm${moduleSuffix}/user/${userId}/enable`;
  }

  public static AdmAnonymisekUser(userId: string): string {
    return `/isee/adm${moduleSuffix}/user/${userId}/anonymise`;
  }

  public static AdminRegionalCenter(): string {
    return `/isee/adm${moduleSuffix}/regional-center`;
  }

  public static OrgunitAddress(orgunitId: string, addressId?: string): string {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/address${addressId ? `/${addressId}` : ''}`;
  }

  public static OrgunitContact(orgunitId: string, contactId?: string): string {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/contact${contactId ? `/${contactId}` : ''}`;
  }

  public static OrgunitLegislativeDuty(orgunitId: string, legislativeDutyId?: string): string {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/legislative-duty${
      legislativeDutyId ? `/${legislativeDutyId}` : ''
    }`;
  }

  public static OrgunitClient(orgunitId: string, clientId?: string): string {
    return `/isee/adm${moduleSuffix}/org-unit/${orgunitId}/client${clientId ? `/${clientId}` : ''}`;
  }

  public static UserSubject(clientId: string, orgunitId?: string): string {
    return `/isee/adm${moduleSuffix}/client/${clientId}/org-unit${orgunitId ? `/${orgunitId}` : ''}`;
  }

  public static UserSubjectCheck(clientId: string, orgunitId?: string): string {
    return `/isee/adm${moduleSuffix}/client/${clientId}/org-unit-check${orgunitId ? `/${orgunitId}` : ''}`;
  }

  public static UserModules(clientId: string): string {
    return `/isee/adm${moduleSuffix}/client/${clientId}/modules`;
  }

  public static UserLegislation(clientId: string): string {
    return `/isee/adm${moduleSuffix}/client/${clientId}/legislative-duties`;
  }

  public static RegisterCompany(clientId: string): string {
    return `/isee/adm${moduleSuffix}/client/${clientId}/register-company`;
  }

  public static AdmRegistrationsList(): string {
    return `/isee/adm${moduleSuffix}/registration`;
  }

  public static AdmRegistrationExtend(guid: string): string {
    return `/isee/adm${moduleSuffix}/registration/${guid}/extend`;
  }

  public static AdmRegistrationStorno(guid: string): string {
    return `/isee/adm${moduleSuffix}/registration/${guid}/cancel`;
  }

  public static AdmCodelistList(codelist: string, id?: string): string {
    return `/isee/adm-bo/cl/${codelist}${id ? `/${id}` : ''}`;
  }

  /** SAVINGS POTENTIAL */
  public static SPList(savingPotentialId?: string, levelFlag?: string): string {
    return `/isee/pu${moduleSuffix}/savings-prospect${savingPotentialId ? `/${savingPotentialId}` : ''}${
      levelFlag ? `?level_flag=${levelFlag}` : ''
    }`;
  }

  public static SPSave(spId?: string): string {
    return `/isee/pu${moduleSuffix}/savings-prospect${spId ? `/${spId}` : ''}`;
  }

  public static SPObject(potentialId: string, objectId?: string): string {
    return `/isee/pu${moduleSuffix}/savings-prospect/${potentialId}/object${objectId ? `/${objectId}` : ''}`;
  }

  public static SPComputeProvision(): string {
    return `/isee/pu${moduleSuffix}/compute/provision`;
  }

  public static GetCompanySubject(): string {
    return `/isee/pu${moduleSuffix}/company-subject`;
  }

  public static RegisterRPO(): string {
    return `${settings.API_PUBLIC_URL}/data-lake/rpo`;
  }

  public static SPSummaryData(energeticAuditId?: string): string {
    return `/isee/pu${moduleSuffix}/savings-prospect/${energeticAuditId}/summary-data`;
  }

  public static SPSummaryPDF(energeticAuditId?: string): string {
    return `/isee/pu${moduleSuffix}/savings-prospect/${energeticAuditId}/summary`;
  }

  public static SPSubmitter(): string {
    return `/isee/pu${moduleSuffix}/submitter`;
  }

  public static EtlQueueStart(): string {
    return `/etl/enqueue`;
  }

  public static EtlQueueStatus(): string {
    return `/etl/queuestatus`;
  }

  public static EtlMeasurableIndicators(): string {
    return `/etl/report/measurableindicatorsreport`;
  }

  public static EtlAimActivity(): string {
    return `/etl/report/reqaimactivity`;
  }

  public static EtlProjects(): string {
    return `/etl/report/projects`;
  }

  public static EtlExpenditures(): string {
    return `/etl/report/expenditures`;
  }

  public static DataLakeRa(): string {
    return `data-lake/ra`;
  }

  public static DataLakeStreet(municipalityCode: string): string {
    return `/data-lake/ra/streetname/${municipalityCode}`;
  }

  /** BUILDINGS */
  public static Building(buildingId?: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object${buildingId ? `/${buildingId}` : ''}`;
  }

  public static BuildingContactEntity(companyId: string): string {
    return `/isee/eo${moduleSuffix}/entity/${companyId}/module`;
  }

  public static BuildingParameters(buildingId: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object/${buildingId}/parameters`;
  }

  public static BuildingSubmitterCompany(id?: string): string {
    return `/isee/eo${moduleSuffix}/company-submitter${id ? `/${id}` : ''}`;
  }

  public static BuildingSubmitterPerson(id?: string): string {
    return `/isee/eo${moduleSuffix}/person-submitter${id ? `/${id}` : ''}`;
  }

  public static BuildingOperationReportList(id: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object/${id}/operation-reports`;
  }

  public static BuildingOperationReportImport(importId?: string): string {
    return `/isee/eo${moduleSuffix}/operation-report-import${importId ? `/${importId}` : ''}`;
  }

  public static BuildingOperationReportImportLogDownload(importId: string): string {
    return `/isee/eo-bo/operation-report-import/${importId}/log`;
  }

  public static DeleteBuildingOperationReportList(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}`;
  }

  public static SaveBuildingOperationReport(): string {
    return `/isee/eo${moduleSuffix}/operation-report`;
  }

  public static GetBuildingOperationReport(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}`;
  }

  public static BuildingOperationReportMediumList(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}/medium`;
  }

  public static EOSummaryData(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}/summary-data`;
  }

  public static EOSummaryPDF(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}/summary`;
  }

  public static SaveBuildingOperationReportMedium(id: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${id}/medium`;
  }

  public static DeleteBuildingOperationReportMediumList(reportId: string, mediumId: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${reportId}/medium/${mediumId}`;
  }

  public static GetBuildingOperationReportMedium(reportId: string, mediumId: string): string {
    return `/isee/eo${moduleSuffix}/operation-report/${reportId}/medium/${mediumId}`;
  }

  public static BuildingSPPDPod(guid: string, podId?: string): string {
    return `/isee/eo${moduleSuffix}/sppd/energetic-object/${guid}/pod${podId ? `/${podId}` : ''}`;
  }

  public static OperationList(guid?: string): string {
    return `/isee/eo${moduleSuffix}/operation-report${guid ? `/${guid}` : ''}`;
  }

  public static BdgOze(modulePrefix: TOzeModule, ozeId?: string): string {
    return `/isee/${modulePrefix}${moduleSuffix}/renewable-source${ozeId ? `/${ozeId}` : ''}`;
  }

  public static BdgOzeList(modulePrefix: TOzeModule, buildingId?: string): string {
    return `/isee/${modulePrefix}${moduleSuffix}/energetic-object${
      buildingId ? `/${buildingId}` : ''
    }/renewable-sources`;
  }

  public static BdgAvailableAddressess(): string {
    return `/isee/eo-bo/available-addresses`;
  }

  /** BUILDING - CASES  */
  public static BuildingCase(guid?: string): string {
    return `/isee/eo${moduleSuffix}/case${guid ? `/${guid}` : ''}`;
  }

  public static BuildingCaseActivity(caseGuid: string): string {
    return `/isee/eo${moduleSuffix}/case/${caseGuid}/activity`;
  }

  public static EnergeticObjectCases(guid: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object/${guid}/cases`;
  }

  public static DeleteEnergeticObjectCase(guid: string): string {
    return `/isee/eo${moduleSuffix}/case/${guid}`;
  }

  public static DeleteEnergeticObjectCaseActivity(caseGuid: string, guid: string): string {
    return `/isee/eo${moduleSuffix}/case/${caseGuid}/activity/${guid}`;
  }

  /** BUILDING - ECB  */
  public static ECBCertificates(guid?: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object/${guid}/certificates`;
  }

  public static ECBRelationship(guid?: string): string {
    return `/isee/eo${moduleSuffix}/ecb-relationship${guid ? `/${guid}` : ''}`;
  }

  public static EICRelationship(guid?: string): string {
    return `/isee/eo${moduleSuffix}/eic-relationship${guid ? `/${guid}` : ''}`;
  }

  /** SHARED */
  public static SPPDConsumptions(): string {
    return `/isee/eo${moduleSuffix}/sppd-consumptions`;
  }

  public static EICConsumptions(buildingId: string): string {
    return `/isee/eo${moduleSuffix}/energetic-object/${buildingId}/eic-consumptions`;
  }

  public static AppStatus(): string {
    return `${settings.API_PUBLIC_URL}/discovery/app-status`;
  }

  public static Self(): string {
    return `/user/info/self`;
  }

  public static AdmECBImport(guid?: string): string {
    return `/isee/eo${moduleSuffix}/ecb-import${guid ? `/${guid}` : ''}`;
  }

  public static AdmECBLog(guid: string): string {
    return `/isee/eo${moduleSuffix}/ecb-import/${guid}/log`;
  }

  public static AdmECBFileUpload(): string {
    return `/isee/eo${moduleSuffix}/ecb-import`;
  }

  public static AdmEcbCertificate(guid?: string): string {
    return `/isee/eo${moduleSuffix}/ecb-certificate${guid ? `/${guid}` : ''}`;
  }

  public static BdgSubmitterChangeProposed(): string {
    return `/isee/eo${moduleSuffix}/submitter-change/proposed`;
  }

  public static BdgSubmitterChangeSubmitted(): string {
    return `/isee/eo${moduleSuffix}/submitter-change/submitted`;
  }

  public static BdgSubmitterChanges(): string {
    return `/isee/eo${moduleSuffix}/submitter-changes`;
  }

  public static BdgSubmitterChangeAction(): string {
    return `/isee/eo${moduleSuffix}/submitter-changes/action`;
  }

  // OZE MODULE
  public static OzeEnergeticObject(id?: string): string {
    return `/isee/oze${moduleSuffix}/energetic-object${id ? `/${id}` : ''}`;
  }

  public static OzeRenewableSource(id?: string): string {
    return `/isee/oze${moduleSuffix}/renewable-source${id ? `/${id}` : ''}`;
  }

  public static EnergeticObjectComplexExport(): string {
    return `/isee/eo${moduleSuffix}/energetic-object-complex-report`;
  }

  public static EnergeticObjectTemplateExport(): string {
    return `/isee/eo${moduleSuffix}/operation-report-export-template`;
  }

  /** OOM */
  public static Oom(oomId?: string): string {
    return `/isee/oom${moduleSuffix}/delivery-point${oomId ? `/${oomId}` : ''}`;
  }
  public static OomSurvey(oomId: string): string {
    return `/isee/oom${moduleSuffix}/delivery-point/${oomId}/survey`;
  }

  public static OomConsumptionImport(importId?: string): string {
    return `/isee/oom${moduleSuffix}/consumption-import${importId ? `/${importId}` : ''}`;
  }

  public static OomRegionalCenter(): string {
    return `/isee/oom${moduleSuffix}/regional-center`;
  }

  public static OomDeliveryPointTariff(): string {
    return `/cl/ISEE.CL.DeliveryPoint.Tariff `;
  }

  public static OesGenerateSummaryExport(typeId: string): string {
    return `/isee/oes${moduleSuffix}/batch/generate/${typeId} `;
  }

  public static OesPrepareSummaryExport(typeId: string): string {
    return `/isee/oes${moduleSuffix}/batch/prepare/${typeId} `;
  }

  public static OesOutputSummaryExport(guid: string): string {
    return `/isee/oes${moduleSuffix}/batch/output/${guid} `;
  }

  public static OomConsumptionGraph(guid: string): string {
    return `/isee/oom-bo/delivery-point/${guid}/consumption `;
  }
}
