import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useError } from '../helpers/hooks';

export interface INumberFieldProps {
  // decimals?: number;
  fontSize?: string | number;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element;
  tabIndex?: number;
}

export const XsNumberField = (props: TextFieldProps & INumberFieldProps) => {
  const { prefix = null, suffix = null, fontSize, name, tabIndex, ...rest } = props;

  const { error, helperText } = useError(name);

  let startAdornment: React.ReactNode | null = prefix;
  let endAdornment: React.ReactNode | null = suffix;

  if (typeof prefix === 'string') {
    startAdornment = <InputAdornment position='start'>{prefix}</InputAdornment>;
  }
  if (typeof prefix === 'string') {
    endAdornment = <InputAdornment position='end'>{suffix}</InputAdornment>;
  }

  return (
    <TextField
      variant='outlined'
      fullWidth
      size={'small'}
      margin={'none'}
      type={'number'}
      autoComplete={'off'}
      inputProps={{
        tabIndex,
        autoComplete: 'off',
        inputMode: 'decimal',
        style: { textAlign: 'right' }
      }}
      InputProps={{
        startAdornment: startAdornment,
        endAdornment: endAdornment
      }}
      // className={props.required ? classes.required : undefined}
      InputLabelProps={{ shrink: true }}
      {...rest}
      // value={
      //   props.decimals !== undefined && typeof props.value === 'number'
      //     ? (props.value as number).toFixed(props.decimals)
      //     : props.value
      // }
      onFocus={(event) => event.target.select()}
      error={error}
      helperText={helperText}
    />
  );
};
