import { InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import React from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { INumberFieldProps } from '.';
import { useError } from '../helpers/hooks';

interface CustomNumberMaskProps {
  align?: 'left' | 'right';
  tooltip?: string;
}

type IXsNumberMaskField = Omit<NumberFormatPropsBase, 'suffix' | 'prefix'> &
  Omit<TextFieldProps, 'suffix' | 'prefix'> &
  INumberFieldProps & {
    maskPrefix?: string;
    maskSuffix?: string;
    prefix?: JSX.Element | string;
    suffix?: JSX.Element | string;
    onInputChange?: (value: number | undefined) => void;
  } & CustomNumberMaskProps;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  onInputChange?: (value: number | undefined) => void;
  name: string;
  align?: 'left' | 'right';
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, onInputChange, align = 'right', ...other } = props;

  return (
    <NumberFormat
      {...other}
      isNumericString={true}
      getInputRef={ref}
      onValueChange={(values, source) => {
        // logger('masked change =>', props.name, values, source.event);
        if (values.floatValue || source.event) {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }
        if (onInputChange && source.event) onInputChange(values.floatValue);
      }}
      align={align}
      style={{ textAlign: align, paddingRight: 5 }}
    />
  );
});

export const XsNumberMaskField = (props: IXsNumberMaskField) => {
  const { prefix = null, suffix = null, fontSize, name, tabIndex, align = 'right', tooltip, ...rest } = props;
  const { error, helperText } = useError(name);

  let startAdornment: React.ReactNode | null = prefix;
  let endAdornment: React.ReactNode | null = suffix;

  if (typeof prefix === 'string') {
    startAdornment = <InputAdornment position='start'>{prefix}</InputAdornment>;
  }
  if (typeof prefix === 'string') {
    endAdornment = <InputAdornment position='end'>{suffix}</InputAdornment>;
  }

  return (
    <Tooltip title={tooltip ?? ''} placement='top'>
      <TextField
        variant='outlined'
        fullWidth
        size={'small'}
        margin={'none'}
        autoComplete={'off'}
        type={'text'}
        inputProps={{
          tabIndex,
          autoComplete: 'off',
          inputMode: 'decimal',
          style: { textAlign: align }
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          inputComponent: NumberFormatCustom as any,
          inputProps: { ...props, prefix: props.maskPrefix, suffix: props.maskSuffix }
        }}
        InputLabelProps={{ shrink: true }}
        {...rest}
        onFocus={(event) => event.target.select()}
        error={error}
        helperText={helperText}
        style={{ color: 'red !important' }}
      />
    </Tooltip>
  );
};
