import { TableState } from 'react-table';
import { IS_FO } from '../config/constants';

interface IPagination {
  pageIndex: number;
  pageSize: number;
}

interface ISort {
  id: string;
  desc: boolean;
}

class ServerTableStore {
  readonly defaultPagination: IPagination = { pageIndex: 0, pageSize: IS_FO ? 10 : 20 };

  paginations: Map<string, IPagination> = new Map();
  sorts: Map<string, ISort | null> = new Map();
  selectedRows: Map<string, string> = new Map();
  filters: Map<string, any> = new Map();
  expanded: Map<string, any> = new Map();

  setExpanded(name: string, obj: any) {
    this.expanded.set(name, { expanded: obj });
  }
  getExpanded(name: string) {
    return this.expanded.get(name);
  }

  resetStoreOnLogout() {
    this.paginations = new Map();
    this.sorts = new Map();
    this.selectedRows = new Map();
    this.filters = new Map();
    this.expanded = new Map();
  }

  setPagination(name: string, pagination: IPagination) {
    this.paginations.set(name, pagination);
  }
  getPagination(name: string, initPageSize?: number) {
    return (
      this.paginations.get(name) ?? {
        ...this.defaultPagination,
        pageSize: initPageSize ?? this.defaultPagination.pageSize
      }
    );
  }
  setSort(name: string, sort: ISort | null) {
    this.sorts.set(name, sort);
  }
  getSort(name: string) {
    let sortBy = [];
    const sort = this.sorts.get(name);

    if (!sort) return {};

    if (sort) {
      sortBy.push(sort);
    }
    return { sortBy };
  }
  setSelectedRow(name: string, row: string) {
    this.selectedRows.set(name, row);
  }
  isSelectedRow(name: string, row: string) {
    return this.selectedRows.get(name) === row;
  }
  setFilter(name: string, filter: any) {
    this.filters.set(name, filter);

    const pagination = this.getPagination(name);
    this.setPagination(name, { ...pagination, pageIndex: 0 });

    this.selectedRows.delete(name);
  }
  clearFilter(name: string) {
    this.filters.delete(name);
  }
  getFilter(name: string) {
    return this.filters.get(name) ?? {};
  }

  applyTableState = (name: string, state: TableState, callback: Function) => {
    this.setPagination(name, { pageSize: state.pageSize, pageIndex: state.pageIndex });
    if (state.sortBy.length > 0) {
      this.setSort(name, { id: state.sortBy[0].id, desc: Boolean(state.sortBy[0].desc) });
    } else {
      this.setSort(name, null);
    }
    this.setExpanded(name, state.expanded);
    callback();
  };
}

const singleton = new ServerTableStore();
export default singleton;
