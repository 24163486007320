import { InputAdornment, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import { XsTextField } from '.';

type IPasswordInputProps = TextFieldProps & {
  isUppercaseValue?: boolean;
  fontSize?: string | number;
};

export const XsPasswordInput = (props: IPasswordInputProps) => {
  const { ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const endAdornment = (
    <InputAdornment position='end'>
      {
        <i
          className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
          onClick={() => {
            handleVisibility();
          }}
        ></i>
      }
    </InputAdornment>
  );

  return <XsTextField {...rest} suffix={endAdornment} type={showPassword ? 'text' : 'password'} />;
};
