import { GetOptionLabel, GroupBase, OptionsOrGroups } from 'react-select';
import { TSelectProps, XsSearchSelect } from '.';
import { codeListsConfig } from '../config/codelist';
import { useCodeList } from '../helpers/hooks';
import { ICacheCodeList } from '../interfaces/requests/cache.request.interface';
import { ICodeListObject, ICodeListResponse } from '../interfaces/responses/codeList.response.interface';
import { XsMultiSearchSelect } from './XsMultiSearchSelect';

type TCodelistSelectProps = TSelectProps<ICodeListObject> & {
  codelistKey: string;
  disabled?: boolean;
  filter?: (row: ICodeListObject) => boolean;
  tabIndex?: number;
  isMulti?: boolean;
};

export const XsCodelistSearchSelect = /*<T extends unknown>*/ (props: TCodelistSelectProps) => {
  const { codelistKey, disabled, filter, tabIndex, getOptionLabel, isMulti = false, ...rest } = props;

  //Find selected code list from config
  const codeListValue: ICacheCodeList | undefined = codeListsConfig.find(
    (cl: ICacheCodeList) => cl.name === codelistKey
  );
  const { data } = useCodeList<ICodeListResponse>(codeListValue!.name);

  // Apply filter function on codelist data rows
  const filteredRows = filter ? data?.rows?.filter(filter) : data?.rows;

  //Extract values from rows and sort by name_ext
  const options: OptionsOrGroups<ICodeListObject, GroupBase<ICodeListObject>> =
    filteredRows?.sort((a: ICodeListObject, b: ICodeListObject) => a.name_ext.localeCompare(b.name_ext)) ?? [];

  //Set option value (_id) and label (name_ext)
  const getOptionValue: GetOptionLabel<ICodeListObject> = (obj: ICodeListObject) => obj._id;
  const getOpLabel: GetOptionLabel<ICodeListObject> = (obj: ICodeListObject) =>
    typeof getOptionLabel === 'function' ? getOptionLabel(obj) : obj.name_ext;

  return (
    <>
      {isMulti === true ? (
        <XsMultiSearchSelect
          {...rest}
          tabIndex={tabIndex}
          options={options}
          disabled={disabled}
          getOptionLabel={getOpLabel}
          getOptionValue={getOptionValue}
        />
      ) : (
        <XsSearchSelect
          {...rest}
          tabIndex={tabIndex}
          options={options}
          disabled={disabled}
          getOptionLabel={getOpLabel}
          getOptionValue={getOptionValue}
        />
      )}
    </>
  );
};
