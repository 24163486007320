import { joiResolver } from '@hookform/resolvers/joi';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import Joi from 'joi';
import { observer } from 'mobx-react-lite';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API from '../config/api';
import { useServerPutCall } from '../helpers/hooks';
import { isPasswordValid } from '../helpers/validators';
import UIStore from '../stores/UIStore';
import { XsButton } from './XsButton';
import { XsPasswordInput } from './XsPasswordInput';
import CloseIcon from '@mui/icons-material/Close';
import { IUserFormFO } from '../interfaces/forms/admin/adminUser.form.interface';
import { IUserFORequest } from '../interfaces/forms/admin/adminUser.request.interface';

type TConfirmPasswordForm = {
  current_password: string;
};

export const XsConfirmChangePasswordDialog = observer(() => {
  const { t } = useTranslation();

  const schema = Joi.object<TConfirmPasswordForm>({
    current_password: Joi.string().regex(isPasswordValid()).required()
  });

  const form = useForm<TConfirmPasswordForm>({
    resolver: joiResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { handleSubmit } = form;

  const onSaveSuccess = () => {
    UIStore.closeConfirmChangeUserDataDialog();
  };

  const saveUser = useServerPutCall<IUserFORequest, any>('saveUser', 'person_info.save_success', onSaveSuccess);

  const onSubmit = (formVals: TConfirmPasswordForm) => {
    const user: IUserFormFO | undefined = UIStore.tmpChangedUserData;

    if (user) {
      const request: IUserFORequest = {
        _id: user._id ?? null /* ID používateľa - null pre crate, vyplnené pre update */,
        auditor: user.auditor /* Príznak, či ide o energetického auditora */,
        auditor_certificate: user.auditor_certificate /* Číslo osvedčenia auditora */,
        first_name: user.first_name /* Krstné meno */,
        last_name: user.last_name /* Priezvisko */,
        login: user.login /* Prihlasovacie meno (iba pri zakladaní nového) */,
        // note: user.note /* Poznámka */,
        enabled: true,
        current_password: formVals.current_password,
        phone_number: user.phone /* Tel. číslo */,
        title_after_id: user.title_after?._id ?? null /* Titul pred menom ID - číselník /cl/Entity.CL.Title */,
        title_before_id: user.title_before?._id ?? null /* Titul za menom ID - číselník /cl/Entity.CL.Title */
      };

      saveUser.run({ data: { ...request }, url: API.AdmUser() });
    }
  };

  const onError = () => {
    UIStore.setNotificationMessage(t('global.checkReqiredFields'), 'error');
  };

  const formSubmit = () => {
    handleSubmit<TConfirmPasswordForm>(onSubmit, onError)();
  };

  return (
    <Dialog open={UIStore.isOpenConfirmChangeUserDataDialog} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container justifyContent={'center'}>
          <Grid item className='blueDark'>
            {t('change_password.title')}
          </Grid>
        </Grid>
        <IconButton
          onClick={() => UIStore.closeConfirmChangeUserDataDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>{t('change_password.confirmed_password')}</Typography>
        <FormProvider {...form}>
          <Grid container spacing={2} mt={4}>
            <Grid item xs={12}>
              <Controller
                name='current_password'
                render={({ field: { ref, ...rest } }) => (
                  <XsPasswordInput
                    {...rest}
                    required
                    label={t('global.current_password')}
                    inputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <XsButton
                label='global.save'
                variant='contained'
                onClick={() => {
                  formSubmit();
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
});
