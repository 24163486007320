import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

export interface IInlineFormData {
  form?: any;
  defaultValues?: any;
  id: string;
}

export const XsInlineFormProvider = ({ watch, onFormChange, children, id, onFormCreated, ...form }: any) => {
  useEffect(() => {
    onFormCreated({ form });
  }, []);

  useEffect(() => {
    const watchSub = watch((_: any, { type }: any) => {
      if (type === 'change') {
        const inlineFormData: IInlineFormData = { form, id };
        onFormChange(inlineFormData);
      }
    });
    return () => watchSub.unsubscribe();
  }, [watch]);

  return <FormProvider {...form}>{children}</FormProvider>;
};
